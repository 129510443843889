<section>
  <div class="container-full head">
    <div class="container">
      <div class="columns">
        <div class="column is-12-desktop">
          <app-breadcrumbs
            [darkTheme]="true"
            [breadcrumbLinks]="breadcrumbLinks"
          ></app-breadcrumbs>
          <h1 class="title is-1 has-text-light">AI Index</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="container m-b-xxl m-t-alt">
    <div class="columns is-multiline">
      <div class="column is-12">
        <div *ngIf="page?.content" [innerHTML]="page?.content"></div>
        <div *ngIf="loadingPage">
          <div
            class="mt-6 temporary-item temporary-item--dark temporary-desc"
          ></div>
          <div class="temporary-item temporary-item--dark temporary-desc"></div>
        </div>
      </div>
    </div>
  </div>
</section>

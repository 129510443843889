<!-- Featured cards -->
<ng-container *ngIf="!loading; else featuredCardsLoader">
  <div *ngIf="featuredCards?.length">
    <div class="columns is-multiline">
      <div class="column is-12" *ngIf="featuredCards">
        <div
          class="columns is-flex is-multiline highlights has-{{
            featuredCards.length
          }}-highlights"
        >
          <ng-container *ngIf="featuredCards.length === 5">
            <div class="column is-12-mobile is-12-tablet is-8-desktop">
              <div class="columns is-multiline">
                <div
                  class="column"
                  *ngFor="let featuredCard of featuredCards | slice : 0 : 2"
                >
                  <app-featured-card
                    [featuredCard]="featuredCard"
                    [layout]="FeaturedCardLayout.Vertical"
                  ></app-featured-card>
                </div>
              </div>
            </div>
            <div class="column is-12-mobile is-12-tablet is-4-desktop">
              <div class="is-flex is-flex-direction-column">
                <app-featured-card
                  *ngFor="let featuredCard of featuredCards | slice : 2 : 5"
                  [featuredCard]="featuredCard"
                  [layout]="FeaturedCardLayout.Horizontal"
                  [showDescription]="false"
                  class="mb-2"
                ></app-featured-card>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="featuredCards.length <= 4">
            <div class="column is-12">
              <div class="featured-card-wapper is-fullwidth">
                <app-featured-card
                  *ngFor="let featuredCard of featuredCards"
                  [featuredCard]="featuredCard"
                  [layout]="FeaturedCardLayout.Vertical"
                ></app-featured-card>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- TODO: Loader -->
<ng-template #featuredCardsLoader>
  
    <div class="columns is-multiline">
      <!-- Loader -->
      <div class="column is-3" >
      <div class="lc lc--regular">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      </div>
      <div class="column is-3" >
      <div class="lc lc--regular">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      </div>
      <div class="column is-3" >
      <div class="lc lc--regular">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      </div>
      <div class="column is-3" >
      <div class="lc lc--regular">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      </div>
  </div>
</ng-template>

<section>
  <app-about-section-head
    [breadcrumbLinks]="breadcrumbLinks"
    [page]="selectedNavItem()"
    [dynamicDescription]="selectedNavItem() === 'about-' ? aboutPage?.excerpt : undefined" />
  <div class="container-full">
    <div class="subnav bg-swamp">
      <div class="container">
        <div class="columns">
          <div class="column p-y-0">
            <app-about-navigation-bar />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="aboutPage" class="container m-t-alt m-b-xxl">
    <div class="columns is-multiline">
      <div
        class="column is-12-mobile is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop is-offset-3-widescreen is-6-widescreen">
        <h1 class="is-2 title">{{ aboutPage.title }}</h1>

        <div class="content" [innerHTML]="pageContent"></div>
      </div>

      <div class="column is-12-mobile is-12-tablet is-3-desktop is-3-widescreen" *ngIf="hasQuickLinks">
        <app-quick-link-list [parentGroupSlug]="'about-' + selectedNavItem()" class="link-list"></app-quick-link-list>
      </div>
    </div>
  </div>

  <!-- // TODO: Add loader component -->
  <div *ngIf="isLoading" class="container m-t-alt m-b-xxl">
    <div class="columns">
      <div
        class="column is-12-mobile is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop is-offset-3-widescreen is-6-widescreen">
        <div class="temporary-item temporary-item--dark temporary-desc"></div>
        <div class="temporary-item temporary-item--dark temporary-desc"></div>
        <div class="temporary-item temporary-item--dark temporary-desc"></div>
        <div class="temporary-item temporary-item--dark temporary-desc"></div>
        <div class="temporary-item temporary-item--dark temporary-desc"></div>
        <div class="temporary-item temporary-item--dark temporary-desc"></div>
        <div class="temporary-item temporary-item--dark temporary-desc"></div>
        <div class="temporary-item temporary-item--dark temporary-desc"></div>
      </div>
    </div>
  </div>
</section>

import { Injectable } from '@angular/core'
import { Params } from '@angular/router'

import { ResourceSlug } from '../enums/wordpress/wp-resource-slug.enum'
import { Paginator } from '../interfaces/paginator.interface'
import { Video } from '../interfaces/video.interface'
import { WPPost } from '../interfaces/wordpress/wp-post.interface'
import { WPVideo } from '../interfaces/wordpress/wp-video.interface'
import { BlogResourceService } from './blog-resource.service'
import { FormatResourceService } from './format-resource.service'

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  videoSlug = ResourceSlug.Videos
  constructor(
    private blogResourceService: BlogResourceService,
    private formatResourceService: FormatResourceService
  ) {}

  list(listParams: {
    page?: number
    authorId?: number
    coAuthorId?: number
    tagId?: string
    tagIds?: number[]

    withoutPagination?: boolean
  }): Promise<Paginator<Video> | Video[]> {
    const params: Params = {
      per_page: 12,
      page: listParams.page ? listParams.page.toString() : '1',
      withoutPagination: !!listParams.withoutPagination
    }
    if (listParams.authorId) {
      params.author = listParams.authorId.toString()
    }
    if (listParams.coAuthorId) {
      params.coAuthor = listParams.coAuthorId.toString()
    }
    if (listParams.page) {
      listParams.page.toString()
    }
    if (listParams.tagId) {
      params.tags = listParams.tagId
    }

    if (listParams.tagIds) {
      params.tags = listParams.tagIds.join(',')
    }

    if (listParams.withoutPagination) {
      return (
        this.blogResourceService.list(this.videoSlug, params) as Promise<
          WPVideo[]
        >
      ).then((wpVideoRes: WPVideo[]) =>
        wpVideoRes.map((wpVideo) =>
          this.formatResourceService.formatVideo(wpVideo)
        )
      )
    }

    return (
      this.blogResourceService.list(this.videoSlug, params) as Promise<
        Paginator<WPVideo>
      >
    ).then((wpVideoRes: Paginator<WPVideo>) => {
      const videos: Video[] = wpVideoRes.data.map((wpVideo: WPVideo) =>
        this.formatResourceService.formatVideo(wpVideo)
      )
      const paginator: Paginator<Video> = Object.assign(wpVideoRes, {
        data: videos
      })
      return paginator
    })
    // TODO: Catch
  }

  // The way to get post by slug instead of Id (for SEO purposes) is to filter list by slug.
  show(id: number): Promise<Video> {
    return this.blogResourceService
      .show(this.videoSlug, id)
      .then((wpVideoRes: WPPost) =>
        this.formatResourceService.formatVideo(wpVideoRes)
      )
  }
}

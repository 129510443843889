import { Injectable } from '@angular/core'

import { ResourceSlug } from '../enums/wordpress/wp-resource-slug.enum'
import { VisualizationTaxonomy } from '../interfaces/resources/visualization-taxonomy.interfaces'
import { Visualization } from '../interfaces/resources/visualization.interface'
import { WPVisualization } from '../interfaces/wordpress/wp-visualization.interface'
import { BlogResourceService } from './blog-resource.service'
import { FormatResourceService } from './format-resource.service'

@Injectable({
  providedIn: 'root'
})
export class VisualizationService {
  constructor(
    private blogResourceService: BlogResourceService,
    private formatResourceService: FormatResourceService
  ) {}

  async listByAreas({ areaId }: { areaId?: number }): Promise<Visualization[]> {
    return (
      this.blogResourceService.list(ResourceSlug.Visualization, {
        withoutPagination: true,
        per_page: 100,
        areas: areaId,
        order: 'asc',
        orderby: 'date'
      }) as Promise<WPVisualization[]>
    ).then((wpVisualizationRes: WPVisualization[]) =>
      wpVisualizationRes.map((wpVisualization) =>
        this.formatResourceService.formatVisualization(wpVisualization)
      )
    )
  }

  async listIsFeatured(): Promise<Visualization[]> {
    return (
      this.blogResourceService.list(ResourceSlug.Visualization, {
        isFeatured: true,
        withoutPagination: true
      }) as Promise<WPVisualization[]>
    ).then((wpVisualizationRes: WPVisualization[]) =>
      wpVisualizationRes.map((wpVisualization) =>
        this.formatResourceService.formatVisualization(wpVisualization)
      )
    )
  }

  async listSources({
    ids
  }: {
    ids: number[]
  }): Promise<VisualizationTaxonomy[]> {
    return (
      this.blogResourceService.list(ResourceSlug.Sources, {
        withoutPagination: true,
        per_page: 100,
        include: ids.join(',')
      }) as Promise<WPVisualization[]>
    ).then((wpVisualizationTaxRes: WPVisualization[]) =>
      wpVisualizationTaxRes.map((wpVisualizationTax) =>
        this.formatResourceService.formatVisualizationTax(wpVisualizationTax)
      )
    )
  }

  async listGroups({
    ids
  }: {
    ids: number[]
  }): Promise<VisualizationTaxonomy[]> {
    return (
      this.blogResourceService.list(ResourceSlug.Groups, {
        withoutPagination: true,
        per_page: 100,
        include: ids.filter((id) => !!id).join(',')
      }) as Promise<WPVisualization[]>
    ).then((wpVisualizationTaxRes: WPVisualization[]) =>
      wpVisualizationTaxRes.map((wpVisualizationTax) =>
        this.formatResourceService.formatVisualizationTax(wpVisualizationTax)
      )
    )
  }
}

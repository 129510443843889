<div
  class="block"
  [ngClass]="{ 'block--featured': initiative.featured }"
  *ngFor="let initiative of initiatives"
>
  <div class="block__wrapper py-5">
    <div class="block__media block-left" *ngIf="showImages">
      <a [href]="initiative.externalLink" target="_blank" class="full-width">
        <figure class="contain p-a-gutter">
          <img
            *ngIf="initiative.logo"
            [src]="initiative.logo"
            [alt]="initiative.company + ' logo'"
          />
          <img
            *ngIf="!initiative.logo"
            src="./assets/images/oecd-default-img.svg"
            [alt]="initiative.company"
          />
        </figure>
      </a>
    </div>
    <div class="block__content">
      <h2
        class="is-size-6 is-sans is-uppercase is-bold has-text-dark mb-0"
        *ngIf="initiative.externalLink"
      >
        <a [href]="initiative.externalLink" target="_blank">
          {{ initiative.title }}
        </a>
        <div class="control is-inline-flex ml-2" *ngIf="initiative.featured">
          <div class="tags has-addons">
            <span class="tag is-yellow-light">
              <i class="fas fa-star has-text-primary"></i>
            </span>
            <span class="tag is-yellow-light has-text-primary pl-0"
              >National AI strategy</span
            >
          </div>
        </div>
      </h2>

      <h2
        class="is-size-6 is-sans is-uppercase is-bold has-text-dark mb-0"
        *ngIf="initiative.link"
      >
        <a [appSmartLink]="initiative.link" target="_blank">
          {{ initiative.title }}
        </a>
        <div class="control is-inline-flex ml-2" *ngIf="initiative.featured">
          <div class="tags has-addons">
            <span class="tag is-yellow-light">
              <i class="fas fa-star has-text-primary"></i>
            </span>
            <span class="tag is-yellow-light has-text-primary pl-0"
              >National AI strategy</span
            >
          </div>
        </div>
      </h2>

      <h2
        class="is-size-6 is-sans is-uppercase is-bold has-text-dark mb-0"
        *ngIf="!initiative.externalLink && !initiative.link"
      >
        {{ initiative.title }}
        <div class="control is-inline-flex ml-2" *ngIf="initiative.featured">
          <div class="tags has-addons">
            <span class="tag is-yellow-light">
              <i class="fas fa-star has-text-primary"></i>
            </span>
            <span class="tag is-yellow-light has-text-primary pl-0"
              >National AI strategy</span
            >
          </div>
        </div>
      </h2>

      <p class="content is-small has-text-black-ter m-y-xs flex flex-wrap">
        <span class="p-r-xl meta" *ngIf="initiative.countryName">
          <i class="has-text-link icon icon-Public m-r-md"></i>
          <span>{{ initiative.countryName }}</span>
        </span>
        <span class="p-r-xl meta" *ngIf="initiative.category">
          <i class="has-text-link icon icon-Tags p-r-md"></i>
          <span>{{ initiative.category }}</span>
        </span>
        <ng-container *ngIf="initiative.stakeholderTypes">
          <span
            class="p-r-xl meta"
            *ngFor="let stakeholderType of initiative.stakeholderTypes"
          >
            <i class="has-text-link icon icon-Tags p-r-md"></i>
            <span>{{ stakeholderType.name }}</span>
          </span>
        </ng-container>

        <span class="p-r-xl meta" *ngIf="initiative.company">
          <i class="has-text-link icon icon-Gridview p-r-md"></i>
          <span>{{ initiative.company }}</span>
        </span>

        <span class="p-r-xl meta" *ngIf="initiative.organization">
          <i class="has-text-link icon icon-Gridview p-r-md"></i>
          <span>{{ initiative.organization }}</span>
        </span>

        <span class="p-r-xl meta" *ngIf="initiative.date">
          <i class="has-text-link icon icon-Calendar p-r-md"></i>
          <span>{{ initiative.date | date: 'LLLL yyyy' }}</span>
        </span>
      </p>
      <p class="content is-small has-text-black-ter m-t-xs">
        {{ initiative.description | truncate: [250] }}
      </p>
    </div>
  </div>
</div>

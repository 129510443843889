<nav
  class="breadcrumb"
  aria-label="breadcrumbs"
  [ngClass]="{ 'is-light has-text-light': darkTheme }"
>
  <ol
    itemscope
    itemtype="https://schema.org/BreadcrumbList"
    class="p-l-0 m-y-colgap"
    *ngIf="breadcrumbLinks"
  >
    <li
      itemprop="itemListElement"
      itemscope
      itemtype="https://schema.org/ListItem"
      *ngFor="
        let breadcrumbLink of breadcrumbLinks;
        let i = index;
        let last = last
      "
    >
      <a
        itemprop="item"
        [ngClass]="{ 'no-deco has-text-light': darkTheme, 'is-active': last }"
        [routerLink]="'/' + breadcrumbLink.path"
        *ngIf="breadcrumbLink.path"
      >
        <span itemprop="name">{{ breadcrumbLink.label }}</span>
      </a>

      <span
        itemprop="item"
        *ngIf="!breadcrumbLink.path"
        [ngClass]="{ 'is-active': last }"
      >
        <span itemprop="name">{{ breadcrumbLink.label }}</span>
      </span>
      <meta itemprop="position" [content]="i" />
    </li>
  </ol>
</nav>

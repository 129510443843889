import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ActivatedRoute, Params } from '@angular/router'
import * as moment from 'moment'

import { metaDataContent } from '../../../locale/meta-data/meta-data.content.en'
import { BreadcrumbLink } from '../../shared/interfaces/breadcrumb-link.interface'
import { Initiative } from '../../shared/interfaces/initiative.interface'
import { Area } from '../../shared/interfaces/resources/area.interface'
import { AreaService } from '../../shared/services/area.service'
import { BreadcrumbService } from '../../shared/services/breadcrumb.service'
import { MetaService } from '../../shared/services/meta.service'
import { initiatives } from './oecd-metrics-and-methods.content'

@Component({
  selector: 'app-oecd-metrics-and-methods',
  templateUrl: './oecd-metrics-and-methods.component.html',
  styleUrls: ['./oecd-metrics-and-methods.component.scss']
})
export class OecdMetricsAndMethodsComponent implements OnInit {
  bibliometricsArea: Area
  breadcrumbLinks: BreadcrumbLink[]

  initiatives: Initiative[] = initiatives.sort((a, b) =>
    moment(a.sortDate, 'MM-DD-YYYY').isBefore(moment(b.sortDate, 'MM-DD-YYYY'))
      ? 1
      : -1
  )

  tabForm: FormGroup

  constructor(
    private formBuilder: FormBuilder,
    private areaService: AreaService,
    private activatedRoute: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    metaService: MetaService
  ) {
    this.breadcrumbLinks = breadcrumbService.getBreadCrumbLinks(
      'OECDMetricsAndMethods'
    )
    metaService.setTags(metaDataContent.OECDMetricsAndMethods)
  }

  ngOnInit(): void {
    this.tabForm = this.formBuilder.group({
      selectedTab: 'publications'
    })

    this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
      if (queryParams.selectedArea) {
        this.tabForm.get('selectedTab').setValue(queryParams.selectedArea)
      }
    })

    this.areaService.list().then((areas: Area[]) => {
      this.bibliometricsArea = areas.find((a) => a.slug === 'bibliometrics')
    })
  }
}

<div class="container-full bg-theme">
  <div class="container">
    <div class="columns">
      <div class="column is-12 p-b-xl">
        <app-breadcrumbs
          [darkTheme]="hasDarkTheme"
          [breadcrumbLinks]="breadcrumbLinks"
        ></app-breadcrumbs>
        <h1 class="title is-1 has-text-light">
          {{ title }}
        </h1>
        <p class="has-text-light m-y-gutter">
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</div>
<div class="container-full">
  <div class="subnav bg-swamp">
    <div class="container">
      <div class="columns">
        <div class="column p-y-0">
          <div class="full-width subnav--list">
            <a
              *ngFor="let tab of tabs"
              class="subnav--item is-wips"
              [ngClass]="{
                'is-active': selectedTab === tab
              }"
              [routerLink]="tab.baseUrl"
              >{{ tab.label }}</a
            >
          </div>
        </div>
        <div
          class="column is-5 is-hidden-mobile has-text-right py-0"
          *ngIf="
            selectedTab.label === 'Countries & territories' ||
            selectedTab.label === 'Policy Instruments' ||
            selectedTab.label === 'Target Groups'
          "
        >
          <div
            class="is-flex is-justify-content-flex-end is-align-items-center is-fullwidth is-full-height"
          >

            <a
              *ngIf="allPoliciesFilePath"
              class="button is-warning ml-4 is-hidden-touch"
              style="max-width: 50%"
              [href]="allPoliciesFilePath"
              download=""
              >Download all AI policies</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

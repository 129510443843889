import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'truncate',
  standalone: true
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, args: number[]): string {
    if (!value) {
      return ''
    }
    const limit = args.length > 0 ? args[0] : 20
    const trail = args.length > 1 ? args[1] : '...'
    return value.length > limit ? value.substring(0, limit) + trail : value
  }
}

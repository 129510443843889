export enum ResourceSlug {
  Medias = 'media',
  Principles = 'ai-principles',
  Resources = 'ai-resources',
  Areas = 'areas',
  Users = 'users',
  Documents = 'documents',
  Events = 'events',
  Pages = 'pages',
  Tools = 'tools',
  ToolUseCases = 'tool-use-cases',
  Metrics = 'metrics',
  MetricUseCases = 'metric-use-cases',
  Videos = 'videos',
  Visualization = 'visualizations',
  Sources = 'sources',
  Groups = 'groups',
  Countries = 'countries',
  Posts = 'posts',
  Discussions = 'discussions',
  FeaturedCards = 'featured-cards',
  QuickLinks = 'quick-links',
  QuickLinkGroups = 'quick-link-groups',
  Publications = 'publications',
  AiPublications = 'ai-publications',
  PolicyInitiatives = 'policy-initiatives',

  // Taxonomies.
  ProgrammingLanguages = 'programming-languages',
  PolicyAreas = 'policy-areas',
  Tags = 'tags',
  Initiatives = 'initiatives',
  KeyThemes = 'wips-key-themes',
  ToolTypes = 'tool-types',
  Lifecycles = 'lifecycles',
  WorkingGroups = 'working-groups',
  StakeholderTypes = 'stakeholder-types',
  ImpactedStakeholder = 'impacted-stakeholders',
  StakeholderGroups = 'stakeholder-groups',
  Purposes = 'purposes',
  Licenses = 'licenses',
  TargetUsers = 'target-users',
  TargetSectors = 'target-sectors',
  TargetGroups = 'target-groups',
  UsageRights = 'usage-rights',
  Maturity = 'maturities',
  Organisations = 'organisations',
  Objectives = 'objectives',
  Approaches = 'approaches',
  ExternalLink = 'external-links',
  ExternalLinkType = 'external-link-types',

  // Hidden Tool filters
  GeographicalScope = 'geographical-scopes',
  Enforcement = 'enforcements',
  Benefit = 'benefits',
  PeopleInvolved = 'people-involveds',
  RequiredSkills = 'required-skills',
  TechnologyPlatform = 'technology-platforms',
  Validities = 'validities',
  MetricTypes = 'metric-types',
  Risks = 'risks'
}

import { Initiative } from '../../shared/interfaces/initiative.interface'
export const initiatives: Initiative[] = [
  {
    title:
      'Identifying and measuring developments in artificial intelligence: Making the impossible possible',
    company: '',
    logo: '/assets/images/identifying-and-measuring-developments-in-artificial-intelligence.png',
    category: 'Book',
    description:
      'This paper identifies and measures developments in science, algorithms and technologies related to artificial intelligence (AI). Using information from scientific publications, open source software (OSS) and patents, it finds a marked increase in AI-related developments over recent years.',
    externalLink: 'https://doi.org/10.1787/5f65ff7e-en',
    date: 'May 2020',
    sortDate: '05-01-2020'
  },
  {
    title: 'Shaping the future of technologies and of AI',
    company: '',
    logo: '/assets/images/book.jpg',
    category: 'Working paper',
    description:
      'New JRC-OECD report World corporate top R&D investors: Shaping the future of technologies and AI. It is a useful source of data and analysis for anyone interested in the scientific and technological activities of key industry players, particularly in the field of AI.',
    externalLink:
      'https://ec.europa.eu/jrc/en/science-update/shaping-future-technologies-and-ai',
    date: 'September 2019',
    sortDate: '09-01-2019'
  },
  {
    title: 'Roadmap: The digitalisation of science',
    company: '',
    logo: '/assets/images/book-roadmap.jpg',
    category:
      'Chapter in Measuring the Digital Transformation: A Roadmap for the Future',
    description:
      'The OECD was asked to monitor the transformation of digital technologies and invited to convene the international community working on STI data and indicators to develop new thinking and solutions for empirical evidence to guide policy...',
    externalLink:
      'https://www.oecd-ilibrary.org/fr/science-and-technology/measuring-the-digital-transformation_a5c1bd3a-en',
    date: 'March 2019',
    sortDate: '03-01-2019'
  },
  {
    title: 'Measuring the Digital Transformation: A Roadmap for the Future',
    company: '',
    logo: '/assets/images/book-roadmap.jpg',
    category: 'Book',
    description:
      'This book maps indicators across a range of areas against current digital policy issues, to identify gaps in the current measurement framework, assesses progress made towards filling these gaps and set out a forward-looking measurement roadmap. The goal is to expand the evidence base...',
    externalLink:
      'https://www.oecd-ilibrary.org/fr/science-and-technology/measuring-the-digital-transformation_9789264311992-en',
    date: 'March 2019',
    sortDate: '03-01-2019'
  },
  {
    title: 'Private Equity Investment in Artificial Intelligence',
    company: '',
    logo: '/assets/images/book3.jpg',
    category: 'Policy brief',
    description:
      'After five years of steady increases, private equity investment in AI has accelerated since 2016, with the amount of private equity invested doubling from 2016 to 2017. In total, it is estimated that more than USD 50 billion was invested in AI start-ups during the period 2011 through to mid-2018. The surge in private investment suggests that investors are increasingly aware of the potential of AI, and are crafting their investment strategies accordingly.',
    externalLink:
      'https://www.oecd.org/going-digital/ai/private-equity-investment-in-artificial-intelligence.pdf',
    date: 'December 2018',
    sortDate: '12-01-2018'
  },
  {
    title:
      'OECD Science, Technology and Innovation Scoreboard: The digital transformation',
    company: '',
    logo: '/assets/images/book2-bold.jpg',
    category: 'Book',
    description:
      'With some 200 indicators, the 2017 edition of the OECD Science, Technology and Industry (STI) Scoreboard shows how the digital transformation affects science, innovation, the economy, and the way people work and live. It can help governments design more effective science, innovation and industry policies in the fast-changing digital era. The charts and underlying data in this publication are available for download and over half the indicators contain additional data expanding the time and/or country coverage of the print edition.',
    externalLink:
      'https://www.oecd-ilibrary.org/fr/science-and-technology/oecd-science-technology-and-industry-scoreboard-2017_9789264268821-en',
    date: 'November 2017',
    sortDate: '11-01-2017'
  },
  {
    title: 'Artificial Intelligence in Society',
    company: '',
    logo: '/assets/images/book-ai-in-society.jpg',
    category: 'Book',
    description:
      'This report helps build a shared understanding of AI in the present and near-term by mapping the AI technical, economic, use case and policy landscape and identifying major public policy considerations. It is also intended to help co-ordination…',
    externalLink:
      'https://www.oecd-ilibrary.org/science-and-technology/artificial-intelligence-in-society_eedfee77-en',
    date: 'June 2019',
    sortDate: '06-01-2019'
  }
]

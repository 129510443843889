import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AboutComponent } from './about.component'
import { PartnersComponent } from '../partners/partners.component'

const routes: Routes = [
  {
    path: 'the-context',
    component: AboutComponent,
    data: { slug: 'the-context' }
  },
  {
    path: 'about-gpai',
    component: AboutComponent,
    data: { slug: 'what-we-do' }
  },
  {
    path: 'background',
    component: AboutComponent,
    data: { slug: 'background' }
  },
  {
    path: 'partners',
    component: PartnersComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AboutRoutingModule {
}

import { Injectable } from '@angular/core'
import { ResourceSlug } from '../enums/wordpress/wp-resource-slug.enum'
import { Area } from '../interfaces/resources/area.interface'
import { WPArea } from '../interfaces/wordpress/wp-area.interface'
import { BlogResourceService } from './blog-resource.service'
import { FormatResourceService } from './format-resource.service'

@Injectable({
  providedIn: 'root'
})
export class AreaService {
  constructor(
    private blogResourceService: BlogResourceService,
    private formatResourceService: FormatResourceService
  ) {}

  list(): Promise<Area[]> {
    return (
      this.blogResourceService.list(ResourceSlug.Areas, {
        withoutPagination: true,
        per_page: 100
      }) as Promise<WPArea[]>
    ).then((wpAreaRes: WPArea[]) =>
      wpAreaRes.map((wpArea) => this.formatResourceService.formatArea(wpArea))
    )
  }

  show(slug: string): Promise<Area> {
    return (
      this.blogResourceService.showBySlug(
        ResourceSlug.Areas,
        slug
      ) as Promise<WPArea>
    ).then((wpArea: WPArea) => this.formatResourceService.formatArea(wpArea))
  }
}

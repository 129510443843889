import { isPlatformBrowser } from '@angular/common'
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
  ViewChild
} from '@angular/core'
import { Router } from '@angular/router'
import { HTMLInputEvent } from '../../interfaces/html-input-event.interface'

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  standalone: true
})
export class SearchBarComponent implements OnChanges {
  @ViewChild('searchInput', { static: true }) private searchInputEl: ElementRef
  @Input() showSearch: boolean

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private router: Router
  ) {}

  ngOnChanges() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => this.searchInputEl.nativeElement.focus(), 500)
    }
  }

  search() {
    this.router.navigate(['/search'], {
      queryParams: { q: this.searchInputEl.nativeElement.value }
    })
  }

  onKeydown(event: HTMLInputEvent) {
    if (event.key === 'Enter') {
      this.router.navigate(['/search'], {
        queryParams: { q: event.target.value }
      })
    }
  }
}

import { Component } from '@angular/core'

import { metaDataContent } from '../../../locale/meta-data/meta-data.content.en'
import { BreadcrumbLink } from '../../shared/interfaces/breadcrumb-link.interface'
import { BreadcrumbService } from '../../shared/services/breadcrumb.service'
import { MetaService } from '../../shared/services/meta.service'

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component {
  breadcrumbLinks: BreadcrumbLink[]

  constructor(breadcrumbService: BreadcrumbService, metaService: MetaService) {
    this.breadcrumbLinks = breadcrumbService.getBreadCrumbLinks('error404')
    metaService.setTags(metaDataContent.error404)
  }
}

<section>
  <div class="container-full bg-theme">
    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <app-breadcrumbs
            [darkTheme]="true"
            [breadcrumbLinks]="breadcrumbLinks"
          ></app-breadcrumbs>
          <h1 i18n="@@participantListTitle" class="title is-1 has-text-light">
            List of participants in the OECD Expert Group on AI (AIGO)
          </h1>
          <p i18n="@@participantListBaseline" class="has-text-light m-y-gutter">
            The following experts contributed to the work of the AIGO as
            members. Their contributions are gratefully acknowledged.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container m-t-rel">
    <div class="columns">
      <div class="column is-12">
        <!-- Contributors -->
        <div class="table table-responsive is-striped is-fullwidth">
          <table>
            <thead>
              <tr>
                <th i18n="@@participantListTableName">Name</th>
                <th i18n="@@participantListTableTitle">Title</th>
                <th i18n="@@participantListTableOrganisation">
                  Organisation / Country
                </th>
                <th i18n="@@participantListTableGroup">Group / Delegation</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let participant of contributors">
                <td width="180">{{ participant.name }}</td>
                <td>{{ participant.title }}</td>
                <td width="326">{{ participant.organization }}</td>
                <td width="204">{{ participant.group }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <p i18n="@@participantListSubtitle2ndTable">
          Beside the members, the work of the AIGO benefited from the
          contributions and input of other experts. We gratefully acknowledge
          the contributions of:
        </p>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <!-- Other contributors -->
        <div class="table table-responsive is-striped is-fullwidth">
          <table>
            <thead>
              <tr>
                <th i18n="@@participantList2ndTableName">Name</th>
                <th i18n="@@participantList2ndTableTitle">Title</th>
                <th i18n="@@participantList2ndTableOrganisation">
                  Organisation / Country
                </th>
                <th i18n="@@participantList2ndTableGroup">
                  Group / Delegation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let participant of otherContributors">
                <td width="180">{{ participant.name }}</td>
                <td>{{ participant.title }}</td>
                <td width="326">{{ participant.organization }}</td>
                <td width="204">{{ participant.group }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <p i18n="@@participantListSubtitle3rdTable">
          In addition, we thank the following experts for their contributions to
          the work of the AIGO subgroups:
        </p>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <div class="table table-responsive is-striped is-fullwidth">
          <!-- Aditional experts -->
          <table>
            <thead>
              <tr>
                <th i18n="@@participantList3rdTableName">Name</th>
                <th i18n="@@participantList3rdTableTitle">Title</th>
                <th i18n="@@participantList3rdTableOrganisation">
                  Organisation / Country
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let participant of additionalExperts">
                <td width="180">{{ participant.name }}</td>
                <td>{{ participant.title }}</td>
                <td width="326">{{ participant.organization }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <p i18n="@@participantListGreetings">
          The support of MIT Internet Policy Research Initiative and of the UAE
          Ministry of AI, which each hosted an AIGO meeting, is also gratefully
          acknowledged.
        </p>
      </div>
    </div>
  </div>
</section>

import { NgIf } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { RouterLink } from '@angular/router'
import * as moment from 'moment'

import { EventService } from '../../services/event.service'
import { NewsletterFormComponent } from '../newsletter-form/newsletter-form.component'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [NewsletterFormComponent, NgIf, RouterLink]
})
export class FooterComponent implements OnInit {
  currentYear: string = moment().format('YYYY')

  isHome: boolean

  constructor(private eventService: EventService) {}

  ngOnInit() {
    this.eventService.routeChanged.subscribe(
      (routeChanged: { url: string }) => {
        this.isHome = routeChanged.url === '/'
      }
    )
  }
}

import { DatePipe, NgClass, NgIf } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { RouterLink } from '@angular/router'

import { DocumentLayout } from '../../../enums/document-layout.enum'
import { FileMime } from '../../../enums/file-mime.enum'
import { Document } from '../../../interfaces/resources/document.interface'

@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, RouterLink, DatePipe]
})
export class DocumentCardComponent implements OnInit {
  @Input() document: Document
  @Input() layout: DocumentLayout = DocumentLayout.Regular

  mime: FileMime
  FileMime = FileMime
  DocumentLayout = DocumentLayout

  ngOnInit() {
    this.mime = this.setMime(this.document.path)
  }

  setMime(path: string): FileMime {
    const extension: string = (path || '').split('.').pop()

    if (extension === 'xls' || extension === 'xlsx' || extension === 'csv') {
      return FileMime.Excel
    } else if (extension === 'pdf') {
      return FileMime.Pdf
    } else if (extension === 'ppt') {
      return FileMime.Ppt
    } else {
      return FileMime.Other
    }
  }
}

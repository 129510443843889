import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'

import { NgFor, NgIf } from '@angular/common'
import { metaDataContent } from '../../../../../locale/meta-data/meta-data.content.en'
import { AuthorLayout } from '../../../enums/author-layout.enum'
import { Author } from '../../../interfaces/author.interface'
import { BreadcrumbLink } from '../../../interfaces/breadcrumb-link.interface'
import { Paginator } from '../../../interfaces/paginator.interface'
import { BreadcrumbsComponent } from '../../../partials/breadcrumbs/breadcrumbs.component'
import { ListMetaComponent } from '../../../partials/list-meta/list-meta.component'
import { PaginationComponent } from '../../../partials/pagination/pagination.component'
import { AuthorService } from '../../../services/author.service'
import { BreadcrumbService } from '../../../services/breadcrumb.service'
import { MetaService } from '../../../services/meta.service'
import { AuthorCardComponent } from '../author-card/author-card.component'
import { AuthorDisclaimerComponent } from '../author-disclaimer/author-disclaimer.component'
import { AuthorFilterComponent } from '../author-filter/author-filter.component'

@Component({
  selector: 'app-author-list',
  templateUrl: './author-list.component.html',
  styleUrls: ['./author-list.component.scss'],
  standalone: true,
  imports: [
    AuthorFilterComponent,
    AuthorCardComponent,
    ListMetaComponent,
    PaginationComponent,
    AuthorDisclaimerComponent,
    BreadcrumbsComponent,
    NgIf,
    NgFor
  ]
})
export class AuthorListComponent implements OnInit {
  paginator: Paginator<Author>
  authors: Author[]

  selectedParams: {
    role?: string
    workingGroupId?: string
    terms?: string
    countryId?: string
    stakeholderTypeId?: string
  }

  breadcrumbLinks: BreadcrumbLink[]
  loading: boolean
  AuthorLayout = AuthorLayout

  currentPath = '/community'

  constructor(
    private authorService: AuthorService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    metaService: MetaService,
    breadcrumbService: BreadcrumbService
  ) {
    metaService.setTags(metaDataContent.authorList)
    this.breadcrumbLinks = breadcrumbService.getBreadCrumbLinks('blogAuthors')
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
      this.loading = true

      this.selectedParams = {
        role: queryParams && queryParams.role ? queryParams.role : '',
        workingGroupId:
          queryParams && queryParams.workingGroupId
            ? queryParams.workingGroupId
            : '',
        countryId:
          queryParams && queryParams.countryId ? queryParams.countryId : '',
        stakeholderTypeId:
          queryParams && queryParams.stakeholderTypeId
            ? queryParams.stakeholderTypeId
            : '',
        terms: queryParams && queryParams.terms ? queryParams.terms : ''
      }
      ;(
        this.authorService.list({
          workingGroup: this.selectedParams.workingGroupId,
          role: this.selectedParams.role,
          page: queryParams && queryParams.page,
          country: this.selectedParams.countryId,
          stakeholderType: this.selectedParams.stakeholderTypeId,
          terms: this.selectedParams.terms
        }) as Promise<Paginator<Author>>
      ).then((authorRes: Paginator<Author>) => {
        this.loading = false
        this.paginator = authorRes
      })
    })
  }

  goToPage(pageNumber: number) {
    const queryParams: Params = { page: pageNumber.toString() }
    this.router.navigate([this.currentPath], {
      queryParams,
      queryParamsHandling: 'merge'
    })
  }
}

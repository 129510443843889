import { NgClass, NgFor, NgIf } from '@angular/common'
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { Router } from '@angular/router'
import { fromEvent, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators'

import { Author } from '../../../interfaces/author.interface'
import { Paginator } from '../../../interfaces/paginator.interface'
import { WorkingGroup } from '../../../interfaces/resources/working-group.interface'
import { TaxonomyTerm } from '../../../interfaces/taxonomy-term.interface'
import { BlogResourceService } from '../../../services/blog-resource.service'
import { WorkingGroupService } from '../../../services/working-group.service'

@Component({
  selector: 'app-author-filter',
  templateUrl: './author-filter.component.html',
  styleUrls: ['./author-filter.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, NgFor, NgClass]
})
export class AuthorFilterComponent implements OnInit {
  @ViewChild('searchInput', { static: true })
  searchEl: ElementRef

  @Input() formParams: {
    role?: string
    workingGroupId?: string
    terms?: string
    countryId?: string
    stakeholderTypeId?: string
  }

  @Input() filterByWorkingGroup = true
  @Input() currentPath: string

  searchTerms: Subject<string> = new Subject()

  authors: Author[]
  paginator: Paginator<Author>
  workingGroups: WorkingGroup[]
  stakeholderTypes: TaxonomyTerm[]
  countries: TaxonomyTerm[]

  filterForm: FormGroup

  constructor(
    private workingGroupService: WorkingGroupService,
    private blogResourceService: BlogResourceService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      role: '',
      workingGroupId: '',
      terms: '',
      countryId: '',
      stakeholderTypeId: ''
    })

    if (this.formParams.terms) {
      this.searchEl.nativeElement.value = this.formParams.terms
      setTimeout(() => this.searchEl.nativeElement.focus(), 500)
    }

    fromEvent(this.searchEl.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe((terms: string) => {
        this.filterForm.patchValue({
          terms: terms
        })
      })

    if (this.filterByWorkingGroup) {
      this.workingGroupService
        .list()
        .then((workingGroupRes: WorkingGroup[]) => {
          this.workingGroups = workingGroupRes
        })
    }

    // Get WP API Taxonomy Terms.
    (
      this.blogResourceService.list('stakeholder-types', {
        withoutPagination: true
      }) as Promise<TaxonomyTerm[]>
    ).then((stakeholderTypeRes: TaxonomyTerm[]) => {
      this.stakeholderTypes = stakeholderTypeRes
    })
    ;(
      this.blogResourceService.list('countries', {
        withoutPagination: true
      }) as Promise<TaxonomyTerm[]>
    ).then((countriesRes: TaxonomyTerm[]) => {
      this.countries = countriesRes
    })

    this.filterForm.patchValue({
      role: this.formParams.role || '',
      workingGroupId: this.formParams.workingGroupId || '',
      countryId: this.formParams.countryId || '',
      stakeholderTypeId: this.formParams.stakeholderTypeId || '',
      terms: this.formParams.terms
    })

    this.filterForm.valueChanges.subscribe((formValue) => {
      this.router.navigate([this.currentPath], {
        queryParams: Object.assign(formValue, { page: 1 })
      })
    })
  }

  clear() {
    this.searchEl.nativeElement.value = ''
    this.filterForm.patchValue({
      role: this.formParams.role || '',
      workingGroupId: this.formParams.workingGroupId || '',
      countryId: this.formParams.countryId || '',
      stakeholderTypeId: this.formParams.stakeholderTypeId || '',
      terms: ''
    })
  }
}

import { isPlatformBrowser } from '@angular/common'
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2
} from '@angular/core'
import { FeaturedCardLayout } from 'src/app/shared/enums/featured-card-layout.enum'

import { flashMessages } from '../../../locale/flash-messages/flash-messages.en'
import { metaDataContent } from '../../../locale/meta-data/meta-data.content.en'
import { FeaturedCard } from '../../shared/interfaces/resources/featured-card.interface'
import { Post } from '../../shared/interfaces/resources/post.interface'
import { Visualization } from '../../shared/interfaces/resources/visualization.interface'
import { FeaturedCardService } from '../../shared/services/featured-card.service'
import { FlashMessageService } from '../../shared/services/flash-message.service'
import { MetaService } from '../../shared/services/meta.service'
import { PostService } from '../../shared/services/post.service'
import { VisualizationService } from '../../shared/services/visualization.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  posts: Post[]
  featuredCards: FeaturedCard[]
  visualizations: Visualization[]

  loadingPosts: boolean
  loadingWordMap: boolean
  loadingFeaturedCards = true
  showVideoModal = false
  videoSourceMP4 = 'assets/videos/video-test-MP4.mp4'
  videoSourceWEBM = 'assets/videos/video-test-WEB.webm'

  isClientApp: boolean

  FeaturedCardLayout = FeaturedCardLayout

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private postService: PostService,
    private featuredCardService: FeaturedCardService,
    private visualizationService: VisualizationService,
    private flashMessageService: FlashMessageService,
    private renderer: Renderer2,
    metaService: MetaService
  ) {
    metaService.setTags(metaDataContent.home)
    this.isClientApp = isPlatformBrowser(this.platformId)
  }

  async ngOnInit() {
    // SSR Performance : Only the clientApp will make hose API calls.
    if (this.isClientApp) {
      this.loadingPosts = true
      this.postService
        .listUnpaginated({ featuredPortal: true, limitTo: 10 })
        .then((postRes: Post[]) => {
          this.loadingPosts = false
          this.posts = postRes
        })
        .catch(() => {
          this.loadingPosts = false
          this.flashMessageService.error(
            flashMessages.home.error.cannotFetchNews
          )
        })

      this.loadingWordMap = true
    }

    this.featuredCardService
      .list({ limitTo: 5, featuredOnHomeOnly: true })
      .then((res: FeaturedCard[]) => {
        this.loadingFeaturedCards = false
        this.featuredCards = res.sort((a: FeaturedCard, b: FeaturedCard) => {
          return a.order - b.order
        })
      })

    this.visualizationService
      .listIsFeatured()
      .then((visualizationRes: Visualization[]) => {
        this.visualizations = visualizationRes.sort(
          (a: Visualization, b: Visualization) => {
            return b.order - a.order
          }
        )
      })
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target: any) {
    // We track clicks to close model if open and click outside
    if (this.showVideoModal && target.className === 'modal-background') {
      this.showVideoModal = false
      // Stop video from playing by resetting its source.
      const playerEl: Element = document.querySelector('#video-player')
      this.renderer.setAttribute(playerEl, 'src', '')
    }
  }
}

<div class="searchbar-wrap flex align-center justify-center">
  <input
    type="text"
    class="input"
    #searchInput
    (keydown)="onKeydown($event)"
    placeholder="Search OECD.AI..."
    i18n-placeholder="@@search_barPlaceholder"
  />
  <button class="button" (click)="search()">Search</button>
</div>

import { Component, Input, OnInit } from '@angular/core'
import { ViewportService } from '../../services/viewport.service'
import { NgxOecdAiDatavizModule } from 'ngx-oecd-ai-dataviz'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'app-visualization-toolkit-chart',
  standalone: true,
  imports: [NgxOecdAiDatavizModule],
  template: `
    <div class="content">
      <div
        class="widget-detail-wrapper-chart"
        [style]="{ width: '100%' }"
      >
        <lib-oecd-ai-dataviz
          [id]="chartId"
          [isMobile]="isMobile"
          [selectedFiltersHash]="selectedFiltersHash"
          [unboundedHeight]="true"
          (selectedFiltersChange)="handleSelectedFiltersChange($event)"
        />
      </div>
    </div>
  `
})
export class VisualizationToolkitChartComponent implements OnInit {
  @Input() selectedFiltersHash: string
  @Input() chartId: string

  isMobile: boolean

  constructor(
    private viewportService: ViewportService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.viewportService.isMobileResolution.subscribe((isMobile) => {
      this.isMobile = isMobile
    })
  }

  /**
   * Adds the selected filters hash to the URL query params.
   *
   * @param visualizationFilterHash The selected filters hash.
   */
  handleSelectedFiltersChange(visualizationFiltersHash: string): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { visualizationFiltersHash },
      queryParamsHandling: 'merge'
    })
  }
}

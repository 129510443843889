import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import {
  BrowserModule,
  provideClientHydration
} from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TransferHttpCacheModule } from '@nguniversal/common'
import { DISQUS_SHORTNAME } from 'ngx-disqus'

import Bugsnag from '@bugsnag/js'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { DashboardSearchComponent } from './modules/dashboard/dashboards/dashboard-search/dashboard-search.component'
import { AiIndexComponent } from './pages/ai-index/ai-index.component'
import { ClassificationSurveyComponent } from './pages/classification-survey/classification-survey.component'
import { ContactComponent } from './pages/contact/contact.component'
import { Error404Component } from './pages/error404/error404.component'
import { FaqComponent } from './pages/faq/faq.component'
import { HomeComponent } from './pages/home/home.component'
import { LiveDataFromPartnersComponent } from './pages/live-data-from-partners/live-data-from-partners.component'
import { NetworksOfExpertsComponent } from './pages/networks-of-experts/networks-of-experts.component'
import { OecdMetricsAndMethodsComponent } from './pages/oecd-metrics-and-methods/oecd-metrics-and-methods.component'
import { ParticipantListComponent } from './pages/participant-list/participant-list.component'
import { ResourcesOnAiComponent } from './pages/resources-on-ai/resources-on-ai.component'
import { SearchComponent } from './pages/search/search.component'
import { SurveyComponent } from './pages/survey/survey.component'
import { TrendsAndDataComponent } from './pages/trends-and-data/trends-and-data.component'
import { PageDetailComponent } from './resources/page/page-detail/page-detail.component'
import { VideoListComponent } from './resources/video/video-list/video-list.component'
import { SmartLinkDirective } from './shared/directives/smart-link.directive'
import { BreadcrumbsComponent } from './shared/partials/breadcrumbs/breadcrumbs.component'
import {
  DataVisualizationPanelComponent
} from './shared/partials/data-visualization-panel/data-visualization-panel.component'
import { FeaturedCardListComponent } from './shared/partials/featured-card-list/featured-card-list.component'
import { FeaturedCardComponent } from './shared/partials/featured-card/featured-card.component'
import { FlagListComponent } from './shared/partials/flag-list/flag-list.component'
import { FlashMessageComponent } from './shared/partials/flash-message/flash-message.component'
import { FooterComponent } from './shared/partials/footer/footer.component'
import { HeadComponent } from './shared/partials/head/head.component'
import { HeaderTouchComponent } from './shared/partials/header-touch/header-touch.component'
import { HeaderComponent } from './shared/partials/header/header.component'
import { InitiativeListComponent } from './shared/partials/initiative-list/initiative-list.component'
import { ListMetaComponent } from './shared/partials/list-meta/list-meta.component'
import { NewsletterFormComponent } from './shared/partials/newsletter-form/newsletter-form.component'
import { PaginationComponent } from './shared/partials/pagination/pagination.component'
import { PriorityIssueCardComponent } from './shared/partials/priority-issue-card/priority-issue-card.component'
import {
  PriorityIssueCarrouselComponent
} from './shared/partials/priority-issue-carousel/priority-issue-carousel.component'
import { QuickLinkListComponent } from './shared/partials/quick-link-list/quick-link-list.component'
import { TermsComponent } from './shared/partials/terms/terms.component'
import { StripHtmlPipe } from './shared/pipes/strip-html.pipe'
import { TruncatePipe } from './shared/pipes/truncate.pipe'
import { DocumentListComponent } from './shared/resources/document/document-list/document-list.component'
import { PostCardComponent } from './shared/resources/post/post-card/post-card.component'
import { PostCarouselComponent } from './shared/resources/post/post-carousel/post-carousel.component'
import { VideoCardComponent } from './shared/resources/video/video-card/video-card.component'
import {
  VisualizationToolkitChartComponent
} from './shared/partials/visualization-toolkit-chart/visualization-toolkit-chart.component'
import { NgxOecdAiDatavizModule } from 'ngx-oecd-ai-dataviz'
import {
  AboutNavigationBarComponent
} from './shared/components/about-section/about-navigation-bar/about-navigation-bar.component'
import {
  AboutSectionHeadComponent
} from './shared/components/about-section/about-section-head/about-section-head.component'
import { AboutModule } from './pages/about/about.module'

if (environment.enableBugsnag) {
  Bugsnag.start({
    apiKey: 'c7f04b4b37cbe41d375491e8497c4509',
    releaseStage: environment.envName
  })
}

@NgModule({
  declarations: [
    AppComponent,

    // Components: Pages
    ClassificationSurveyComponent,
    ContactComponent,
    Error404Component,
    FaqComponent,
    HomeComponent,
    NetworksOfExpertsComponent,
    OecdMetricsAndMethodsComponent,
    ParticipantListComponent,
    ResourcesOnAiComponent,
    SearchComponent,
    SurveyComponent,
    TrendsAndDataComponent,
    LiveDataFromPartnersComponent,
    // shared,
    // Components: Resources.
    PageDetailComponent,
    VideoListComponent,
    PriorityIssueCarrouselComponent,
    PriorityIssueCardComponent,
    AiIndexComponent

  ],
  imports: [
    BrowserModule,
    TransferHttpCacheModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    // Standalone
    FooterComponent,
    FlashMessageComponent,
    HeaderComponent,
    HeaderTouchComponent,
    HeadComponent,
    DashboardSearchComponent,
    PostCardComponent,
    BreadcrumbsComponent,
    ListMetaComponent,
    VideoCardComponent,
    PaginationComponent,
    TermsComponent,
    DataVisualizationPanelComponent,
    InitiativeListComponent,
    FlagListComponent,
    NewsletterFormComponent,
    PostCarouselComponent,
    FeaturedCardComponent,
    FeaturedCardListComponent,
    QuickLinkListComponent,
    DocumentListComponent,
    SmartLinkDirective,
    StripHtmlPipe,
    TruncatePipe,
    VisualizationToolkitChartComponent,
    NgxOecdAiDatavizModule,
    AboutNavigationBarComponent,
    AboutSectionHeadComponent,
    AboutModule
  ],
  providers: [
    provideClientHydration(),
    StripHtmlPipe,
    TruncatePipe,
    {
      provide: DISQUS_SHORTNAME,
      useValue: environment.disqusId
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

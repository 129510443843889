<div class="full-width subnav--list">
  <a class="subnav--item is-about"
     routerLinkActive="is-active"
     routerLink="/about/the-context">The Context</a>
  <a class="subnav--item is-about"
     routerLinkActive="is-active"
     routerLink="/about/about-gpai">About GPAI</a>
  <a class="subnav--item is-about"
     routerLinkActive="is-active"
     routerLink="/about-oecd-ai">About OECD.AI</a>
  <a class="subnav--item is-about"
     routerLinkActive="is-active"
     routerLink="/about-aigo">About AIGO</a>
  <a class="subnav--item is-about"
     routerLinkActive="is-active"
     routerLink="/about/background">Background</a>
  <a class="subnav--item is-about"
     routerLinkActive="is-active"
     routerLink="/about/partners">Partners</a>
</div>

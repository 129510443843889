import { Principle } from '../../app/shared/interfaces/principle.interface'

export const principles: Principle[] = [
  {
    uri: 'http://ai.oecd.org/taxonomy/principles#P5',
    label: 'Inclusive growth, sustainable development and well-being',
    code: '1_1',
    description: null,
    icon: 'p5',
    link: {
      view: 'AIPrincipleDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/principles#P5'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/principles#P6',
    label: 'Human rights and democratic values, including fairness and privacy',
    code: '1_2',
    description: null,
    icon: 'p6',
    link: {
      view: 'AIPrincipleDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/principles#P6'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/principles#P7',
    label: 'Transparency and explainability',
    code: '1_3',
    description: null,
    icon: 'p7',
    link: {
      view: 'AIPrincipleDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/principles#P7'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/principles#P8',
    label: 'Robustness, security and safety',
    code: '1_4',
    description: null,
    icon: 'p8',
    link: {
      view: 'AIPrincipleDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/principles#P8'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/principles#P9',
    label: 'Accountability',
    code: '1_5',
    description: null,
    icon: 'p9',
    link: {
      view: 'AIPrincipleDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/principles#P9'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/principles#P10',
    label: 'Investing in AI research and development',
    code: '2_1',
    description: null,
    icon: 'p10',
    link: {
      view: 'AIPrincipleDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/principles#P10'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/principles#P11',
    label: 'Fostering an inclusive AI-enabling ecosystem',
    code: '2_2',
    description: null,
    icon: 'p11',
    link: {
      view: 'AIPrincipleDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/principles#P11'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/principles#P12',
    label: 'Shaping an enabling interoperable governance and policy environment for AI',
    code: '2_3',
    description: null,
    icon: 'p12',
    link: {
      view: 'AIPrincipleDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/principles#P12'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/principles#P13',
    label: 'Building human capacity and preparing for labour market transition',
    code: '2_4',
    description: null,
    icon: 'p13',
    link: {
      view: 'AIPrincipleDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/principles#P13'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/principles#P14',
    label: 'International co-operation for trustworthy AI',
    code: '2_5',
    description: null,
    icon: 'p14',
    link: {
      view: 'AIPrincipleDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/principles#P14'
      }
    },
    subitems: []
  }
]

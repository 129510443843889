<div class="card m-b-md">
  <div class="columns">
    <ng-container *ngIf="visualizations && visualizations.length > 1">
      <div
        class="column is-3 is-hidden-touch"
        *ngIf="isSourcesExtendable === false"
      >
        <ul class="no-lst m-y-sm ai-block-sidebar">
          <li *ngFor="let source of sources">
            <ng-container *ngIf="source.slug !== 'noSource'">
              <h5
                class="title is-6 is-uppercase sans is-bold has-text-dark m-b-0 m-t-xl"
              >
                {{ source.name }}
              </h5>
            </ng-container>

            <ul class="no-lst m-y-sm ai-block-sidebar">
              <li *ngFor="let group of source.groups">
                <ng-container *ngIf="group.slug !== 'noGroup'">
                  <span
                    class="flex align-center space-between accordion-sidebar-item"
                    (click)="toggleExtendGroup(group.slug)"
                    (keydown)="toggleExtendGroup(group.slug)"
                    tabindex="0"
                  >
                    <span>{{ group.name }}</span>
                    <span class="icon">
                      <i
                        class="icon is-size-7"
                        [ngClass]="{
                          'icon-Top': extendedGroup === group.slug,
                          'icon-Down': extendedGroup !== group.slug
                        }"
                      ></i>
                    </span>
                  </span>
                </ng-container>

                <ul
                  class="no-lst m-y-sm ai-block-sidebar"
                  *ngIf="
                    extendedGroup === group.slug || group.slug === 'noGroup'
                  "
                >
                  <li *ngFor="let visualization of group.visualizations">
                    <a
                      (click)="goToVisualization(visualization.slug)"
                      (keydown)="goToVisualization(visualization.slug)"
                      tabindex="0"
                      class="no-deco has-text-black-ter"
                      [ngClass]="{
                        'has-text-calm':
                          selectedVisualizationSlug === visualization.slug
                      }"
                    >
                      {{ visualization.shortTitle || visualization.title }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <!--Different display if sources are extendable -->
      <div
        class="column is-3 is-hidden-touch"
        *ngIf="isSourcesExtendable === true"
      >
        <ul class="no-lst m-y-sm ai-block-sidebar">
          <li *ngFor="let source of sources">
            <ng-container *ngIf="source.slug !== 'noSource'">
              <span
                class="flex align-center space-between accordion-sidebar-item"
                (click)="toggleExtendSource(source.slug)"
                (keydown)="toggleExtendSource(source.slug)"
                tabindex="0"
              >
                <h5
                  class="title is-6 is-uppercase sans is-bold has-text-dark m-b-0 m-t-xs"
                >
                  {{ source.name }}
                </h5>
                <span class="icon">
                  <i
                    class="icon is-size-7"
                    [ngClass]="{
                      'icon-Top': extendedSource === source.slug,
                      'icon-Down': extendedSource !== source.slug
                    }"
                  ></i>
                </span>
              </span>
            </ng-container>

            <ul
              class="no-lst m-y-sm ai-block-sidebar"
              *ngIf="extendedSource === source.slug"
            >
              <li *ngFor="let group of source.groups">
                <ng-container *ngIf="group.slug !== 'noGroup'">
                  <span
                    class="flex align-center space-between accordion-sidebar-item"
                    (click)="toggleExtendGroup(group.slug)"
                    (keydown)="toggleExtendGroup(group.slug)"
                    tabindex="0"
                  >
                    <span>{{ group.name }}</span>
                    <span class="icon">
                      <i
                        class="icon is-size-7"
                        [ngClass]="{
                          'icon-Top': extendedGroup === group.slug,
                          'icon-Down': extendedGroup !== group.slug
                        }"
                      ></i>
                    </span>
                  </span>
                  <ul class="no-lst m-y-sm ai-block-sidebar">
                    <li *ngFor="let visualization of group.visualizations">
                      <a
                        *ngIf="extendedGroup === group.slug"
                        (click)="goToVisualization(visualization.slug)"
                        (keydown)="goToVisualization(visualization.slug)"
                        tabindex="0"
                        class="no-deco has-text-black-ter"
                        [ngClass]="{
                          'has-text-calm':
                            selectedVisualizationSlug === visualization.slug
                        }"
                      >
                        {{ visualization.shortTitle || visualization.title }}
                      </a>
                    </li>
                  </ul>
                </ng-container>

                <ul
                  class="no-lst m-y-sm ai-block-sidebar"
                  *ngIf="group.slug === 'noGroup'"
                >
                  <li *ngFor="let visualization of group.visualizations">
                    <a
                      *ngIf="extendedSource === source.slug"
                      (click)="goToVisualization(visualization.slug)"
                      (keydown)="goToVisualization(visualization.slug)"
                      tabindex="0"
                      class="no-deco has-text-black-ter"
                      [ngClass]="{
                        'has-text-calm':
                          selectedVisualizationSlug === visualization.slug
                      }"
                    >
                      {{ visualization.shortTitle || visualization.title }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </ng-container>

    <!-- Touch navigation menu -->
    <div class="columns is-hidden-desktop">
      <div class="column">
        <div class="control">
          <div class="select is-dark">
            <select
              class="p-b-0"
              (change)="goToVisualization($event.target.value)"
            >
              <ng-container *ngFor="let source of sources">
                <option [value]="" disabled *ngIf="source.slug !== 'noSource'">
                  {{ source.name }}
                </option>

                <ng-container *ngFor="let group of source.groups">
                  <option [value]="" disabled *ngIf="group.slug !== 'noGroup'">
                    <ng-container *ngIf="source.slug !== 'noSource'"
                      >&nbsp;&nbsp;</ng-container
                    >{{ group.name }}
                  </option>

                  <option
                    [value]="visualization.slug"
                    *ngFor="let visualization of group.visualizations"
                  >
                    <ng-container *ngIf="source.slug !== 'noSource'"
                      >&nbsp;&nbsp;</ng-container
                    >
                    <ng-container *ngIf="group.slug !== 'noGroup'"
                      >&nbsp;&nbsp;</ng-container
                    >{{ visualization.shortTitle || visualization.title }}
                  </option>
                </ng-container>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="column" *ngIf="selectedVisualization">
      <div class="columns" *ngIf="!selectedVisualization?.chartId">
        <div class="column">
          <h4 class="title is-4 m-r-md m-y-0">
            {{ selectedVisualization.title }}
          </h4>
        </div>
      </div>
      <div class="columns m-b-lg">
        <div class="column">
          <iframe
            style="width: 100%"
            [src]="selectedVisualization.iframeUrl"
            frameborder="0"
            class="data-viz-iframe"
            *ngIf="selectedVisualization.iframeUrl"
          ></iframe>
          <app-visualization-toolkit-chart
            *ngIf="selectedVisualization.chartId"
            [chartId]="selectedVisualization.chartId"
            [selectedFiltersHash]="selectedVisualizationFiltersHash"
          >
          </app-visualization-toolkit-chart>
        </div>
      </div>
    </div>
  </div>
</div>

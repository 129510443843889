<section>
  <div class="container-full bg-theme">
    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <app-breadcrumbs
            [darkTheme]="true"
            [breadcrumbLinks]="breadcrumbLinks"
          ></app-breadcrumbs>
          <h1 i18n="@@faqTitle" class="title is-1 has-text-light">
            OECD.AI FAQ
          </h1>
          <p i18n="@@faqSubtitle" class="has-text-light m-y-gutter">
            This section provides answers to common questions about OECD.AI.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container m-t-rel" *ngIf="faqs">
    <!-- Accordion -->
    <div class="columns">
      <div class="column is-12">
        <div class="accordion m-b-xxl">
          <div class="accordion__item" *ngFor="let faq of faqs">
            <div class="accordion__section">
              <div
                class="accordion__head"
                (click)="selectLiveDataAccordion(faq.label)"
                (keydown)="selectLiveDataAccordion(faq.label)"
                tabindex="0"
              >
                <h2 class="title is-3 m-y-0">
                  <span class="has-text-black">
                    {{ faq.label }}
                  </span>
                </h2>
                <span class="icon">
                  <i
                    class="icon is-size-3"
                    [ngClass]="{
                      'icon-Top': faqActiveAccordion === faq.label,
                      'icon-Down': faqActiveAccordion !== faq.label
                    }"
                  ></i>
                </span>
              </div>
              <div
                class="accordion__content"
                *ngIf="faqActiveAccordion === faq.label"
              >
                <div [innerHTML]="faq.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <div class="card p-a-xxl m-b-xxl">
          <div class="flex justify-center align-center dir-col-touch">
            <p i18n="@@faqQuestion" class="m-y-xl m-x-xl">
              If you have another question, please contact us
            </p>
            <a i18n="@@faqButton" routerLink="/contact" class="m-x-xl button">
              Contact
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

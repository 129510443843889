import { Component, OnInit } from '@angular/core'

import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { metaDataContent } from '../../../locale/meta-data/meta-data.content.en'
import { BreadcrumbLink } from '../../shared/interfaces/breadcrumb-link.interface'
import { BreadcrumbService } from '../../shared/services/breadcrumb.service'
import { MetaService } from '../../shared/services/meta.service'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  breadcrumbLinks: BreadcrumbLink[]
  searchHTML: SafeHtml

  constructor(
    breadcrumbService: BreadcrumbService,
    metaService: MetaService,
    private sanitizer: DomSanitizer
  ) {
    this.breadcrumbLinks = breadcrumbService.getBreadCrumbLinks('search')
    metaService.setTags(metaDataContent.search)
  }

  ngOnInit() {
    this.searchHTML = this.sanitizer.bypassSecurityTrustHtml(
      '<gcse:search></gcse:search>'
    )

    const cx = '014590198432315495341:fw0os7p8avk'
    const gcse = document.createElement('script')
    gcse.type = 'text/javascript'
    gcse.async = true
    gcse.src = 'https://cse.google.com/cse.js?cx=' + cx
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(gcse, s)
  }
}

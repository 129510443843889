<!-- Regular layout -->
<div
  class="card card--doc is-full-height"
  *ngIf="layout === DocumentLayout.Regular"
>
  <div class="card--doc__wrapper">
    <div class="card--doc__content">
      <p class="m-b-xs m-t-0">
        <span
          class="has-text-link small-meta"
          *ngIf="document.stakeholderTypes && document.stakeholderTypes.length"
          >{{ document.stakeholderTypes[0].name }}</span
        >
      </p>

      <a [routerLink]="['/wonk', 'documents', document.slug]" class="ghost">
        <h4 class="title is-4 p-r-gutter">
          {{ document.name }}
        </h4>
      </a>
      <p class="small-meta">
        <span class="m-r-xs">{{ document.description }}</span>
        <ng-container *ngIf="document.description">— </ng-container>
        <span class="m-x-xs">{{ document.date | date: 'LLLL d, yyyy' }}</span>
      </p>
    </div>
    <div class="card--doc__icon">
      <a [routerLink]="['/wonk', 'documents', document.slug]" class="ghost">
        <i
          class="icon is-size-2"
          [ngClass]="{
            'icon-circle-excel': mime === FileMime.Excel,
            'icon-circle-acrobat': mime === FileMime.Pdf,
            'icon-circle-powerpoint': mime === FileMime.Ppt,
            'icon-Download has-text-oecd-blue': mime === FileMime.Other
          }"
        ></i>
      </a>
    </div>
  </div>
</div>

<!-- List layout (with direct download) -->
<div class="doc-list__wrapper" *ngIf="layout === DocumentLayout.List">
  <div class="doc-list__icon m-r-gutter">
    <a
      [href]="document.path"
      target="_blank"
      download
      class="ghost"
      title="download {{ document.name }}"
    >
      <i
        class="icon is-size-2"
        [ngClass]="{
          'icon-circle-excel': mime === FileMime.Excel,
          'icon-circle-acrobat': mime === FileMime.Pdf,
          'icon-circle-powerpoint': mime === FileMime.Ppt,
          'icon-Download has-text-oecd-blue': mime === FileMime.Other
        }"
      ></i>
    </a>
  </div>
  <p class="doc-list__title">
    <a
      [href]="document.path"
      target="_blank"
      download
      class="ghost"
      title="download {{ document.name }}"
    >
      {{ document.name }}
    </a>
  </p>
</div>

import { isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID, Renderer2 } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class DomContentService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  enableAccordions(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }
    // Accordion system.
    const accordionItems: HTMLCollectionOf<Element> =
      document.getElementsByClassName('accordion__item')

    for (let i = 0; i < accordionItems.length; i++) {
      accordionItems[i].addEventListener('click', () => {
        const chevronIcon: HTMLElement =
          accordionItems[i].getElementsByTagName('i')[0]

        const accordionContent: Element =
          accordionItems[i].getElementsByClassName('accordion__content')[0]

        if (chevronIcon.classList.contains('icon-Down')) {
          // Open accordion.
          chevronIcon.className = chevronIcon.className.replace(
            'icon-Down',
            'icon-Top'
          )
          accordionContent.setAttribute('style', 'display:block')
        } else {
          // Close accordion.
          chevronIcon.className = chevronIcon.className.replace(
            'icon-Top',
            'icon-Down'
          )
          accordionContent.setAttribute('style', 'display:none')
        }
      })
    }
  }

  enablePlayVideoOverlays(renderer: Renderer2): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }
    const elements: HTMLCollectionOf<Element> = document.getElementsByClassName(
      'has-video-thumbnail'
    )

    for (let i = 0; i < elements.length; i++) {
      const playIconWrapper = renderer.createElement('div')
      renderer.addClass(playIconWrapper, 'img-fg')
      const playIcon = renderer.createElement('i')
      playIcon.classList.add(
        'icon',
        'icon-Video',
        'has-text-white',
        'is-size-1'
      )

      renderer.appendChild(playIconWrapper, playIcon)
      renderer.appendChild(elements[i].firstChild, playIconWrapper)
    }
  }

  enableTooltips(renderer: Renderer2): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }
    const elements: HTMLCollectionOf<Element> =
      document.getElementsByClassName('wp-tooltip')
    const tooltip: HTMLElement = document.getElementById('custom-tooltip')
    const wrapper: HTMLElement = document.getElementById(
      'custom-tooltip-html-wrapper'
    )

    const closeButton: HTMLElement = document.getElementById('close-button')

    if (closeButton) {
      closeButton.addEventListener('click', () =>
        renderer.setStyle(tooltip, 'display', 'none')
      )
    }

    /* tslint:disable */
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener(
        'mouseover',
        (event: MouseEvent | HTMLInputElement | any) => {
          renderer.setStyle(tooltip, 'display', 'block')

          wrapper.innerHTML = this.markdownLinksToHTML(
            event.target.getAttribute('data-content')
          )

          renderer.setStyle(tooltip, 'top', event.layerY + 'px')
          renderer.setStyle(tooltip, 'left', event.layerX + 'px')
        },
        false
      )
    }
    /* tslint:enable */
  }

  // Detects "custom markdown links" in text and replaces them by a HTML link tag.a
  // Format of markdown links should be like markdown links but with "$" instead of "[" and "]" (WP compatibility...).
  // Ex: $OECD$(https://oecd.org)
  private markdownLinksToHTML(text: string): string {
    const elements = text.match(/\$.*?\)/g)
    if (elements !== null && elements.length > 0) {
      for (const el of elements) {
        const anchor = el.match(/\$(.*?)\$/)[1]
        const url = el.match(/\((.*?)\)/)[1]
        text = text.replace(
          el,
          '<a href="' + url + '" target="_blank">' + anchor + '</a>'
        )
      }
    }
    return text
  }
}

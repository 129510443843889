import { Participant } from 'src/app/shared/interfaces/participant.interface'

export const otherContributors: Participant[] = [
  {
    name: 'Mr. Kelly Ross',
    title: 'Deputy Policy Director ',
    organization: 'American Federation of Labor and Congress ',
    group: 'TUAC'
  },
  {
    name: 'Mr. Tomáš Jucha',
    title:
      'director of Department of Innovative Technologies and International Cooperation',
    organization: `Deputy Prime Minister’s Office for Investments and Informatization of the Slovak Republic`,
    group: 'Slovak Republic'
  },
  {
    name: 'Mr. Timotej Šooš',
    title: 'Key Horizontal Projects Coordinator',
    organization: `Ministry of Foreign Affairs of Slovenia`,
    group: 'Slovenia'
  },
  {
    name: 'Mr. Daniel Egloff',
    title: 'Head of Innovation Unit',
    organization: `State Secretariat for Education, Research and Innovation`,
    group: 'Switzerland'
  },
  {
    name: 'Ms. Eva Thelisson',
    title: 'Co-Founder & CEO',
    organization: `AI Transparency Institute`,
    group: 'Consultative expert'
  },
  {
    name: 'Ms. Karen McCabe',
    title: 'Senior Director, Technology Policy and International Affairs',
    organization: `IEEE`,
    group: 'ITAC'
  },
  {
    name: 'Mr. Kentaro Kotsuki',
    title:
      'Director of the Policy Research Department Institute for Information and Communications Policy (IICP) ',
    organization: `Ministry of Internal Affairs and Communications`,
    group: 'Japan'
  },
  {
    name: 'Mr. Doug Franz',
    title: '',
    organization: ``,
    group: 'Consultative expert'
  },
  {
    name: 'Mr. Nils Börnsen',
    title: 'Policy adviser responsible for AI policy at BMWI',
    organization: `Federal Ministry for Economic Affairs and Energy`,
    group: 'Germany'
  }
]

import { NgClass, NgIf } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { RouterLink } from '@angular/router'
import { FeaturedCardLayout } from 'src/app/shared/enums/featured-card-layout.enum'
import { FeaturedCard } from 'src/app/shared/interfaces/resources/featured-card.interface'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-featured-card',
  templateUrl: './featured-card.component.html',
  styleUrls: ['./featured-card.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, RouterLink]
})
export class FeaturedCardComponent implements OnInit {
  @Input() featuredCard: FeaturedCard
  @Input() layout: FeaturedCardLayout
  @Input() showDescription = true

  environment = environment
  FeaturedCardLayout = FeaturedCardLayout
  isExternalLink: boolean

  ngOnInit(): void {
    this.isExternalLink = !this.featuredCard?.link?.includes(
      environment.baseUrl
    )
  }
}

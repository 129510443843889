<section>
  <div class="container-full bg-theme">
    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <app-breadcrumbs
            [darkTheme]="true"
            [breadcrumbLinks]="breadcrumbLinks"
          ></app-breadcrumbs>
          <h1
            i18n="@@classificationSurveyTitle"
            class="title is-1 has-text-light"
          >
            Survey for the classification of AI Systems
          </h1>
          <p
            i18n="@@classificationSurveySubtitle"
            class="has-text-light m-y-gutter"
          >
            Please share your experience with us through this short survey on
            the classification of AI systems.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container m-t-rel">
    <div class="columns">
      <div class="column is-11-tablet is-8-desktop m-b-0 p-b-sm">
        <div>
          <p i18n="@@classificationSurveyText">
            The purpose of this short survey is to capture examples of AI
            systems and how they could be classified under different dimensions.
            The information you provide will help build a simplified,
            user-friendly classification framework to inform policymakers and
            other stakeholders as they seek to better understand AI systems,
            their contexts and impacts.
          </p>
          <p i18n="@@classificationSurveyThanks" class="m-b-0">
            Thank you for participating. Success for this project hinges on your
            engagement!
          </p>
        </div>
      </div>
    </div>
    <div class="columns m-t-0 p-b-xl m-b-0">
      <div class="column is-11-tablet is-8-desktop m-b-0">
        <div class="card">
          <a
            href="https://survey2018.oecd.org/Survey.aspx?s=1d026816f51f486f8538842e707d18ef"
            target="_blank"
            class="button button-link"
            i18n="@@classificationSurveySubmit"
            >Submit a use case</a
          >
        </div>
      </div>
    </div>
  </div>
</section>

export const flashMessages = {
  home: {
    error: {
      cannotFetchNews: 'There has been an error fetching news.'
    }
  },
  downloadLink: {
    error: {
      cannotFetchData: `Error : Couldn't fetch the data from the server`
    }
  },
  contact: {
    error: {
      cannotSend:
        'There has been an error sending your message. Please try to join us through another way.'
    },
    success: {
      successSend:
        'Your message has been successfully sent to our team, thank you for your interest.'
    }
  },
  newsletter: {
    success: {
      successfullyAdd:
        'Your email has been successfully added to our mailing list.'
    },
    error: {
      cannotAdd: 'This email is already registered for email alerts.'
    }
  },
  tool: {
    error: {
      cannotSend:
        'There has been an error submitting you tool. Please fill all the mandatory fields'
    },
    success: {
      successSend:
        'Thank you ! The tool has been successfully submitted. It is now awaiting validation by the OECD Secretariat.'
    }
  }
}

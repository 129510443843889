import { NgClass, NgFor, NgIf } from '@angular/common'
import { Component, Input } from '@angular/core'
import { RouterLink } from '@angular/router'

import { AuthorLayout } from '../../../enums/author-layout.enum'
import { Author } from '../../../interfaces/author.interface'
import { ImageSet } from '../../../interfaces/image-set.interface'

@Component({
  selector: 'app-author-card',
  templateUrl: './author-card.component.html',
  styleUrls: ['./author-card.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, NgFor, RouterLink]
})
export class AuthorCardComponent {
  @Input() author: Author
  @Input() authorImage: ImageSet
  @Input() coAuthors: Author[]
  @Input() layout: AuthorLayout = AuthorLayout.Regular

  showBio = false

  AuthorLayout = AuthorLayout
}

import { Injectable } from '@angular/core'

import { ResourceSlug } from '../../enums/wordpress/wp-resource-slug.enum'
import { Page } from '../../interfaces/page.interface'
import { Paginator } from '../../interfaces/paginator.interface'
import { WPPage } from '../../interfaces/wordpress/wp-page.interface'
import { BlogResourceService } from '../blog-resource.service'
import { FormatResourceService } from '../format-resource.service'

@Injectable({
  providedIn: 'root'
})
export class PageService {
  constructor(
    private blogResourceService: BlogResourceService,
    private formatResourceService: FormatResourceService
  ) {}

  // The way to get page by slug instead of Id (for SEO purposes) is to filter list by slug.
  async show(slug: string): Promise<Page> {
    return (
      this.blogResourceService.list(ResourceSlug.Pages, { slug }) as Promise<
        Paginator<WPPage>
      >
    ).then(
      (wpPageRes: Paginator<WPPage>) => {
        if (!wpPageRes.data || !wpPageRes.data.length) {
          return Promise.reject('resource not found')
        }
        return this.formatResourceService.formatPage(wpPageRes.data[0])
      },
      (err) => Promise.reject(err)
    )
  }
}

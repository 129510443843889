<div class="mt-0 is-overflow-hidden">
  <nav class="level">
    <!-- Left side -->
    <div class="level-left">
      <div class="level-item">
        <h2 class="title is-3">
          <ng-container *ngIf="title">{{ title }}</ng-container>
          <ng-container *ngIf="!title" i18n="@@homeLatestPostsTitle"
            >Latest posts on the OECD AI Wonk</ng-container
          >
        </h2>
      </div>
      <p class="level-item">
        <a
          routerLink="/wonk"
          class="no-deco flex align-start m-l-md"
          i18n="@@carouselVisitAIWonk"
        >
          <i class="icon icon-Listright is-size-3 m-t-xs"></i>
          Visit the AI Wonk</a
        >
      </p>
    </div>

    <!-- Right side -->
    <div class="level-right">
      <!-- Navigation -->
      <div class="carousel-nav arrows" *ngIf="posts && posts.length > 1">
        <p class="buttons mt-0 justify-center-mobile">
          <a
            class="button is-circle"
            (click)="selectedItemIndex = selectedItemIndex - 1"
            (keydown)="selectedItemIndex = selectedItemIndex - 1"
            tabindex="0"
            [ngClass]="{
              'is-outlined is-disabled is-light': selectedItemIndex === 0
            }"
          >
            <span class="icon is-large">
              <i class="icon icon-Left is-size-5 pr-1"></i>
            </span>
          </a>
          <a
            class="button is-circle ml-1"
            (click)="selectedItemIndex = selectedItemIndex + 1"
            (keydown)="selectedItemIndex = selectedItemIndex + 1"
            tabindex="0"
            [ngClass]="{
              'is-outlined is-disabled is-light':
                posts.length <= carouselItemsPerLine ||
                selectedItemIndex >= posts.length - carouselItemsPerLine
            }"
          >
            <span class="icon is-large">
              <i class="icon icon-Right is-size-5 pl-1"></i>
            </span>
          </a>
        </p>
      </div>
    </div>
  </nav>
  <div
    class="featured-link-carousel featured-link-carousel--news columns index-{{
      selectedItemIndex
    }} has-{{ carouselItemsPerLine }}-items-per-line"
    style="width: auto"
  >
    <div
      class="column is-12-mobile is-6-tablet"
      *ngFor="let post of posts; let i = index"
      [ngClass]="{
        'is-active': selectedItemIndex === i,
        'is-3-desktop': carouselItemsPerLine === 4,
        'is-4-desktop': carouselItemsPerLine === 3,
        'is-highlighted': post.isHighlighted
      }"
    >
      <app-post-card [post]="post"></app-post-card>
    </div>
  </div>
</div>

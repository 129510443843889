import { Component, Input } from '@angular/core'
import { PriorityIssue } from '../../interfaces/priority-issue.interface'

@Component({
  selector: 'app-priority-issue-card',
  templateUrl: './priority-issue-card.component.html',
  styleUrls: ['./priority-issue-card.component.scss']
})
export class PriorityIssueCardComponent {
  @Input({ required: true }) priorityIssue: PriorityIssue
  @Input({ required: true }) isMobile: boolean
}

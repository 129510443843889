<section>
  <div class="container-full bg-theme">
    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <app-breadcrumbs
            [darkTheme]="true"
            [breadcrumbLinks]="breadcrumbLinks"
          ></app-breadcrumbs>
          <h1 i18n="@@resourcesTitle" class="title is-1 has-text-light">
            Resources on AI
          </h1>
          <p i18n="@@resourcesBaseline" class="has-text-light m-y-gutter">
            OECD.AI seeks to feature useful resources on AI. We are planning to
            add materials to help policy makers understand what is AI and its
            policy implications.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container m-t-rel m-b-xl">
    <div class="columns">
      <div class="column">
        <h2 i18n="@@resourcesTitleList" class="m-b-0 title is-2">
          List of OECD resources
        </h2>
      </div>
    </div>
    <div class="columns">
      <div class="column is-6 m-b-0">
        <div class="columns" *ngFor="let resource of OECDResources">
          <div class="column">
            <div class="faq-item">
              <p class="m-b-0 m-t-gutter">
                <a class="ghost" href="{{ resource.link }}" target="_blank"
                  ><strong>{{ resource.title }}</strong></a
                ><br />
                {{ resource.description }}
              </p>
              <span
                ><i
                  class="icon icon-Externallink m-r-sm has-text-link is-size-7"
                ></i>
                <a
                  i18n="@@resourcesVisitWebsite"
                  href="{{ resource.link }}"
                  target="_blank"
                  >Visit website</a
                >
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-6">
        <script
          type="text/javascript"
          src="https://app.cadmoremedia.com/Scripts/Embed.js"
        ></script>
        <iframe
          src="https://app.cadmoremedia.com/VideoPlayer/Video?id=59d8bb39-156d-4f04-a43f-2c7b425ec35a"
          scrolling="no"
          allowtransparency="true"
          frameborder="0"
          allow="encrypted-media"
          class="cmpl_iframe"
          allowfullscreen
          data-toggletranscript="false"
          data-tab="#fefcff"
          data-tabtransparency="1"
          data-text="#eeeeee"
          data-highlightcolor="#107d8b"
          style="
            overflow: hidden;
            border: 0;
            height: 100%;
            margin: 0;
            width: 100%;
          "
        ></iframe>
      </div>
    </div>
  </div>
  <app-terms></app-terms>
  <div class="container m-t-xl">
    <div class="columns">
      <div class="column">
        <h2 i18n="@@resourcesTitleOther" class="m-b-0 m-t-xl title is-2">
          Other AI observatories and repositories
        </h2>
        <p i18n="@@resourcesParagraphOther" class="m-b-0 m-t-gutter">
          This section provides examples of “observatories” developed through
          policy initiatives. Similar to the OECD.AI, they plan to monitor the
          development of AI technology, support the AI distribution and enhance
          public discussions at different level.
        </p>
      </div>
    </div>
    <div class="columns" *ngFor="let resource of otherAIResources">
      <div class="column is-12 m-b-0">
        <div class="faq-item">
          <p class="m-b-0 m-t-gutter">
            <a class="ghost" href="{{ resource.link }}" target="_blank"
              ><strong>{{ resource.title }}</strong></a
            ><br />
            {{ resource.description }}
          </p>

          <span
            ><i
              class="icon icon-Externallink m-r-sm has-text-link is-size-7"
            ></i>
            <a
              i18n="@@resourcesVisitWebsite2"
              href="{{ resource.link }}"
              target="_blank"
              >Visit website</a
            >
          </span>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <h2 i18n="@@resourcesTitleNonOECDRes" class="m-b-0 m-t-xl title is-2">
          List of Non OECD resources
        </h2>
      </div>
    </div>
    <div class="columns" *ngFor="let resource of nonOECDResources">
      <div class="column is-12 m-b-0">
        <div class="faq-item">
          <p class="m-b-0 m-t-gutter">
            <a class="ghost" href="{{ resource.link }}" target="_blank"
              ><strong>{{ resource.title }}</strong></a
            ><br />
            {{ resource.description }}
          </p>
          <span
            ><i
              class="icon icon-Externallink m-r-sm has-text-link is-size-7"
            ></i>
            <a
              i18n="@@resourcesWebsiteNonOECDRes"
              href="{{ resource.link }}"
              target="_blank"
              >Visit website</a
            >
          </span>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <p i18n="@@resourcesContactUs" class="m-t-gutter">
          Please
          <a
            href="mailto:ai@oecd.org?subject=additional resource suggestion&body=Hello,%0D%0AI would like to suggest the inclusion of additional educational materials or other
            relevant resources."
            >contact us</a
          >
          to suggest the inclusion of additional educational materials or other
          relevant resources.
        </p>
      </div>
    </div>
  </div>
</section>

import { Component, OnInit } from '@angular/core'
import { BreadcrumbLink } from 'src/app/shared/interfaces/breadcrumb-link.interface'
import { Page } from 'src/app/shared/interfaces/page.interface'
import { StripHtmlPipe } from 'src/app/shared/pipes/strip-html.pipe'
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service'
import { MetaService } from 'src/app/shared/services/meta.service'
import { PageService } from 'src/app/shared/services/page-service/page.service'

@Component({
  selector: 'app-ai-index',
  templateUrl: './ai-index.component.html',
  styleUrls: ['./ai-index.component.scss']
})
export class AiIndexComponent implements OnInit {
  page: Page
  loadingPage: boolean

  breadcrumbLinks: BreadcrumbLink[]

  constructor(
    private pageService: PageService,
    private metaService: MetaService,
    private stripHtmlPipe: StripHtmlPipe,
    breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumbLinks = breadcrumbService.getBreadCrumbLinks('aiIndex')
  }

  async ngOnInit(): Promise<void> {
    this.loadingPage = true
    this.page = await this.pageService.show('ai-index')
    this.loadingPage = false

    this.metaService.setTags({
      title: this.page.metaTitle || this.page.title,
      description: this.stripHtmlPipe.transform(
        this.page.metaDescription || this.page.excerpt
      ),
      path: this.page.slug,
      absoluteOgImage: this.page.image
        ? this.page.image.socialCard
        : this.metaService.defaultMeta.absoluteOgImage
    })
  }
}

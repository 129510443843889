<section>
  <div class="container-full bg-theme">
    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <app-breadcrumbs
            [darkTheme]="true"
            [breadcrumbLinks]="breadcrumbLinks"
          ></app-breadcrumbs>
          <h1 i18n="@@partnersTitle" class="title is-1 has-text-light">
            Partners
          </h1>
          <p i18n="@@partnersBaseline" class="has-text-light m-y-gutter">
            To inform policy, the OECD welcomes cooperative relationships with
            other actors with complementary technical, business or policy
            expertise. It welcomes data and insights on AI developments with an
            eye on international reach.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container-full">
    <div class="subnav bg-swamp">
      <div class="container">
        <div class="columns">
          <div class="column p-y-0">
            <app-about-navigation-bar />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="partnersContent">
    <div class="columns m-t-giant p-t-xl p-b-colgap">
      <div class="column is-5">
        <div class="flex flex-column align-start justify-start is-full-height">
          <h2 class="title is-2 m-b-gutter m-t-0">
            {{ partnersContent.subtitle }}
          </h2>

          <div [innerHtml]="partnersContent.richText1"></div>
        </div>
      </div>
      <div class="column is-5 is-offset-2">
        <div class="flex flex-column align-start justify-center is-full-height">
          <figure class="contain has-dots m-b-xxl">
            <img
              src="{{ partnersContent.image1 }}"
              alt="Two person showing recommendation"
            />
          </figure>
          <figure class="contain has-dots m-b-xxl">
            <img
              src="{{ partnersContent.image2 }}"
              alt="OECD Partnerships & implementation"
            />
          </figure>
          <figure class="contain has-dots m-b-xxl">
            <img
              src="{{ partnersContent.image3 }}"
              alt="OECD Partnerships & implementation : handshake"
            />
          </figure>
          <figure class="contain has-dots m-b-xxl">
            <img src="{{ partnersContent.image4 }}" alt="OECD AI Meeting" />
          </figure>
          <figure class="contain has-dots">
            <img src="{{ partnersContent.image5 }}" alt="OECD AI Meeting" />
          </figure>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <hr />
      </div>
    </div>

    <div class="columns">
      <div class="column is-6 is-offset-3 m-b-xxl">
        <div class="flex align-center justify-center">
          <p i18n="@@partnersContactUs" class="p-x-colgap content is-small">
            Contact us if you are interested in becoming a partner of OECD.AI.
          </p>
          <a
            i18n="@@partnersParagraphContactButton"
            routerLink="/contact"
            class="button m-x-colgap"
          >Contact</a
          >
        </div>
      </div>
    </div>
  </div>
</section>

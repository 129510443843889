import { Component, DestroyRef, inject, OnInit, signal, WritableSignal } from '@angular/core'

import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { ActivatedRoute, Data } from '@angular/router'
import { StripHtmlPipe } from 'src/app/shared/pipes/strip-html.pipe'
import { QuickLinkService } from 'src/app/shared/services/quick-link.service'
import { BreadcrumbLink } from '../../shared/interfaces/breadcrumb-link.interface'
import { Page } from '../../shared/interfaces/page.interface'
import { BreadcrumbService } from '../../shared/services/breadcrumb.service'
import { MetaService } from '../../shared/services/meta.service'
import { PageService } from '../../shared/services/page-service/page.service'
import { finalize } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { AboutPageState, createAboutPath, slugMapToLabel } from '../../shared/interfaces/about-page.model'

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  #destroyRef: DestroyRef = inject(DestroyRef)
  selectedNavItem: WritableSignal<AboutPageState | undefined> = signal<AboutPageState | undefined>(undefined)
  breadcrumbLinks: BreadcrumbLink[]
  aboutPage: Page
  pageContent: SafeHtml
  hasQuickLinks: boolean
  isLoading = true

  constructor(
    private pageService: PageService,
    private breadcrumbService: BreadcrumbService,
    private metaService: MetaService,
    private sanitizer: DomSanitizer,
    private stripHtmlPipe: StripHtmlPipe,
    private activatedRoute: ActivatedRoute,
    private quickLinkService: QuickLinkService
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(
        finalize(() => {
          this.isLoading = true
          this.hasQuickLinks = false
        }),
        takeUntilDestroyed(this.#destroyRef))
      .subscribe((data: Data) => {
        this.selectedNavItem.set(data.slug.length ? data.slug : 'about-' as AboutPageState)
        this.showPageResult(this.selectedNavItem() !== 'about-' ? `about-${data.slug}` : 'about')

        this.initBreadCrumbLinks()
      })
  }

  private showPageResult(pageToShow: string): void {

    this.pageService.show(pageToShow).then((page) => {
      this.aboutPage = page

      this.pageContent = this.sanitizer.bypassSecurityTrustHtml(
        this.aboutPage.content
      )
      this.isLoading = false

      this.quickLinkService
        .getQuickLinksGroupsByParentGroupSlug('about-' + this.selectedNavItem())
        .then((quickLinks) => {
          this.hasQuickLinks = !!quickLinks.length
        })

      this.metaService.setTags({
        title: this.aboutPage.metaTitle || this.aboutPage.title,
        description: this.stripHtmlPipe.transform(
          this.aboutPage.metaDescription || this.aboutPage.excerpt
        ),
        path: 'about/' + this.selectedNavItem(),
        absoluteOgImage: this.aboutPage.image
          ? this.aboutPage.image.socialCard
          : this.metaService.defaultMeta.absoluteOgImage
      })
    })
  }

  private initBreadCrumbLinks(): void {
    this.breadcrumbLinks = this.breadcrumbService.getBreadCrumbLinks(
      'about',
      [
        {
          label: slugMapToLabel(this.selectedNavItem()),
          path: createAboutPath(this.selectedNavItem())
        }
      ]
    )
  }
}

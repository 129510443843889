import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Params } from '@angular/router'
import { firstValueFrom } from 'rxjs'
import { map } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { ResourceSlug } from '../enums/wordpress/wp-resource-slug.enum'
import { Paginator } from '../interfaces/paginator.interface'

@Injectable({
  providedIn: 'root'
})
export class BlogResourceService {
  constructor(private http: HttpClient) {}

  list(
    resourceName: string,
    refineParams?: Params
  ): Promise<Paginator<any> | any[]> {
    let httpParams = new HttpParams()

    // Require related resources.
    if (
      resourceName === ResourceSlug.Pages ||
      resourceName === ResourceSlug.Posts ||
      resourceName === ResourceSlug.Documents ||
      resourceName === ResourceSlug.Discussions ||
      resourceName === ResourceSlug.Initiatives ||
      resourceName === ResourceSlug.Events ||
      resourceName === ResourceSlug.Users ||
      resourceName === ResourceSlug.Videos ||
      resourceName === ResourceSlug.KeyThemes ||
      resourceName === ResourceSlug.Principles ||
      resourceName === ResourceSlug.Tags ||
      resourceName === ResourceSlug.PolicyAreas ||
      resourceName === ResourceSlug.Areas ||
      resourceName === ResourceSlug.Visualization ||
      resourceName === ResourceSlug.Countries ||
      resourceName === ResourceSlug.Lifecycles ||
      resourceName === ResourceSlug.FeaturedCards ||
      resourceName === ResourceSlug.QuickLinks ||
      resourceName === ResourceSlug.QuickLinkGroups ||
      resourceName === ResourceSlug.AiPublications
    ) {
      httpParams = httpParams.append('_embed', '1')
      httpParams = httpParams.append('acf_format', 'standard')
    }

    if (environment.lang === 'fr' && !refineParams.lang) {
      httpParams = httpParams.append('lang', 'fr')
    }

    if (refineParams) {
      Object.keys(refineParams)
        .filter((paramName) => paramName !== 'withoutPagination')
        .forEach((key: string) => {
          if (Array.isArray(refineParams[key])) {
            refineParams[key].forEach((value: string) => {
              httpParams = httpParams.append(`${key}[]`, value)
            })
          } else {
            httpParams = httpParams.set(key, refineParams[key])
          }
        })
    }

    if (environment.lang === 'fr' && resourceName === ResourceSlug.Pages) {
      // Pages in FR have the "-fr" suffix in slug.
      const slug: string = httpParams.get('slug')
      httpParams = httpParams.set('slug', `${slug}-fr`)
    }

    if (refineParams?.withoutPagination === true) {
      if (!refineParams?.per_page) {
        httpParams = httpParams.set('per_page', 100)
      }

      return firstValueFrom(
        this.http.get(`${environment.apiBlogBaseUrl}${resourceName}`, {
          params: httpParams
        })
      ) as Promise<Paginator<any> | any[]>
    }

    return this.http
      .get(`${environment.apiBlogBaseUrl}${resourceName}`, {
        params: httpParams,
        observe: 'response'
      })
      .pipe(
        map((res: HttpResponse<any>) => {
          // Build Paginator.
          const currentPage: number =
            parseInt(refineParams && refineParams.page) || 1
          const perPage: number = (refineParams && refineParams.per_page) || 10
          const offset: number = (currentPage - 1) * perPage
          const total: number = parseInt(res.headers.get('x-wp-total'), 10)

          return {
            data: res.body,
            currentPage,
            lastPage: Math.ceil(total / perPage),
            from: offset + 1,
            to: offset + perPage,
            total,
            perPage
          }
        })
      )
      .toPromise()
  }

  show(
    resourceName: string,
    id: number | string,
    suffix?: string,
    headers?: HttpHeaders
  ): Promise<any> {
    let httpParams = new HttpParams()
    // Require related resources.
    httpParams = httpParams.append('_embed', '1')

    return this.http
      .get(
        `${environment.apiBlogBaseUrl}${resourceName}/${id}` +
          (suffix ? `/${suffix}` : ''),
        {
          params: httpParams,
          headers
        }
      )
      .pipe(map((res) => res))
      .toPromise()
  }

  showBySlug(resourceName: string, slug: string): Promise<any> {
    let httpParams = new HttpParams()
    httpParams = httpParams.append('_embed', '1')
    httpParams = httpParams.append('slug', slug)

    return firstValueFrom(
      this.http.get(`${environment.apiBlogBaseUrl}${resourceName}`, {
        params: httpParams
      })
    ).then((res: any) => {
      if (!res || !res.length) {
        return Promise.reject('resource not found')
      }
      return res[0]
    })
  }

  store(resourceSlug: string, body: any, headers?: HttpHeaders): Promise<any> {
    return this.http
      .post(`${environment.apiBlogBaseUrl}${resourceSlug}`, body, {
        headers
      })
      .toPromise()
  }
}

import { Component, Input } from '@angular/core'
import { SmartLinkDirective } from '../../directives/smart-link.directive'
import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common'
import { TruncatePipe } from '../../pipes/truncate.pipe'

@Component({
  selector: 'app-initiative-list',
  templateUrl: './initiative-list.component.html',
  styleUrls: ['./initiative-list.component.scss'],
  standalone: true,
  imports: [SmartLinkDirective, TruncatePipe, DatePipe, NgClass, NgFor, NgIf]
})
export class InitiativeListComponent {
  @Input() showImages = true
  @Input() initiatives: any[]
}

<section>
  <div class="container-full bg-theme">
    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <app-breadcrumbs
            [darkTheme]="true"
            [breadcrumbLinks]="breadcrumbLinks"
          ></app-breadcrumbs>
          <h1 i18n="@@surveyTitle" class="title is-1 has-text-light">
            Practical use cases for implementation of the OECD AI Principles
          </h1>
          <p i18n="@@surveyBaseline" class="has-text-light m-y-gutter">
            Please share your experience with us through this short survey on
            practical approaches and good practices to help further
            implementation of trustworthy AI systems.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container m-t-rel">
    <div class="columns">
      <div class="column is-11-tablet is-8-desktop m-b-0 p-b-sm">
        <div>
          <p i18n="@@surveyParagraph">
            The goal of this short survey is to identify and share practical
            approaches and good practices to help further inform the
            implementation of trustworthy AI systems; that is, AI systems that
            embody principles such as human rights, fairness, transparency,
            explainability, security, safety and accountability.
          </p>
          <p i18n="@@surveyParagraph1">
            These approaches could include, for example, system development
            processes; principles and codes of conduct; software tools;
            technical research etc.
          </p>
          <p i18n="@@surveyParagraph2">
            Note: Organisations submitting the use cases should be directly
            involved in, or have substantial knowledge of, the work, due to
            either first-hand usage or research in cooperation with the
            implementing organisations. Use cases should be implemented or in
            deployment, but not necessarily completed.
          </p>
          <p class="m-b-0" i18n="@@surveyParagraphThanks">
            Thank you for participating!
          </p>
        </div>
      </div>
    </div>
    <div class="columns m-t-0 p-b-xl m-b-0">
      <div class="column is-11-tablet is-8-desktop m-b-0">
        <div class="card">
          <a
            i18n="@@surveyParagraphSubmit"
            href="https://survey2018.oecd.org/Survey.aspx?s=a0c5375c10f0445587fa9917c88b62d2"
            target="_blank"
            class="button button-link"
            >Submit a use case</a
          >
        </div>
      </div>
    </div>
  </div>
</section>

import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'

import { metaDataContent } from '../../../../locale/meta-data/meta-data.content.en'
import { BreadcrumbLink } from '../../../shared/interfaces/breadcrumb-link.interface'
import { Paginator } from '../../../shared/interfaces/paginator.interface'
import { Video } from '../../../shared/interfaces/video.interface'
import { BreadcrumbService } from '../../../shared/services/breadcrumb.service'
import { MetaService } from '../../../shared/services/meta.service'
import { VideoService } from '../../../shared/services/video.service'

@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss']
})
export class VideoListComponent implements OnInit {
  paginator: Paginator<Video>

  breadcrumbLinks: BreadcrumbLink[]
  loading: boolean

  constructor(
    private videoService: VideoService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    metaService: MetaService,
    breadcrumbService: BreadcrumbService
  ) {
    metaService.setTags(metaDataContent.videos)
    this.breadcrumbLinks = breadcrumbService.getBreadCrumbLinks('videos')
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
      this.loading = true
      ;(
        this.videoService.list({
          page: queryParams && queryParams.page
        }) as Promise<Paginator<Video>>
      ).then((videoRes: Paginator<Video>) => {
        this.loading = false
        this.paginator = videoRes
      })
    })
  }

  goToPage(pageNumber: number) {
    const queryParams: Params = { page: pageNumber.toString() }
    this.router.navigate(['/videos'], {
      queryParams,
      queryParamsHandling: 'merge'
    })
  }
}

<div class="container-full bg-theme">
  <div class="container">
    <div class="columns">
      <div class="column is-10">
        <app-breadcrumbs
          [darkTheme]="true"
          [breadcrumbLinks]="breadcrumbLinks"
        ></app-breadcrumbs>
        <h1 i18n="@@liveDataTitle" class="title is-1 has-text-light">
          Live data
        </h1>
        <p i18n="@@liveDataSubtitle" class="has-text-light m-y-gutter">
          This section leverages live data to show timely trends about where,
          how and at what rate AI is being developed and used and in which
          sectors.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container m-t-rel">
  <div class="columns">
    <div class="column">
      <a
        [ngClass]="{
          'is-outlined': selectedArea?.slug !== area.slug
        }"
        *ngFor="let area of areas"
        routerLink="/data"
        [queryParams]="{
          selectedArea: area.slug
        }"
        class="button is-calm ml-0 mr-4 mb-4"
        >{{ area.name }}</a
      >
    </div>
  </div>
  <div class="columns">
    <div class="column">
      <div class="mt-0" [innerHTML]="selectedArea?.description"></div>
    </div>
  </div>

  <app-data-visualization-panel
    *ngIf="selectedArea"
    [area]="selectedArea"
    [selectedVisualizationSlug]="selectedVisualization"
    [selectedVisualizationFiltersHash]="visualizationFiltersHash"
    [isSourcesExtendable]="true"
  >
  </app-data-visualization-panel>

  <!-- Note -->

  <div class="columns">
    <div class="column is-11-tablet is-10-desktop m-b-xl">
      <p i18n="@@liveDataNoteText" class="small-meta">
        NOTE: It is important to highlight that data sources and methodologies
        may differ from those used in OECD research, and therefore results may
        vary. As such, this section includes full and transparent methodological
        information. Because methods for measuring AI are still evolving, there
        remain definitional and other issues that can influence empirical
        results. Efforts to develop clear definitions, taxonomies and
        classifications are underway, as are efforts to compile accurate and
        comparable indicators. Given the current evolving situation, showing
        trends in a timely manner based on transparent methodologies by partner
        institutions can be of value to policy makers.
      </p>
    </div>
  </div>
</div>

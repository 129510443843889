export const appConstants = {
  FLASH_MESSAGE_TIMEOUT: 4000,

  // Same as $desktop in variables.scss.
  MOBILE_BREAKPOINT: 768,
  TOUCH_BREAKPOINT: 1188,

  // URI resource prefixes. We add it to URL params to keep pretty URLs and prevent encoding issues.
  PREFIXES: {
    // OECD AI prefixes.
    POLICY_AREA: 'http://ai.oecd.org/taxonomy/policyAreas#',
    PRINCIPLE: 'http://ai.oecd.org/taxonomy/principles#',

    // Other STIP prefixes.
    TARGET_GROUP: 'http://stip.oecd.org/taxonomy/targetGroups#',
    POLICY_INSTRUMENT: 'http://stip.oecd.org/model#',
    THEME: 'http://stip.oecd.org/taxonomy/themes#',
    COUNTRY: 'http://kim.oecd.org/Taxonomy/GeographicalAreas#',
    ORGANIZATION: 'http://kim.oecd.org/Taxonomy/Organisations#',
    GENERIC_URI: 'http://stip.oecd.org/',
    RESPONSIBLE_ORGANIZATION:
      'http://stip.oecd.org/model#responsibleOrganisation'
  },

  // Policy Area and AI Principle cards.
  CARD_HEIGHT: 113,
  PADDING_CARD_LIST: 12,

  API_ENDPOINTS: {
    POLICY_AREA_DETAIL: 'dashboards/AIPolicyArea.xqy',
    PRINCIPLE_DETAIL: 'dashboards/AIPrinciple.xqy',
    POLICY_INITIATIVE_LIST: 'dashboards/policyInitiatives.xqy',
    POLICY_INITIATIVE_DETAIL: 'dashboards/policyInitiative.xqy',

    MAIN_DASHBOARD: 'dashboards/main.xqy',
    COUNTRY_DASHBOARD: 'dashboards/country.xqy',
    COUNTRY_THEME_DASHBOARD: 'dashboards/thematicCountry.xqy',
    TARGET_GROUP_DASHBOARD: 'dashboards/targetGroup.xqy',
    POLICY_INSTRUMENT_DASHBOARD: 'dashboards/policyInstrument.xqy',

    NEWS: 'document.xqy?uri=/assets/AIPO_News.json',
    LINKS: 'document.xqy?uri=/assets/AIPO_Links.json'
  },

  AUTH: {
    TOOL_CREATOR_USER_NAME: 'tool-creator',
    TOOL_CREATOR_PASSWORD: 'tool-creator'
  },

  COUNTRIES_THAT_ARE_ORGANIZATIONS: ['EuropeanUnion', 'AfricanUnion'],

  RECAPTCHA_SITE_KEY: '6Lcn3ZwfAAAAAN2xZ-QtM4jZSGBrvNm-hgtGW9yH'
}

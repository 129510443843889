<div class="container-full bg-theme">
  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <app-breadcrumbs
          [darkTheme]="true"
          [breadcrumbLinks]="breadcrumbLinks"
        ></app-breadcrumbs>
        <div *ngIf="page === 'what-we-do'"></div>
        <ng-container></ng-container>
        <ng-container *ngIf="page !== 'one-ai-members' && page !== 'partners' && page !== 'background'; else elseBlock">
          <h1 i18n="@@aboutTitle" class="title is-1 has-text-light">
            About OECD.AI
          </h1>
          <p i18n="@@aboutSubtitle" *ngIf="page === 'what-we-do'" class="has-text-light m-y-gutter">
            This website is currently being updated further to the announcement of an integrated partnership for the
            Global Partnership on AI (GPAI) with the OECD. Stay tuned for further updates! <a
            href="https://www.oecd.org/en/about/programmes/global-partnership-on-artificial-intelligence.html"
            target="_blank" class="has-text-white">See here for more
            information</a>.
          </p>
          <div
            [ngStyle]="{'color' : 'white'}"
            *ngIf="page === 'about-'"
            [innerHTML]="dynamicDescription">
          </div>
        </ng-container>
        <ng-template #elseBlock>
          <div *ngIf="page === 'partners'">
            <h1 i18n="@@partnersTitle" class="title is-1 has-text-light">
              Partners
            </h1>
            <p i18n="@@partnersBaseline" class="has-text-light m-y-gutter">
              To inform policy, the OECD welcomes cooperative relationships with
              other actors with complementary technical, business or policy
              expertise. It welcomes data and insights on AI developments with an
              eye on international reach.
            </p>
          </div>
          <div *ngIf="page === 'about-'">
            <h1 i18n="@@aboutTitle" class="title is-1 has-text-light">
              About OECD.AI
            </h1>
            <p i18n="@@aboutSubtitle" class="has-text-light m-y-gutter">
              This website is currently being updated further to the announcement of an integrated partnership for the
              Global Partnership on AI (GPAI) with the OECD. Stay tuned for further updates! <a
              href="https://www.oecd.org/en/about/programmes/global-partnership-on-artificial-intelligence.html"
              target="_blank" class="has-text-white">See here for more
              information</a>.
            </p>
          </div>
          <div *ngIf="page === 'one-ai-members'">
            <h1 i18n="@@aboutAigoTitle" class="title is-1 has-text-light">
              OECD Working Party on Artificial Intelligence Governance (AIGO)
            </h1>
            <div class="fl m-y-gutter">
              <span i18n="@@aboutAigoDescription" class="has-text-light m-y-gutter">
                Further to the announcement of an integrated partnership for the
                Global Partnership on AI (GPAI) with the OECD, the OECD ONE AI and
                the GPAI Multistakeholder Group are forming a new expert community.
                Stay tuned for further updates!</span>
              <a
                i18n="@@seeMoreInfoText"
                href="https://www.oecd.org/en/about/programmes/global-partnership-on-artificial-intelligence.html"
                target="_blank"
                class="has-text-white"
              >See here for more information</a>
            </div>
          </div>
          <div *ngIf="page === 'about-'"></div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

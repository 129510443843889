import { Directive, HostListener, Input } from '@angular/core'

import { DashboardLink } from '../interfaces/dashboard-link.interface'
import { SmartLinkService } from '../services/smart-link.service'

@Directive({
  selector: '[appSmartLink]',
  providers: [SmartLinkService],
  standalone: true
})
export class SmartLinkDirective {
  @Input() appSmartLink: DashboardLink

  @HostListener('click')
  onClick() {
    this.smartLinkService.goToLink(this.appSmartLink)
  }

  constructor(private smartLinkService: SmartLinkService) {}
}

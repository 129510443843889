<div class="container-full bg-theme">
  <div class="container">
    <div class="columns">
      <div class="column is-10">
        <app-breadcrumbs
          [darkTheme]="true"
          [breadcrumbLinks]="breadcrumbLinks"
        ></app-breadcrumbs>
        <h1 i18n="@@metricsTitle" class="title is-1 has-text-light">
          OECD metrics & methods
        </h1>
        <p i18n="@@metricsBaseline" class="has-text-light m-y-gutter">
          The OECD has begun methodological work to quantify AI developments –
          this section presents official publications and indicators from OECD
          Committees related to AI measurement.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container m-t-rel">
  <div class="columns">
    <div class="column m-b-0 p-b-0">
      <div
        class="flex flex-wrap align-start justify-start"
        [formGroup]="tabForm"
      >
        <div class="control">
          <label class="radio radio--btn">
            <input
              type="radio"
              value="publications"
              formControlName="selectedTab"
            />
            <span class="label-content" i18n="@@metricsTab1"
              >Measurement publications</span
            >
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="control">
          <label class="radio radio--btn">
            <input
              type="radio"
              value="bibliometrics"
              formControlName="selectedTab"
            />
            <span class="label-content" i18n="@@metricsTab2"
              >Bibliometrics dataviz</span
            >
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container m-t-rel m-b-xxl">
  <div class="columns" *ngIf="tabForm.value.selectedTab === 'publications'">
    <div class="column trends-and-data-content is-12">
      <app-initiative-list [initiatives]="initiatives"></app-initiative-list>
    </div>
  </div>
  <app-data-visualization-panel
    [area]="bibliometricsArea"
    *ngIf="tabForm.value.selectedTab === 'bibliometrics' && bibliometricsArea"
  ></app-data-visualization-panel>
</div>

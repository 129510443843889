import { Component, OnInit } from '@angular/core'
import { metaDataContent } from '../../../locale/meta-data/meta-data.content.en'
import { BreadcrumbLink } from '../../shared/interfaces/breadcrumb-link.interface'
import { BreadcrumbService } from '../../shared/services/breadcrumb.service'
import { MetaService } from '../../shared/services/meta.service'

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
  breadcrumbLinks: BreadcrumbLink[]

  constructor(
    private breadcrumbService: BreadcrumbService,
    private metaService: MetaService
  ) {}

  ngOnInit(): void {
    this.breadcrumbLinks = this.breadcrumbService.getBreadCrumbLinks('survey')
    this.metaService.setTags(metaDataContent.survey)
  }
}

import { Component, OnInit } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Router } from '@angular/router'

import { metaDataContent } from '../../../locale/meta-data/meta-data.content.en'
import { BreadcrumbLink } from '../../shared/interfaces/breadcrumb-link.interface'
import { Page } from '../../shared/interfaces/page.interface'
import { StripHtmlPipe } from '../../shared/pipes/strip-html.pipe'
import { BreadcrumbService } from '../../shared/services/breadcrumb.service'
import { MetaService } from '../../shared/services/meta.service'
import { PageService } from '../../shared/services/page-service/page.service'

@Component({
  selector: 'app-networks-of-experts',
  templateUrl: './networks-of-experts.component.html',
  styleUrls: ['./networks-of-experts.component.scss']
})
export class NetworksOfExpertsComponent implements OnInit {
  documents: Document[]

  aboutContent: SafeHtml
  breadcrumbLinks: BreadcrumbLink[]
  loading: boolean

  constructor(
    private pageService: PageService,
    private breadcrumbService: BreadcrumbService,
    private metaService: MetaService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private stripHtmlPipe: StripHtmlPipe
  ) {
    metaService.setTags(metaDataContent.networkOfExperts)
    this.breadcrumbLinks =
      breadcrumbService.getBreadCrumbLinks('networkOfExperts')
  }

  ngOnInit() {
    this.loading = true

    this.pageService.show('one-ai-members').then(
      (page: Page) => {
        this.loading = false
        this.aboutContent = this.sanitizer.bypassSecurityTrustHtml(page.content)

        // Meta and breadcrumbs.
        this.metaService.setTags({
          title: page.metaTitle || page.title,
          description: this.stripHtmlPipe.transform(
            page.metaDescription || page.excerpt
          ),
          path: `network-of-experts`,
          absoluteOgImage: page.image
            ? page.image.socialCard
            : this.metaService.defaultMeta.absoluteOgImage
        })
        this.breadcrumbLinks =
          this.breadcrumbService.getBreadCrumbLinks('networkOfExperts')
      },
      () => {
        this.router.navigate(['/404'])
      }
    )
  }
}

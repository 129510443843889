<ng-container *ngIf="!locked">
  <app-header *ngIf="!isTouchResolution"></app-header>
  <app-header-touch *ngIf="isTouchResolution"></app-header-touch>
  <app-flash-message></app-flash-message>

  <main class="main">
    <router-outlet></router-outlet>
  </main>
  <app-footer></app-footer>
</ng-container>

<!-- Password protection (only for staging environments)-->
<ng-container *ngIf="locked">
  <div class="container">
    <div class="columns">
      <div class="column is-4 is-offset-4">
        <label class="is-size-7" i18n="@@contactLabelName" for="name-input"
          >Password*</label
        >
        <div class="control mb-4">
          <input
            class="input is-small"
            type="password"
            name="password"
            placeholder="Enter password to see content..."
            id="name-input"
           
            #unlockInput
          />
        </div>
        <button class="button is-primary" (click)="unlock()">Unlock</button>
      </div>
    </div>
  </div>
</ng-container>

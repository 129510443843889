import { Component, HostListener, Input, Renderer2 } from '@angular/core'
import { Video } from '../../../interfaces/video.interface'
import { DatePipe, NgIf } from '@angular/common'

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss'],
  standalone: true,
  imports: [DatePipe, NgIf]
})
export class VideoCardComponent {
  @Input() video: Video
  @Input() showExcerpt = true

  isActive: boolean

  constructor(private renderer: Renderer2) {}

  @HostListener('document:click', ['$event.target'])
  onClick(target: any) {
    // We track clicks to close model if open and click outside
    if (this.isActive && target.className === 'modal-background') {
      this.isActive = false
      // Stop YouTube video from playing by resetting its source.
      const playerEl: Element = document.querySelector('#youtube-player')
      this.renderer.setAttribute(playerEl, 'src', '')
    }
  }
}

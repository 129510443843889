import { Component, OnInit } from '@angular/core'
import { BreadcrumbLink } from '../../shared/interfaces/breadcrumb-link.interface'
import { BreadcrumbService } from '../../shared/services/breadcrumb.service'
import { MetaService } from '../../shared/services/meta.service'

import { metaDataContent } from '../../../locale/meta-data/meta-data.content.en'
import { Faq } from '../../shared/interfaces/faq.interface'
import { FaqService } from '../../shared/services/faq.service'

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  breadcrumbLinks: BreadcrumbLink[]
  faqActiveAccordion: string
  faqs: Faq[]
  slugToExclude = 'tool-faq'

  constructor(
    private faqService: FaqService,
    breadcrumbService: BreadcrumbService,
    metaService: MetaService
  ) {
    metaService.setTags(metaDataContent.FAQ)
    this.breadcrumbLinks = breadcrumbService.getBreadCrumbLinks('FAQ')
  }

  async ngOnInit(): Promise<void> {
    this.faqs = await this.faqService.list({
      faqCategorySlugNotIn: this.slugToExclude
    })
  }

  selectLiveDataAccordion(accordion: string) {
    if (accordion === this.faqActiveAccordion) {
      delete this.faqActiveAccordion
    } else {
      this.faqActiveAccordion = accordion
    }
  }
}

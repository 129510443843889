<section>
  <div class="container-full bg-theme">
    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <app-breadcrumbs
            [darkTheme]="true"
            [breadcrumbLinks]="breadcrumbLinks"
          ></app-breadcrumbs>
          <h1 class="title is-1 has-text-light">
            OECD Working Party on Artificial Intelligence Governance (AIGO)
          </h1>
          <p class="has-text-light m-y-gutter">
            Further to the announcement of an integrated partnership for the
            Global Partnership on AI (GPAI) with the OECD, the OECD ONE AI and
            the GPAI Multistakeholder Group are forming a new expert community.
            Stay tuned for further updates!
            <a
              href="https://www.oecd.org/en/about/programmes/global-partnership-on-artificial-intelligence.html"
              target="_blank"
              class="has-text-white"
            >See here for more information</a
            >.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container m-b-xxl">
    <div class="columns m-b-xl">
      <div class="column">
        <div [innerHTML]="aboutContent"></div>
      </div>
    </div>
  </div>
</section>

import { Participant } from 'src/app/shared/interfaces/participant.interface'

export const contributors: Participant[] = [
  {
    name: 'Mr. Wonki Min',
    title:
      '[AIGO Chair] Vice-Minister and Chair of the OECD Committee on Digital Economy Policy',
    organization: 'Ministry of Science and ICT, Korea',
    group: 'Korea'
  },
  {
    name: 'Mr. Tim Bradley',
    title: 'Minister-Counsellor',
    organization: 'Department of Industry, Innovation and Science.',
    group: 'Australia'
  },
  {
    name: 'Mr. Alex Cooke ',
    title: 'Counsellor, Department of Industry, Innovation and Science ',
    organization:
      'Australian Embassy to Belgium, Luxembourg and Mission to the European Union and NATO',
    group: 'Australia'
  },
  {
    name: 'Ms. Elissa Strome',
    title: 'Executive Director of the Pan-Canadian AI Strategy',
    organization: 'Canadian Institute for Advanced Research (CIFAR)',
    group: 'Canada'
  },
  {
    name: 'Mr. Lars Rugholm Nielsen',
    title: 'Head of Section',
    organization: 'Danish Business Authority',
    group: 'Denmark'
  },
  {
    name: 'Mr. Antti Eskola',
    title:
      'Commercial Counsellor for Innovation and Enterprise Financing Department',
    organization: 'Ministry of Economic Affairs and Employment',
    group: 'Finland'
  },
  {
    name: 'Ms. Christel Fiorina',
    title: 'Head of Audiovisual and Multimedia office',
    organization:
      'Directorate General for Enterprise, French ministry of economy and finance',
    group: 'France'
  },
  {
    name: 'Mr. Bertrand Pailhes',
    title: 'National Coordinator for the Frence AI Strategy',
    organization: "State Digital Service, Prime Minister's Service",
    group: 'France'
  },
  {
    name: 'Mr. Michael Schönstein',
    title:
      'Head of Strategic Futures & Analysis Policy Lab “Digital Work & Society”, Federal',
    organization: 'Ministry for Labour and Social Affairs',
    group: 'Germany'
  },
  {
    name: 'Mr. András Hlács',
    title: 'Counsellor',
    organization: 'Permanent Delegation of Hungary to OECD ',
    group: 'Hungary'
  },
  {
    name: 'Mr. Osamu Sudoh',
    title:
      'Professor, Graduate School of Interdisciplinary Information Studies',
    organization: 'University of Tokyo ',
    group: 'Japan'
  },
  {
    name: 'Mr. Susumu Hirano',
    title: 'Dean and Professor',
    organization: 'Chuo University Graduate School of Policy Studies',
    group: 'Japan'
  },
  {
    name: 'Mr. Chungwon LEE',
    title: 'Director, Multilateral cooperation division',
    organization: 'Ministry of Science and ICT, Korea',
    group: 'Korea'
  },
  {
    name: 'Mr. Seongtak Oh ',
    title: 'Executive Director, Department of Bigdata',
    organization: 'National Information Society Agency, Korea ',
    group: 'Korea'
  },
  {
    name: 'Mr. Javier Juárez Mojica',
    title: "[Co-moderator, 'What is AI' AIGO subgroup] IFT Commissioner",
    organization: 'Federal Telecommunications Institute',
    group: 'Mexico'
  },
  {
    name: 'Mr. Wim Rullens ',
    title: 'Senior Policy Coordinator',
    organization: 'Ministry of Economic Affairs and Climate',
    group: 'Netherlands'
  },
  {
    name: 'Ms. Olivia Erdelyi',
    title: 'Lecturer',
    organization: 'Canterbury University ',
    group: 'New Zealand'
  },
  {
    name: 'Mr. Robert Kroplewski',
    title: 'Representative',
    organization:
      'Minister for Digitalisation of the Information Society in Poland',
    group: 'Poland'
  },
  {
    name: 'Mr. Andrey Ignatyev',
    title: 'Deputy Head of OECD Unit',
    organization: 'Ministry of Economic Development',
    group: 'Russian Federation'
  },
  {
    name: 'Mr. Konstantin Vishnevskiy',
    title: 'Head of Department for Digital Economy Studies ISSEK HSE',
    organization:
      'Institute for Statistical Studies and Economics of Knowledge',
    group: 'Russian Federation'
  },
  {
    name: 'Ms. Xinrong Yang',
    title: 'Manager, International',
    organization:
      'Infocomm Media Development Authority (IM;DA), Government of Singapore',
    group: 'Singapore'
  },
  {
    name: 'Mr. Michal Cíž',
    title: 'AI Policy Expert, EU Digital Single Market',
    organization:
      'Deputy Prime Minister’s Office for Investments and Informatization',
    group: 'Slovak Republic'
  },
  {
    name: 'Mr. Marko Grobelnik',
    title: "[Co-moderator, 'What is AI' AIGO subgroup] Researcher in AI",
    organization: 'Jozef Stefan Institute - Artificial Intelligence Lab',
    group: 'Slovenia'
  },
  {
    name: 'Ms. Helena Hånell McKelvey',
    title: 'Head of Section, Division for Digital Development',
    organization: 'Ministry of Enterprise and Innovation ',
    group: 'Sweden'
  },
  {
    name: 'Ms. Livia Walpen',
    title: 'Advisor, International Relations ',
    organization: 'Swiss Federal Office of Communications',
    group: 'Switzerland'
  },
  {
    name: 'Ms. Ezgi Bener ',
    title: 'Expert on Scientific Programmes',
    organization:
      'The Scientific and Technological Research Council of Türkiye (TUBITAK)',
    group: 'Türkiye'
  },
  {
    name: 'Mr. Cyrus Hodes ',
    title: 'Advisor to the UAE Minister for AI',
    organization: 'UAE Ministry for AI',
    group: 'UAE'
  },
  {
    name: 'Mr. Edward Teather ',
    title: 'Senior Policy Adviser ',
    organization: 'Office for Artificial Intelligence',
    group: 'United Kingdom'
  },
  {
    name: 'Mr. Adam Murray',
    title:
      'International Affairs Officer, Office of International Communications and Information Policy',
    organization: 'U.S. Department of State ',
    group: 'United States'
  },
  {
    name: 'Ms. Fiona Alexander ',
    title: 'NTIA Associate Administrator',
    organization: 'U.S. Department of Commerce ',
    group: 'United States'
  },
  {
    name: 'Mr. Jim Kurose',
    title:
      "[Co-moderator, 'AI system lifecycle' AIGO subgroup] Assistant Director for Computer and Information Science and Engineering, Assistant Director for AI at the Office of Science and Technology Policy",
    organization: 'U.S. National Science Foundation',
    group: 'United States'
  },
  {
    name: 'Ms. Irina Orssich ',
    title: 'Political Analyst ',
    organization: 'European Commission',
    group: 'European Commission'
  },
  {
    name: 'Mr. Jean-Yves Roger ',
    title: 'Policy Officer',
    organization: 'European Commission',
    group: 'European Commission'
  },
  {
    name: 'Mr. Barry O’Brien ',
    title: 'Government and Regulatory Affairs Executive',
    organization: ' IBM (Ireland) ',
    group: 'BIAC'
  },
  {
    name: 'Ms. Carolyn Nguyen ',
    title: 'Director, Technology Policy Group',
    organization: 'Microsoft',
    group: 'BIAC'
  },
  {
    name: 'Mr. Ludovic Peran',
    title: "Public Policy & Gov't Relations ",
    organization: 'Google',
    group: 'BIAC'
  },
  {
    name: 'Mr. Noberto Andrade ',
    title: 'Privacy and Public Policy Manager',
    organization: 'Facebook',
    group: 'BIAC'
  },
  {
    name: 'Mr. Marc Rotenberg ',
    title: 'Executive Director',
    organization: 'Electronic Privacy Information Center (EPIC) ',
    group: 'CSISAC'
  },
  {
    name: 'Mr. Suso Baleato  ',
    title: 'Secretary',
    organization: 'CSISAC',
    group: 'CSISAC'
  },
  {
    name: 'Mr. Konstantinos Karachalios',
    title: 'Managing Director ',
    organization: 'IEEE ',
    group: 'ITAC'
  },
  {
    name: 'Ms. Anna Byhovskaya',
    title: 'Senior Policy Advisor ',
    organization: 'TUAC - Trade Union Advisory Committee to the OECD ',
    group: 'TUAC'
  },
  {
    name: 'Ms. Christina J. Colclough',
    title: 'Director Platform & Agency Workers, Digitalisation and Trade',
    organization: ' Uni Global Union (UNI)',
    group: 'TUAC'
  },
  {
    name: 'Mr. Nicolas Miailhe',
    title: 'Co-Founder of AI Initiative',
    organization: 'AI Initiative (civil society) ',
    group: 'Invited expert'
  },
  {
    name: 'Ms. Verity Harding ',
    title: 'Co-Lead',
    organization: ' DeepMind Ethics & Society ',
    group: 'Invited expert'
  },
  {
    name: 'Mr Jason Stanley',
    title: ' Design Research Practice Lead',
    organization: ' ElementAI',
    group: 'Invited expert'
  },
  {
    name: 'Mr. Urs Gasser ',
    title: 'Director, Technology Policy Group',
    organization: 'Harvard Berkman Klein Center',
    group: 'Invited expert'
  },
  {
    name: 'Mr. Ryan Budish',
    title: 'Senior Researcher',
    organization: 'Harvard Berkman Klein Center',
    group: 'Invited expert'
  },
  {
    name: 'Ms. Nozha Boujemaa ',
    title:
      "[Co-moderator, 'AI system lifecycle' AIGO subgroup] Director of Research",
    organization:
      "[Co-moderator, 'AI system lifecycle' AIGO subgroup] Director of Research INRIA",
    group: 'Invited expert'
  },
  {
    name: 'Mr. Michel Morvan ',
    title: 'President / Executive Chairman',
    organization: 'IRT SystemX / Cosmo Tech',
    group: 'Invited expert'
  },
  {
    name: 'Mr. Taylor Reynolds',
    title: 'Director, Technology Policy',
    organization: 'MIT',
    group: 'Invited expert'
  },
  {
    name: 'Mr. Jonathan Frankle',
    title: 'Principal Research Scientist',
    organization: 'MIT',
    group: 'Invited expert'
  },
  {
    name: 'Mr. Jack Clark',
    title: ' Policy Director',
    organization: 'OpenAI',
    group: 'Invited expert'
  },
  {
    name: 'Mr Dudu Mimran ',
    title: 'CTO',
    organization: 'Telekom Innovation Laboratories Israel',
    group: 'Invited expert'
  },
  {
    name: 'Mr. Moez Chakchouk',
    title: 'Assistant Director-General for Communication and Information ',
    organization: 'UNESCO',
    group: 'Invited expert'
  },
  {
    name: 'Ms. Pam Dixon',
    title: 'Founder/ executive director',
    organization: 'World Privacy Forum',
    group: 'Invited expert'
  }
]

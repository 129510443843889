import { PolicyArea } from '../../app/shared/interfaces/policy-area.interface'

export const policyAreas: PolicyArea[] = [
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA1',
    label: 'Agriculture',
    code: null,
    description: null,
    icon: 'pa1',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA1'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA2',
    label: 'Competition',
    code: null,
    description: null,
    icon: 'pa2',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA2'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA3',
    label: 'Corporate governance',
    code: null,
    description: null,
    icon: 'pa3',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA3'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA4',
    label: 'Development',
    code: null,
    description: null,
    icon: 'pa4',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA4'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA5',
    label: 'Digital economy',
    code: null,
    description: null,
    icon: 'pa5',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA5'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA6',
    label: 'Economy',
    code: null,
    description: null,
    icon: 'pa6',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA6'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA7',
    label: 'Education',
    code: null,
    description: null,
    icon: 'pa7',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA7'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA8',
    label: 'Employment',
    code: null,
    description: null,
    icon: 'pa8',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA8'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA9',
    label: 'Environment',
    code: null,
    description: null,
    icon: 'pa9',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA9'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA10',
    label: 'Finance and insurance',
    code: null,
    description: null,
    icon: 'pa10',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA10'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA11',
    label: 'Health',
    code: null,
    description: null,
    icon: 'pa11',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA11'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA12',
    label: 'Industry and entrepreneurship',
    code: null,
    description: null,
    icon: 'pa12',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA12'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA13',
    label: 'Innovation',
    code: null,
    description: null,
    icon: 'pa13',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA13'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA14',
    label: 'Investment',
    code: null,
    description: null,
    icon: 'pa14',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA14'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA15',
    label: 'Public governance',
    code: null,
    description: null,
    icon: 'pa15',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA15'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA16',
    label: 'Science and technology',
    code: null,
    description: null,
    icon: 'pa16',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA16'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA17',
    label: 'Social and welfare issues',
    code: null,
    description: null,
    icon: 'pa17',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA17'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA18',
    label: 'Tax',
    code: null,
    description: null,
    icon: 'pa18',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA18'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA19',
    label: 'Trade',
    code: null,
    description: null,
    icon: 'pa19',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA19'
      }
    },
    subitems: []
  },
  {
    uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA20',
    label: 'Transport',
    code: null,
    description: null,
    icon: 'pa20',
    link: {
      view: 'AIPolicyAreaDashboard',
      params: {
        uri: 'http://ai.oecd.org/taxonomy/policyAreas#PA20'
      }
    },
    subitems: []
  }
]

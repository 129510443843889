<div
  class="card card--author"
  [ngClass]="{
    'is-large': layout === AuthorLayout.Large,
    'is-regular': layout === AuthorLayout.Regular
  }"
>
  <a
    [routerLink]="['/community', author.slug]"
    class="ghost flex align-center justify-start"
  >
    <figure class="avatar m-y-xs">
      <img
        [src]="authorImage.square"
        [alt]="'photo of ' + author.name"
        *ngIf="authorImage"
      />

      <img
        src="./assets/images/default-author.svg"
        [alt]="'photo of ' + author.name"
        *ngIf="!authorImage"
      />
    </figure>
    <a
      [routerLink]="['/community', author.slug]"
      class="is-bold is-size-7 ghost m-l-sm"
      >{{ author.name }}</a
    >
  </a>
  <div>
    <p class="is-bold m-y-xs">
      <span>
        <a [routerLink]="['/community', author.slug]" class="ghost">{{
          author.institution
        }}</a>
      </span>
    </p>
    <p class="m-y-0">
      <a [routerLink]="['/community', author.slug]" class="ghost">
        {{ author.position }}
      </a>
      <a
        [routerLink]="['/community', author.slug]"
        class="ghost"
        *ngIf="author.workingGroups?.length"
      >
        <ng-container *ngIf="author.position">- </ng-container>
        <ng-container
          *ngFor="let workingGroup of author.workingGroups; last as last"
        >
          {{ workingGroup.name }} <span *ngIf="!last"> - </span>
        </ng-container>
      </a>
    </p>
    <div
      [innerHTML]="author.bio"
      *ngIf="layout === AuthorLayout.Large && author.bio && showBio"
    ></div>
    <ul class="m-t-sm author-links">
      <li *ngIf="layout === AuthorLayout.Large && author.bio">
        <a
          i18n="@@author_cardListSeeBio"
          class="deco-on-hover"
          (click)="showBio = true"
          (keydown)="showBio = true"
          tabindex="0"
          *ngIf="!showBio"
          >See bio
        </a>
        <a
          i18n="@@author_cardListHideBio"
          class="deco-on-hover"
          (click)="showBio = false"
          (keydown)="showBio = false"
          tabindex="0"
          *ngIf="showBio"
          >Hide bio
        </a>
      </li>
      <li>
        <a class="deco-on-hover" [routerLink]="['/community', author.slug]">
          <ng-container
            i18n="@@author_cardListSeePosts"
            *ngIf="author.postCount"
            >See all posts</ng-container
          >
          <ng-container
            i18n="@@author_cardListSeeProfile"
            *ngIf="!author.postCount"
            >See profile</ng-container
          >
        </a>
      </li>
    </ul>
  </div>
</div>

<div
  class="card card--author"
  *ngFor="let coAuthor of coAuthors"
  [ngClass]="{
    'is-large': layout === AuthorLayout.Large,
    'is-regular': layout === AuthorLayout.Regular
  }"
>
  <a [routerLink]="['/community', coAuthor.slug]" class="ghost">
    <figure class="avatar m-t-xs">
      <img
        [src]="coAuthor.image.square"
        [alt]="'photo of ' + coAuthor.name"
        *ngIf="coAuthor.image"
      />

      <img
        src="./assets/images/default-author.svg"
        [alt]="'photo of ' + coAuthor.name"
        *ngIf="!coAuthor.image"
      />
    </figure>
  </a>
  <div>
    <p class="is-bold m-b-xs">
      <a [routerLink]="['/community', coAuthor.slug]" class="ghost">{{
        coAuthor.name
      }}</a>
    </p>
    <p class="is-bold m-y-xs">
      <span>
        <a [routerLink]="['/community', coAuthor.slug]" class="ghost">{{
          coAuthor.institution
        }}</a>
      </span>
    </p>
    <p class="m-y-0">
      <a [routerLink]="['/community', coAuthor.slug]" class="ghost">
        {{ coAuthor.position }}
      </a>
      <a
        [routerLink]="['/community', coAuthor.slug]"
        class="ghost"
        *ngIf="coAuthor.workingGroups?.length"
      >
        <ng-container *ngIf="coAuthor.position">- </ng-container>
        <ng-container
          *ngFor="let workingGroup of coAuthor.workingGroups; last as last"
        >
          {{ workingGroup.name }} <span *ngIf="!last"> - </span>
        </ng-container>
      </a>
    </p>
    <div
      [innerHTML]="coAuthor.bio"
      *ngIf="layout === AuthorLayout.Large && coAuthor.bio && showBio"
    ></div>
    <ul class="m-t-sm author-links">
      <li>
        <a class="no-deco" [routerLink]="['/community', coAuthor.slug]">
          <ng-container *ngIf="coAuthor.postCount">See all posts</ng-container>
          <ng-container *ngIf="!coAuthor.postCount">See profile</ng-container>
        </a>
      </li>
    </ul>
  </div>
</div>

<div class="container-full bg-theme">
  <div class="container">
    <div class="columns">
      <div class="column">
        <app-breadcrumbs
          [darkTheme]="true"
          [breadcrumbLinks]="breadcrumbLinks"
        ></app-breadcrumbs>
        <h1 i18n="@@contactTitle" class="title is-1 has-text-light">Contact</h1>
      </div>
    </div>
  </div>
</div>

<div class="container m-t-rel m-b-xxl">
  <div class="columns">
    <!-- Contact form -->
    <div class="column is-5">
      <form [formGroup]="form" (submit)="submit()">
        <!-- Name -->
        <label class="is-size-7" i18n="@@contactLabelName" for="name-input"
          >Name*</label
        >
        <div class="control mb-4">
          <input
            class="input is-small"
            placeholder="Name"
            formControlName="name"
            id="name-input"
            i18n-placeholder="@@contactPlaceholderName"
          />
        </div>

        <!-- Email -->
        <label class="is-size-7" i18n="@@contactLabelEmail" for="email-input"
          >Email*</label
        >
        <div class="control mb-4">
          <input
            class="input is-small"
            type="email"
            placeholder="E-mail"
            formControlName="email"
            id="email-input"
          />
        </div>

        <!-- Company -->
        <label
          class="is-size-7"
          i18n="@@contactLabelCompany"
          for="company-input"
          >Company</label
        >
        <div class="control mb-4">
          <input
            class="input is-small"
            placeholder="Company"
            i18n-placeholder="@@contactPlaceholderCompany"
            formControlName="company"
            id="company-input"
          />
        </div>

        <!--Query -->
        <div class="control mb-4">
          <label i18n="@@contactLabelQuery" for="query-select">Query*</label
          ><br />
          <div class="select is-small">
            <select
              name="query"
              id="query-select"
              formControlName="query"
              required
            >
              <option i18n="@@contactOptionPartnership" value="Partnership">
                Partnership
              </option>
              <option i18n="@@contactOptionSuggestion" value="Suggestion">
                Suggestion
              </option>
              <option i18n="@@contactOptionQuestion" value="Question">
                Question
              </option>
              <option i18n="@@contactOptionOther" value="Other">Other</option>
            </select>
          </div>
        </div>

        <!-- Message -->
        <div class="control mb-4">
          <label i18n="@@contactLabelMessage" for="message-textarea"
            >Message*</label
          >
          <textarea
            name="message"
            id="message-textarea"
            class="textarea"
            cols="30"
            rows="10"
            formControlName="message"
          ></textarea>
        </div>
        <button
          i18n="@@contactButtonSend"
          class="button is-primary"
          [disabled]="form.invalid"
          [ngClass]="{ 'is-loading': loading }"
        >
          Send
        </button>
      </form>
    </div>
    <div class="column is-6 is-offset-1">
      <p i18n="@@contactText">
        We would be happy to hear from you. Please send us comments, suggestions
        or queries about OECD.AI by using this form or by sending us an email at
        <a href="mailto:ai@oecd.org">ai@oecd.org</a>. If you would like to
        receive the latest updates, do not forget to follow us on social media:
      </p>

      <ul>
        <li>
          <a
            i18n="@@contactSocialMediaTwitter"
            href="https://twitter.com/OECDinnovation"
            target="_blank"
            >Twitter</a
          >
        </li>
        <li>
          <a
            i18n="@@contactSocialMediaFacebook"
            href="https://www.facebook.com/theOECD/"
            target="_blank"
            >Facebook</a
          >
        </li>
        <li>
          <a
            i18n="@@contactSocialMediaLinkedIn"
            href="https://www.linkedin.com/company/organisation-eco-cooperation-development-organisation-cooperation-developpement-eco/"
            target="_blank"
            >LinkedIn</a
          >
        </li>
      </ul>
    </div>
  </div>
</div>

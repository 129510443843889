<div class="columns">
  <div class="column is-12">
    <div class="mt-0 is-overflow-hidden">
      <nav class="level">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <h2 class="title is-3">Priority Issues</h2>
          </div>
        </div>

        <!-- Right side -->
        <div class="level-right">
          <!-- Navigation -->
          <div class="carousel-nav arrows" *ngIf="priorityIssues?.length > 1">
            <p class="buttons mt-0 justify-center-mobile">
              <a
                class="button is-circle"
                (click)="selectedItemIndex = selectedItemIndex - 1"
                (keydown)="selectedItemIndex = selectedItemIndex - 1"
                tabindex="0"
                [ngClass]="{
                  'is-outlined is-disabled is-light': selectedItemIndex === 0
                }"
              >
                <span class="icon is-large">
                  <i class="icon icon-Left is-size-5 pr-1"></i>
                </span>
              </a>
              <a
                class="button is-circle ml-1"
                (click)="selectedItemIndex = selectedItemIndex + 1"
                (keydown)="selectedItemIndex = selectedItemIndex + 1"
                tabindex="0"
                [ngClass]="{
                  'is-outlined is-disabled is-light':
                    priorityIssues.length <= carouselItemsPerLine ||
                    selectedItemIndex >=
                      priorityIssues.length - carouselItemsPerLine
                }"
              >
                <span class="icon is-large">
                  <i class="icon icon-Right is-size-5 pl-1"></i>
                </span>
              </a>
            </p>
          </div>
        </div>
      </nav>
      <div
        class="featured-link-carousel featured-link-carousel--news columns index-{{
          selectedItemIndex
        }} has-{{ carouselItemsPerLine }}-items-per-line"
        style="width: auto"
      >
        <div
          class="column is-12-mobile is-6-tablet"
          *ngFor="let priorityIssue of priorityIssues; let i = index"
          [ngClass]="{
        'is-active': selectedItemIndex === i,
        'is-3-desktop': carouselItemsPerLine === 4,
        'is-4-desktop': carouselItemsPerLine === 3,
      }"
        >
          <!-- Priority Issue Card -->
        <app-priority-issue-card
          [priorityIssue]="priorityIssue"
          [isMobile]="isMobile"
        ></app-priority-issue-card>
        </div>
      </div>
    </div>
  </div>
</div>

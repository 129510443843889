<form [formGroup]="filterForm">
  <div class="columns">
    <div class="column is-12 p-b-xs">
      <div class="columns m-b-xs flex flex-wrap">
        <div class="field column is-12-mobile is-8-tablet is-8-desktop p-b-0 search-author">
          <label for="search-input" i18n="@@authorListLabel1" class="is-size-7">Search contributors</label>
          <p class="control has-icons-left has-icons-right my-0">
            <input class="input" type="search" id="search-input" placeholder="Search by name, institution, position..."
              i18n-placeholder="@@author_listPlaceholderSearch" #searchInput />
            <span class="icon is-small is-left">
              <i class="fas fa-search has-text-grey-dark"></i>
            </span>
            <span class="icon is-small is-right pointer close-button" (click)="clear()" (keydown)="clear()" tabindex="0"
              *ngIf="searchEl.nativeElement.value">
              <i class="fas fa-times has-text-primary"></i>
            </span>
          </p>
        </div>

        <div class="column is-12-mobile is-4-tablet is-4-desktop p-b-0">
          <div class="control full-width">
            <label for="role-select" i18n="@@authorListLabel2">Filter by role</label>
            <div class="select is-dark">
              <select class="select" formControlName="role" id="role-select">
                <option value="" selected i18n="@@authorListLabel21Opt1">
                  All roles
                </option>
                <option value="memberOnly" i18n="@@authorListLabel21Opt2">
                  ONE AI Members
                </option>
                <option value="formerMemberOnly" i18n="@@authorListLabel21Opt9">
                  Former ONE AI participants
                </option>
                <option value="observerOnly" i18n="@@authorListLabel21Opt3">
                  ONE AI Observer
                </option>
                <option value="memberOrObserver" i18n="@@authorListLabel21Opt8">
                  ONE AI participants (members and observers)
                </option>
                <option value="secretariatOnly" i18n="@@authorListLabel21Opt4">
                  OECD Secretariat
                </option>
                <option value="blogContributorsOnly" i18n="@@authorListLabel21Opt5">
                  AI Wonk contributors
                </option>
                <option value="gPAIOnly" i18n="@@authorListLabel21Opt6">
                  GPAI
                </option>
                <option value="parliamentaryGroupOnly" i18n="@@authorListLabel21Opt7">
                  OECD Parliamentary Group on AI
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="columns m-b-md flex flex-wrap">
        <div class="column is-12-mobile is-4-tablet is-4-desktop p-b-0" *ngIf="workingGroups">
          <div class="control full-width">
            <label for="working-group-select" i18n="@@authorListLabel3">Filter by working group</label>
            <div class="select is-dark">
              <select class="p-b-0 select" formControlName="workingGroupId" id="working-group-select">
                <option value="" selected i18n="@@authorListLabel3Opt1">
                  All working groups
                </option>
                <option [value]="workingGroup.id" *ngFor="let workingGroup of workingGroups">
                  {{ workingGroup.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-12-mobile p-b-0" [ngClass]="{
            'is-4-tablet is-4-desktop': filterByWorkingGroup,
            'is-6-tablet is-6-desktop': !filterByWorkingGroup
          }">
          <div class="control full-width">
            <label for="stakeholder-type-select" i18n="@@authorListLabel4">Filter by stakeholder type</label>
            <div class="select is-dark">
              <select class="p-b-0 select" formControlName="stakeholderTypeId" id="stakeholder-type-select">
                <option value="" selected i18n="@@authorListLabel4Opt1">
                  All stakeholder types
                </option>
                <option [value]="stakeholderType.id" *ngFor="let stakeholderType of stakeholderTypes">
                  {{ stakeholderType.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-12-mobile p-b-0" [ngClass]="{
            'is-4-tablet is-4-desktop': filterByWorkingGroup,
            'is-6-tablet is-6-desktop': !filterByWorkingGroup
          }">
          <div class="control full-width">
            <label for="country-select" i18n="@@authorListLabel5">Filter by country</label>
            <div class="select is-dark">
              <select class="p-b-0 select" formControlName="countryId" id="country-select">
                <option value="" selected i18n="@@authorListLabel5Opt1">
                  All countries
                </option>
                <option [value]="country.id" *ngFor="let country of countries">
                  {{ country.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-12 has-text-right">
          <i class="fas fa-arrow-right mr-2 has-text-link is-size-8"></i>
          <a class="content is-small"
            href="https://wp.oecd.ai/app/uploads/2024/07/20240716-Guidelines-for-ONE-AI-members.pdf" target="_blank">See
            terms of reference for participation in the OECD.AI Network of
            Experts Group</a>
        </div>
      </div>
    </div>

  </div>

</form>

import { NgClass, NgFor, NgIf } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { RouterLink } from '@angular/router'
import { combineLatest } from 'rxjs'
import { PostLayout } from '../../../enums/post-layout.enum'
import { Post } from '../../../interfaces/resources/post.interface'
import { ViewportService } from '../../../services/viewport.service'
import { PostCardComponent } from '../post-card/post-card.component'

@Component({
  selector: 'app-post-carousel',
  templateUrl: './post-carousel.component.html',
  styleUrls: ['./post-carousel.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, NgFor, PostCardComponent, RouterLink]
})
export class PostCarouselComponent implements OnInit {
  @Input() posts: Post[]
  @Input() title?: string

  PostLayout = PostLayout

  selectedItemIndex = 0
  carouselItemsPerLine: number

  constructor(private viewportService: ViewportService) {}

  ngOnInit(): void {
    // Responsive carousel logic.
    combineLatest([
      this.viewportService.isTouchResolution,
      this.viewportService.isMobileResolution
    ]).subscribe(([isTouch, isMobile]: boolean[]) => {
      this.selectedItemIndex = 0
      if (isMobile) {
        this.carouselItemsPerLine = 1
      } else if (isTouch) {
        this.carouselItemsPerLine = 2
      } else {
        this.carouselItemsPerLine = 4
      }
    })
  }
}

<!-- Header -->
<div class="container-full head">
  <div class="container">
    <div class="columns">
      <div class="column is-12-desktop">
        <app-breadcrumbs
          [darkTheme]="true"
          [breadcrumbLinks]="breadcrumbLinks"
        ></app-breadcrumbs>
        <h1 class="title is-1 has-text-light" i18n="@@authorListTitle">
          OECD.AI community
        </h1>
        <p i18n="@@authorListBlurb" class="has-text-light m-y-gutter">
          Our work relies upon the contributions of a global and diverse group
          of AI professionals. The OECD.AI Network of Experts advises the OECD
          and contributes to its work, but so do many more of our partners whom
          you can find here.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container m-t-alt">
  <app-author-filter
    [formParams]="selectedParams"
    [currentPath]="currentPath"
  ></app-author-filter>

  <!-- large layout -->
  <ng-container *ngIf="paginator">
    <app-list-meta [paginator]="paginator"></app-list-meta>

    <div class="columns flex flex-wrap">
      <div
        class="column is-6-mobile is-4-tablet is-3-desktop"
        *ngFor="let author of paginator.data"
      >
        <app-author-card
          [author]="author"
          [authorImage]="author.image"
        ></app-author-card>
      </div>
    </div>
  </ng-container>
  <div class="columns flex flex-wrap">
    <div class="column is-6-mobile is-4-tablet is-3-desktop" *ngIf="loading">
      <div class="lc lc--author">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="column is-6-mobile is-4-tablet is-3-desktop" *ngIf="loading">
      <div class="lc lc--author">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="column is-6-mobile is-4-tablet is-3-desktop" *ngIf="loading">
      <div class="lc lc--author">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="column is-6-mobile is-4-tablet is-3-desktop" *ngIf="loading">
      <div class="lc lc--author">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="column is-6-mobile is-4-tablet is-3-desktop" *ngIf="loading">
      <div class="lc lc--author">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="column is-6-mobile is-4-tablet is-3-desktop" *ngIf="loading">
      <div class="lc lc--author">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="column is-6-mobile is-4-tablet is-3-desktop" *ngIf="loading">
      <div class="lc lc--author">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="column is-6-mobile is-4-tablet is-3-desktop" *ngIf="loading">
      <div class="lc lc--author">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="column is-6-mobile is-4-tablet is-3-desktop" *ngIf="loading">
      <div class="lc lc--author">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="column is-6-mobile is-4-tablet is-3-desktop" *ngIf="loading">
      <div class="lc lc--author">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="column is-6-mobile is-4-tablet is-3-desktop" *ngIf="loading">
      <div class="lc lc--author">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="column is-6-mobile is-4-tablet is-3-desktop" *ngIf="loading">
      <div class="lc lc--author">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>

  <div class="columns">
    <div class="column">
      <app-pagination
        [paginator]="paginator"
        *ngIf="paginator"
        (pageChange)="goToPage($event)"
      ></app-pagination>
    </div>
  </div>
  <app-author-disclaimer *ngIf="authors"></app-author-disclaimer>
</div>

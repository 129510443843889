import { Injectable } from '@angular/core'

import { ResourceSlug } from '../enums/wordpress/wp-resource-slug.enum'
import { WPFeaturedCard } from '../interfaces/wordpress/wp-featured-card.interface'
import { BlogResourceService } from './blog-resource.service'
import { FormatResourceService } from './format-resource.service'
import { FeaturedCard } from '../interfaces/resources/featured-card.interface'

@Injectable({
  providedIn: 'root'
})
export class FeaturedCardService {
  constructor(
    private formatResourceService: FormatResourceService,
    private blogResourceService: BlogResourceService
  ) {}

  list(listParams: {
    limitTo?: number
    featuredOnHomeOnly?: boolean
    featuredOnGenerativeAIOnly?: boolean
  }): Promise<any> {
    const params: { [key: string]: any } = {}

    params.withoutPagination = true
    params.per_page = 100

    if (listParams.limitTo) {
      params.per_page = listParams.limitTo.toString()
    }
    if (listParams.featuredOnHomeOnly) {
      params.featuredOnHome = '1'
    }
    if (listParams.featuredOnGenerativeAIOnly) {
      params.featuredOnGenerativeAI = '1'
    }
    return (
      this.blogResourceService.list(
        ResourceSlug.FeaturedCards,
        params
      ) as Promise<WPFeaturedCard[]>
    ).then((wpFeaturedCardRes: WPFeaturedCard[]) =>
      wpFeaturedCardRes.map((wpFeaturedCard) =>
        this.formatResourceService.formatFeaturedCard(wpFeaturedCard)
      )
    )
  }

  listByIds(featuredCardIds: string[] | number[]): Promise<FeaturedCard[]> {
    return (
      this.blogResourceService.list(ResourceSlug.FeaturedCards, {
        include: featuredCardIds.join(),
        withoutPagination: true
      }) as Promise<WPFeaturedCard[]>
    ).then((wpFeaturedCardRes: WPFeaturedCard[]) =>
      wpFeaturedCardRes.map((wpFeaturedCard) =>
        this.formatResourceService.formatFeaturedCard(wpFeaturedCard)
      )
    )
  }

  async show(showParams: { id: string }): Promise<any> {
    return (
      this.blogResourceService.show(
        ResourceSlug.FeaturedCards,
        showParams.id
      ) as Promise<WPFeaturedCard>
    ).then((wpFeaturedCardRes: WPFeaturedCard) =>
      this.formatResourceService.formatFeaturedCard(wpFeaturedCardRes)
    )
  }
}

import { Component, OnInit } from '@angular/core'

import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { StripHtmlPipe } from 'src/app/shared/pipes/strip-html.pipe'
import { QuickLinkService } from 'src/app/shared/services/quick-link.service'
import { BreadcrumbLink } from '../../shared/interfaces/breadcrumb-link.interface'
import { Page } from '../../shared/interfaces/page.interface'
import { BreadcrumbService } from '../../shared/services/breadcrumb.service'
import { MetaService } from '../../shared/services/meta.service'
import { PageService } from '../../shared/services/page-service/page.service'

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  breadcrumbLinks: BreadcrumbLink[]
  aboutPage: Page
  pageContent: SafeHtml
  selectedNavItem: string
  hasQuickLinks: boolean
  isLoading: boolean

  constructor(
    private pageService: PageService,
    private breadcrumbService: BreadcrumbService,
    private metaService: MetaService,
    private sanitizer: DomSanitizer,
    private stripHtmlPipe: StripHtmlPipe,
    private activatedRoute: ActivatedRoute,
    private quickLinkService: QuickLinkService
  ) {}

  async ngOnInit() {
    this.activatedRoute.data.subscribe((data) => {
      this.isLoading = true
      this.hasQuickLinks = false

      this.selectedNavItem = data.slug
      this.pageService.show('about-' + this.selectedNavItem).then((page) => {
        this.aboutPage = page

        this.pageContent = this.sanitizer.bypassSecurityTrustHtml(
          this.aboutPage.content
        )
        this.isLoading = false

        this.quickLinkService
          .getQuickLinksGroupsByParentGroupSlug('about-' + this.selectedNavItem)
          .then((quickLinks) => {
            this.hasQuickLinks = !!quickLinks.length
          })

        this.metaService.setTags({
          title: this.aboutPage.metaTitle || this.aboutPage.title,
          description: this.stripHtmlPipe.transform(
            this.aboutPage.metaDescription || this.aboutPage.excerpt
          ),
          path: 'about/' + this.selectedNavItem,
          absoluteOgImage: this.aboutPage.image
            ? this.aboutPage.image.socialCard
            : this.metaService.defaultMeta.absoluteOgImage
        })
      })

      this.breadcrumbLinks = this.breadcrumbService.getBreadCrumbLinks(
        'about',
        [
          {
            label:
              this.selectedNavItem === 'the-context'
                ? 'The context'
                : this.selectedNavItem === 'what-we-do'
                ? 'What we do'
                : 'Background',
            path: 'about/' + this.selectedNavItem
          }
        ]
      )
    })
  }
}

import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core'
import { Router } from '@angular/router'

import { appConstants } from '../../app.constants'
import { DashboardLink } from '../interfaces/dashboard-link.interface'
import { ExternalLink } from '../interfaces/external-link.interface'
import { Environment } from '../interfaces/environment.interface'
import { environment } from '../../../environments/environment'
import { isPlatformBrowser } from '@angular/common'

@Injectable({
  providedIn: 'root'
})
export class SmartLinkService {
  dashboardsBaseUrl = 'dashboards/'
  environment: Environment

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private router: Router,
    private ngZone: NgZone
  ) {
    // Force reload if navigate to same state.
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
  }

  // Navigates to link page.
  goToLink(link: DashboardLink) {
    const path: string = this.generatePath(link)

    this.ngZone.run(() => {
      this.router.navigateByUrl(this.dashboardsBaseUrl + path)
    })
  }

  getPath(link: DashboardLink): string {
    return '/' + this.dashboardsBaseUrl + this.generatePath(link)
  }

  generatePath(link: DashboardLink): string {
    let path: string
    switch (link.view) {
      // OECD AI dashboards.
      case 'AIPrincipleDashboard': {
        path =
          'ai-principles/' +
          link.params.uri?.split(appConstants.PREFIXES.PRINCIPLE).pop()
        break
      }
      case 'AIPolicyAreaDashboard': {
        path =
          'policy-areas/' +
          link.params.uri?.split(appConstants.PREFIXES.POLICY_AREA).pop()
        break
      }

      // Other STIP dashboards.
      case 'countryThemeDashboard': {
        path =
          'countries/' +
          link.params.countryUri
            ?.split(appConstants.PREFIXES.COUNTRY)!
            .pop()!
            .split(appConstants.PREFIXES.ORGANIZATION)!
            .pop()! +
          '/themes/' +
          link.params.mainThemeUri?.split(appConstants.PREFIXES.THEME).pop()
        break
      }
      case 'countryDashboard': {
        path =
          'countries/' +
          link.params.uri
            ?.split(appConstants.PREFIXES.COUNTRY)!
            .pop()!
            .split(appConstants.PREFIXES.ORGANIZATION)
            .pop()
        break
      }
      case 'mainThemeDashboard': {
        path =
          'themes/' + link.params.uri?.split(appConstants.PREFIXES.THEME).pop()
        break
      }
      case 'targetGroupDashboard': {
        path =
          'target-groups/' +
          link.params.uri?.split(appConstants.PREFIXES.TARGET_GROUP).pop()
        break
      }
      case 'policyInstrumentDashboard': {
        path =
          'policy-instruments/' +
          link.params.uri?.split(appConstants.PREFIXES.POLICY_INSTRUMENT).pop()
        break
      }
      case 'responsibleOrganizationDashboard': {
        // Bad practice : we don't receive anything that we can use as an ID to put on the URL so we use "1".
        path = this.router
          .createUrlTree(['responsible-organizations/1'], {
            queryParams: { conceptUris: link.params.conceptUris!.join('||') }
          })
          .toString()
        break
      }
      case 'policyInitiativeList': {
        path =
          'policy-initiatives?conceptUris=' +
          link.params
            .conceptUris!.map((uri) => encodeURIComponent(uri))
            .join('||')
        break
      }
      case 'policyInitiativeDetail': {
        // We remove prefix from uri to have a shorter URL.
        path =
          'policy-initiatives/' +
          encodeURIComponent(
            link.params.uri?.split(appConstants.PREFIXES.GENERIC_URI)!.pop()!
          )

        break
      }
    }
    return path
  }

  goToExternalLink(link: ExternalLink): void {
    // As of today opens only policyExplorer but it is possible to override this function.
    const viewUrl: string = environment.externalLinkUrls.policyExplorer

    if (isPlatformBrowser(this.platformId)) {
      window.open(viewUrl + link.params.uri)
    }
  }
}

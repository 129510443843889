import { PriorityIssue } from 'src/app/shared/interfaces/priority-issue.interface'

export const priorityIssues: PriorityIssue[] = [
  {
    id: 1,
    title: 'AI Futures',
    baseUrl: '/site/ai-futures',
    imageDesktop: 'assets/images/portals/desktop/foresight.png',
    imageMobile: 'assets/images/portals/mobile/foresight.png'
  },
  {
    id: 2,
    title: 'AI Compute & Climate',
    baseUrl: '/site/compute-climate',
    imageDesktop: 'assets/images/portals/desktop/carbon.png',
    imageMobile: 'assets/images/portals/mobile/carbon.png'
  },
  {
    id: 3,
    title: 'AI Risk & Accountability',
    baseUrl: '/site/risk-accountability',
    imageDesktop: 'assets/images/portals/desktop/risks-and-ai-accountability.png',
    imageMobile: 'assets/images/portals/mobile/risks-and-ai-accountability.png'
  },
  {
    id: 4,
    title: 'AI & Health',
    baseUrl: '/site/health',
    imageDesktop: 'assets/images/portals/desktop/health.jpg',
    imageMobile: 'assets/images/portals/mobile/health.jpg'
  },
  {
    id: 5,
    title: ' AI Incidents',
    baseUrl: '/site/incidents',
    imageDesktop: 'assets/images/portals/desktop/manage-incidents.png',
    imageMobile: 'assets/images/portals/mobile/manage-incidents.png'
  },
  {
    id: 6,
    title: 'AI, Data & Privacy',
    baseUrl: '/site/data-privacy',
    imageDesktop: 'assets/images/portals/desktop/ai-data-privacy.png',
    imageMobile: 'assets/images/portals/mobile/ai-data-privacy.png'
  },
  {
    id: 7,
    title: 'Generative AI',
    baseUrl: '/genai',
    imageDesktop: 'assets/images/portals/desktop/genai.png',
    imageMobile: 'assets/images/portals/mobile/genai.png'
  }
]

<div class="container-full bg-gradient">
  <div class="container">
    <div class="columns">
      <div class="column is-10 is-offset-1">
        <h2 i18n="@@termsTitle" class="title is-2 m-t-xxl">
          AI terms & concepts
        </h2>
      </div>
    </div>
    <div class="columns p-b-colgap">
      <div class="column is-3 is-offset-1">
        <div class="tabs is-vertical tabs--principles">
          <ul>
            <li>
              <a
                i18n="@@termsAISystem"
                (mouseenter)="selectedTab = 'AISystem'"
                [ngClass]="{ 'is-active': selectedTab === 'AISystem' }"
              >
                AI system
              </a>
            </li>
            <li>
              <a
                i18n="@@termsAISystemLifecycle"
                (mouseenter)="selectedTab = 'AISystemLifecycle'"
                [ngClass]="{
                  'is-active': selectedTab === 'AISystemLifecycle'
                }"
              >
                AI system lifecycle
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="column is-8">
        <div class="panes">
          <!-- AISystem pan -->
          <div class="tabs-pane" *ngIf="selectedTab === 'AISystem'">
            <div class="pane-wrapper">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <p i18n="@@termsAISystemParagraph">
                    An AI system is a machine-based system that, for explicit or implicit objectives, infers, from the input it receives, how to generate outputs such as predictions, content, recommendations, or decisions that can influence physical or virtual environments. Different AI systems vary in their levels of autonomy and adaptiveness after deployment.
                  </p>
                </div>
                <div class="column is-12">
                  <img src="./assets/images/ai-system-xs.svg" alt="AI system" />
                </div>
              </div>
            </div>
          </div>

          <!-- AISystemLifecycle pan -->
          <div class="tabs-pane" *ngIf="selectedTab === 'AISystemLifecycle'">
            <div class="pane-wrapper">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <p i18n="@@termsAISystemLifecycleParagraph">
                    AI system lifecycle phases involve: i) ‘design, data and
                    models’; which is a context-dependent sequence encompassing
                    planning and design, data collection and processing, as well
                    as model building; ii) ‘verification and validation’; iii)
                    ‘deployment’; and iv) ‘operation and monitoring’. These
                    phases often take place in an iterative manner and are not
                    necessarily sequential. The decision to retire an AI system
                    from operation may occur at any point during the operation
                    and monitoring phase.
                  </p>
                </div>
                <div class="column is-12">
                  <img
                    src="./assets/images/lifecycle.svg"
                    alt="AI system lifecycle"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { NgClass, NgFor, NgIf, isPlatformBrowser } from '@angular/common'
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2
} from '@angular/core'

import { RouterLink } from '@angular/router'
import { environment } from '../../../../environments/environment'
import { policyAreas } from '../../../../locale/policy-areas/policy-areas.content.en'
import { principles } from '../../../../locale/principles/principles.content.en'
import { SmartLinkDirective } from '../../directives/smart-link.directive'
import { PolicyArea } from '../../interfaces/policy-area.interface'
import { Principle } from '../../interfaces/principle.interface'
import { Area } from '../../interfaces/resources/area.interface'
import { KeyTheme } from '../../interfaces/resources/key-theme.interface'
import { AreaService } from '../../services/area.service'
import { EventService } from '../../services/event.service'
import { PostService } from '../../services/post.service'
import { SearchBarComponent } from '../search-bar/search-bar.component'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    SmartLinkDirective,
    SearchBarComponent,
    NgClass,
    NgFor,
    NgIf,
    RouterLink
  ]
})
export class HeaderComponent implements OnInit {
  principles: Principle[] = principles
  policyAreas: PolicyArea[] = policyAreas
  keyThemes: KeyTheme[]
  areas: Area[]

  environment = environment

  activeDropdown: string

  showSearch = false
  showAvailableLanguages = false
  currentLang: string
  currentRouteDropdown?: string
  currentPath: string
  alternativeLangPath: string

  activeTab: string

  fixedHeader = false

  dropdownMappings: { [key: string]: string } = {
    // Blog.
    '/wonk': 'blog',

    // Live data.
    '/data': 'liveData',

    // Countries.
    '/dashboards/overview': 'countries',
    '/dashboards/target-groups': 'countries',
    '/dashboards/countries': 'countries',
    '/dashboards/policy-initiatives': 'countries',

    // Priority issues.
    '/genai': 'priorityIssues',
    '/genai/issues': 'priorityIssues',

    '/site/compute-climate': 'priorityIssues',
    '/site/incidents': 'priorityIssues',
    '/site/ai-futures': 'priorityIssues',
    '/site/risk-accountability': 'priorityIssues',
    '/site/health': 'priorityIssues',
    '/site/data-privacy': 'priorityIssues',
    '/site/ai-index': 'priorityIssues',
    '/working-group-responsible-ai': 'priorityIssues',
    '/working-group-data-governance': 'priorityIssues',
    '/working-group-future-of-work': 'priorityIssues',
    '/working-group-innovation-and-commercialisation': 'priorityIssues',

    '/work-innovation-productivity-skills': 'priorityIssues',
    '/dashboards/work-innovation-productivity-skills': 'priorityIssues',

    //Tools.
    '/catalogue': 'tools',
    '/incidents': 'tools',

    //Resources.
    '/ai-principles': 'resources',
    '/dashboards/ai-principles': 'resources',
    '/policy-areas': 'resources',
    '/dashboards/policy-areas': 'resources',
    '/videos': 'resources',
    '/ai-publications': 'resources',

    // About.
    '/network-of-experts': 'about',
    '/about': 'about',
    '/partners': 'about'
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private postService: PostService,
    private eventService: EventService,
    private areaService: AreaService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    // SSR Performance : Only the clientApp will make hose API calls.
    if (isPlatformBrowser(this.platformId)) {
      ;(
        this.postService.listUnpaginated({
          postType: 'wips-key-themes'
        }) as Promise<KeyTheme[]>
      ).then((keyThemeRes: KeyTheme[]) => {
        this.keyThemes = keyThemeRes.filter((kT) => kT.slug !== 'overview')
      })

      this.eventService.routeChanged.subscribe(
        (routeChanged: { url: string }) => {
          this.hideDropdown()
          this.showSearch = false
          this.currentPath = routeChanged.url
          this.alternativeLangPath = this.currentPath.includes('wonk')
            ? '/wonk'
            : this.currentPath

          this.activeTab = this.setActiveTab(this.currentPath)
        }
      )

      this.areaService.list().then((areas: Area[]) => {
        const trendsAndDataArea: Area = areas.find(
          (a) => a.slug === 'trends-data'
        )

        if (trendsAndDataArea) {
          this.areas = areas
            .filter((a) => a.parent === trendsAndDataArea.id)
            .sort((a, b) => b.sortOrder - a.sortOrder)
        }
      })
    }
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target: any) {
    // We track clicks to close model if open and click outside
    if (
      this.showAvailableLanguages &&
      target.id !== 'language-switcher-button'
    ) {
      this.showAvailableLanguages = false
    }
  }

  // Toggle a dropdown and hide others. If no dropdown is passed through param, hide all.
  showDropdown(dropdownName: string) {
    this.activeDropdown = dropdownName

    if (isPlatformBrowser(this.platformId)) {
      this.renderer.addClass(document.querySelector('#header'), 'is-hovering')
    }
    this.fixedHeader = true
  }

  toggleSearch() {
    this.showSearch = !this.showSearch

    if (this.showSearch) {
      this.hideDropdown()
    }
  }
  hideDropdown() {
    delete this.activeDropdown

    if (isPlatformBrowser(this.platformId)) {
      this.renderer.removeClass(document.querySelector('#header'), 'is-fixed')
    }
    this.fixedHeader = false
  }

  setActiveTab(path: string): string {
    const matchingKey = Object.keys(this.dropdownMappings).find((key) =>
      path.startsWith(key)
    )

    return matchingKey ? this.dropdownMappings[matchingKey] : ''
  }
}

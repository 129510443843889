<div
  *ngIf="featuredCard"
  class="card has-background-white no-border is-sans p-a-0"
  [ngClass]="{
    'is-vertical': layout === FeaturedCardLayout.Vertical,
    'is-horizontal': layout === FeaturedCardLayout.Horizontal
  }"
>
  <!-- No Link -->
  <a *ngIf="!featuredCard.link" class="content ghost no-deco has-text-primary">
    <div class="card__top mb-4" *ngIf="featuredCard.image">
      <figure>
        <img
          [src]="featuredCard.image.eventThumbnail"
          [alt]="featuredCard.title"
        />
      </figure>
    </div>
    <div class="card__bottom mb-4">
      <div
        class="tag my-2"
        *ngIf="featuredCard.tag"
        [ngClass]="{
          'is-danger-bis': featuredCard.tagColor === 'red',
          'is-primary-bis': featuredCard.tagColor === 'blue',
          'is-warning-bis': featuredCard.tagColor === 'yellow',
          'is-color-purple': featuredCard.tagColor === 'purple',
          'is-link': featuredCard.tagColor === 'sky',
          'is-link-bis': featuredCard.tagColor === 'green'
        }"
      >
        {{ featuredCard.tag }}
      </div>
      <h3 class="title is-5 is-bold has-text-primary mt-0">
        {{ featuredCard.title }}
      </h3>
      <p class="m-t-md m-b-gutter" *ngIf="showDescription">
        {{ featuredCard.description }}
      </p>
      <p class="m-t-md content is-small m-b-gutter">
        {{ featuredCard.moreInfos }}
      </p>
    </div>
  </a>

  <!-- External Link -->
  <a
    *ngIf="featuredCard.link && isExternalLink"
    class="content ghost no-deco has-text-primary"
    [href]="featuredCard.link"
    target="_blank"
  >
    <div class="card__top mb-4" *ngIf="featuredCard.image">
      <figure>
        <img [src]="featuredCard.image.eventThumbnail" />
      </figure>
    </div>
    <div class="card__bottom mb-4">
      <div
        class="tag my-2"
        *ngIf="featuredCard.tag"
        [ngClass]="{
          'is-danger-bis': featuredCard.tagColor === 'red',
          'is-primary-bis': featuredCard.tagColor === 'blue',
          'is-warning-bis': featuredCard.tagColor === 'yellow',
          'is-color-purple': featuredCard.tagColor === 'purple',
          'is-link': featuredCard.tagColor === 'sky',
          'is-link-bis': featuredCard.tagColor === 'green'
        }"
      >
        {{ featuredCard.tag }}
      </div>
      <h3 class="title is-5 is-bold has-text-primary mt-0">
        {{ featuredCard.title }}
      </h3>
      <p class="m-t-md m-b-gutter" *ngIf="showDescription">
        {{ featuredCard.description }}
      </p>
      <p class="m-t-md content is-small m-b-gutter">
        {{ featuredCard.moreInfos }}
      </p>
    </div>
  </a>

  <!-- Internal Link -->
  <a
    *ngIf="featuredCard.link && !isExternalLink"
    class="content ghost no-deco has-text-primary"
    [routerLink]="'/' + featuredCard.link.split(environment.baseUrl).join('')"
  >
    <div class="card__top mb-4" *ngIf="featuredCard.image">
      <figure>
        <img [src]="featuredCard.image.eventThumbnail" />
      </figure>
    </div>
    <div class="card__bottom mb-4">
      <div
        class="tag my-2"
        *ngIf="featuredCard.tag"
        [ngClass]="{
          'is-danger-bis': featuredCard.tagColor === 'red',
          'is-primary-bis': featuredCard.tagColor === 'blue',
          'is-warning-bis': featuredCard.tagColor === 'yellow',
          'is-color-purple': featuredCard.tagColor === 'purple',
          'is-link': featuredCard.tagColor === 'sky',
          'is-link-bis': featuredCard.tagColor === 'green'
        }"
      >
        {{ featuredCard.tag }}
      </div>
      <h3 class="title is-5 is-bold has-text-primary mt-0">
        {{ featuredCard.title }}
      </h3>
      <p class="m-t-md m-b-gutter" *ngIf="showDescription">
        {{ featuredCard.description }}
      </p>
      <p class="m-t-md content is-small m-b-gutter">
        {{ featuredCard.moreInfos }}
      </p>
    </div>
  </a>
</div>

<div class="card has-background-white-ter" *ngIf="!isLoading">
  <div class="columns is-multiline">
    <div
      class="column is-12-mobile is-4-tablet is-12-desktop"
      *ngFor="let group of groups"
    >
      <ng-container>
        <h3 class="title is-4 m-b-sm">{{ group.name }}</h3>
        <ul class="my-0">
          <li *ngFor="let link of group.quickLinks">
            <a
              *ngIf="!link.isExternalLink"
              class="no-deco px-0"
              [href]="link.link"
            >
              <span>
                <span>{{ link.title }}</span>
              </span>
            </a>
            <a
              *ngIf="link.isExternalLink"
              target="_blank"
              class="no-deco px-0"
              [href]="link.link"
            >
              <span>
                <span>{{ link.title }}</span>
                <i class="ml-3 icon icon-Externallink is-size-7"></i>
              </span>
            </a>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="isLoading">
  <div class="lc lc--discussion">
    <div></div>
  </div>
</div>

<div class="container-full bg-theme">
  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <div
          class="temporary-item temporary-breadcrumbs"
          *ngIf="!breadcrumbLinks"
        ></div>
        <app-breadcrumbs
          [darkTheme]="true"
          [breadcrumbLinks]="breadcrumbLinks"
          *ngIf="breadcrumbLinks"
        ></app-breadcrumbs>
        <ng-container>
          <h1 class="title is-1 has-text-light" i18n="@@videosTitle">Videos</h1>
          <div class="has-text-light m-y-gutter" i18n="@@videosBlurb">
            Watch our videos to stay informed about what is happening at the
            OECD AI Policy Observatory.
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="container m-t-rel">
  <!-- large layout -->
  <ng-container *ngIf="paginator">
    <app-list-meta [paginator]="paginator"></app-list-meta>

    <div class="columns flex flex-wrap">
      <div
        class="column is-12-mobile is-4-tablet"
        *ngFor="let video of paginator.data"
      >
        <app-video-card [video]="video"></app-video-card>
      </div>
    </div>
  </ng-container>

  <div class="columns">
    <div class="column">
      <app-pagination
        [paginator]="paginator"
        *ngIf="paginator"
        (pageChange)="goToPage($event)"
      ></app-pagination>
    </div>
  </div>
</div>

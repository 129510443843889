<a [routerLink]="priorityIssue.baseUrl" class="ghost">
  <div class="card card--priority-issue p-0 is-borderless">
    <div class="card-image">
      <figure class="image is-square">
        <img
          loading="lazy"
          [src]="
            isMobile ? priorityIssue.imageDesktop : priorityIssue.imageMobile
          "
          [alt]="priorityIssue.title"
        />
      </figure>
      <div
        class="title-wrapper is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
      >
        <h3 class="title is-5 has-text-light mb-0">
          {{ priorityIssue.title }}
        </h3>
      </div>
    </div>
  </div>
</a>

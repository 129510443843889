import { ChangeDetectionStrategy, Component } from '@angular/core'
import { RouterLink, RouterLinkActive } from '@angular/router'

@Component({
  selector: 'app-about-navigation-bar',
  templateUrl: 'about-navigation-bar.component.html',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AboutNavigationBarComponent {

}

<section>
  <div class="container-full bg-theme">
    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <app-breadcrumbs
            [darkTheme]="true"
            [breadcrumbLinks]="breadcrumbLinks"
          ></app-breadcrumbs>
          <h1 i18n="@@trendsAndDataTitle" class="title is-1 has-text-light">
            Trends & data overview
          </h1>
          <p i18n="@@trendsAndDataBaseline" class="has-text-light m-y-gutter">
            OECD.AI compares policy responses and provides data and metrics on
            AI to inform policy.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container m-t-rel" *ngIf="trendsAndDataContent">
    <div class="columns">
      <div class="column is-6">
        <div [innerHTML]="trendsAndDataContent.richText1"></div>

        <div class="button-group">
          <a
            *ngFor="let area of areas"
            class="button m-b-sm m-r-sm m-l-0 is-calm"
            routerLink="/data"
            [queryParams]="{ selectedArea: area.slug }"
            >{{ area.name }}</a
          >
        </div>
      </div>
      <div class="column is-6">
        <figure class="contain">
          <img
            src="{{ trendsAndDataContent.image1 }}"
            alt="{{ trendsAndDataContent.slug }}"
          />
        </figure>
      </div>
    </div>
  </div>
</section>

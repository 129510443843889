import { Component } from '@angular/core'
import { Participant } from 'src/app/shared/interfaces/participant.interface'
import { BreadcrumbLink } from '../../shared/interfaces/breadcrumb-link.interface'
import { BreadcrumbService } from '../../shared/services/breadcrumb.service'
import { MetaService } from '../../shared/services/meta.service'
import { additionalExperts } from './additional-experts.content'
import { contributors } from './contributors.content'
import { otherContributors } from './other-contributors.content'

@Component({
  selector: 'app-participant-list',
  templateUrl: './participant-list.component.html',
  styleUrls: ['./participant-list.component.scss']
})
export class ParticipantListComponent {
  contributors: Participant[] = contributors
  otherContributors: Participant[] = otherContributors
  additionalExperts: Participant[] = additionalExperts

  breadcrumbLinks: BreadcrumbLink[]

  constructor(metaService: MetaService, breadcrumbService: BreadcrumbService) {
    this.breadcrumbLinks =
      breadcrumbService.getBreadCrumbLinks('participantList')
    metaService.setTags({
      title: `List of participants in the OECD Expert Group on AI (AIGO)`,
      description: `The following experts were members of and contributed to the work of AIGO. Their contributions are gratefully acknowledged.`,
      path: 'list-of-participants-oecd-expert-group-on-ai'
    })
  }
}

import { Injectable } from '@angular/core'

import { ResourceSlug } from '../enums/wordpress/wp-resource-slug.enum'
import { Author } from '../interfaces/author.interface'
import { Paginator } from '../interfaces/paginator.interface'
import { WPUser } from '../interfaces/wordpress/wp-user.interface'
import { BlogResourceService } from './blog-resource.service'
import { FormatResourceService } from './format-resource.service'

@Injectable({
  providedIn: 'root'
})
export class AuthorService {
  constructor(
    private blogResourceService: BlogResourceService,
    private formatResourceService: FormatResourceService
  ) {}

  list(listParams: {
    limitTo?: number
    featured?: boolean
    featuredFutures?: boolean
    featuredComputeClimate?: boolean
    featuredIncidents?: boolean
    featuredRiskAccountability?: boolean
    featuredHealth?: boolean
    featuredDataPrivacy?: boolean
    slug?: string
    workingGroup?: string
    country?: string
    stakeholderType?: string
    role?: string
    page?: string
    terms?: string
    withoutPagination?: boolean
  }): Promise<Paginator<Author> | Author[]> {
    // Only show "visible" users. Keep those 2 properties in that order !!! If not WP does not return the correct users for some unknown reason.
    const params: { [key: string]: any } = {
      'filter[meta_value]': '1',
      'filter[meta_key]': 'visible',
      per_page: '100',
      page: listParams.page ? listParams.page.toString() : '1',
      withoutPagination: !!listParams.withoutPagination
    }
    if (listParams.featured) {
      params.featuredOnly = '1'
    }

    //TODO: Find a way to filter by featuredFuturesOnly that doesn't require to delete page and per_page.
    if (listParams.featuredFutures) {
      params.featuredFuturesOnly = '1'

      // Delete page & per_page from the req because theses params send only the first author with featuredFutures = true, instead of all of them.
      delete params.page
      delete params.per_page
    }
    if (listParams.featuredComputeClimate) {
      params.featuredComputeClimateOnly = '1'

      // Delete page & per_page from the req because theses params send only the first author with featuredFutures = true, instead of all of them.
      delete params.page
      delete params.per_page
    }

    if (listParams.featuredIncidents) {
      params.featuredIncidentsOnly = '1'

      // Delete page & per_page from the req because theses params send only the first author with featuredFutures = true, instead of all of them.
      delete params.page
      delete params.per_page
    }

    if (listParams.featuredRiskAccountability) {
      params.featuredRiskAccountabilityOnly = '1'

      // Delete page & per_page from the req because theses params send only the first author with featuredFutures = true, instead of all of them.
      delete params.page
      delete params.per_page
    }

    if (listParams.featuredDataPrivacy) {
      params.featuredDataPrivacyOnly = '1'

      // Delete page & per_page from the req because theses params send only the first author with featuredFutures = true, instead of all of them.
      delete params.page
      delete params.per_page
    }

    if (listParams.featuredHealth) {
      params.featuredHealthOnly = '1'

      // Delete page & per_page from the req because theses params send only the first author with featuredFutures = true, instead of all of them.
      delete params.page
      delete params.per_page
    }

    if (listParams.limitTo) {
      params.per_page = listParams.limitTo.toString()
    }
    if (listParams.slug) {
      params.slug = listParams.slug.toString()
    }
    if (listParams.role === 'observerOnly') {
      params.observerOnly = '1'
    }
    if (listParams.role === 'formerMemberOnly') {
      params.formerMemberOnly = '1'
    }
    if (listParams.role === 'speakerOnly') {
      params.speakerOnly = '1'
    }
    if (listParams.role === 'memberOnly') {
      params.memberOnly = '1'
    }
    if (listParams.role === 'memberOrObserver') {
      params.memberOrObserver = '1'
    }
    if (listParams.role === 'secretariatOnly') {
      params.secretariatOnly = '1'
    }
    if (listParams.role === 'blogContributorsOnly') {
      params.blogContributorsOnly = '1'
    }
    if (listParams.role === 'gPAIOnly') {
      params.gPAIOnly = '1'
    }
    if (listParams.role === 'parliamentaryGroupOnly') {
      params.parliamentaryGroupOnly = '1'
    }

    if (listParams.workingGroup) {
      params.workingGroup = listParams.workingGroup
    }
    if (listParams.country) {
      params.country = listParams.country
    }
    if (listParams.stakeholderType) {
      params.stakeholderType = listParams.stakeholderType
    }
    if (listParams.terms) {
      params.terms = listParams.terms
    }

    // params['filter[meta_value]'] = '1'
    // params['filter[meta_key]'] = 'visible'

    if (listParams.withoutPagination) {
      return (
        this.blogResourceService.list(ResourceSlug.Users, params) as Promise<
          WPUser[]
        >
      ).then((wpUserRes: WPUser[]) =>
        wpUserRes.map((wpU) => this.formatResourceService.formatAuthor(wpU))
      )
    }
    console.log('params', params)
    return (
      this.blogResourceService.list(ResourceSlug.Users, params) as Promise<
        Paginator<WPUser>
      >
    ).then((wpUserRes: Paginator<WPUser>) => {
      const authors: Author[] = wpUserRes.data.map((wpUser: WPUser) =>
        this.formatResourceService.formatAuthor(wpUser)
      )
      const paginator: Paginator<Author> = Object.assign(wpUserRes, {
        data: authors
      })
      return paginator
    })
    // TODO: Catch
  }

  listByIds(authorIds: number[]): Promise<Author[]> {
    return (
      this.blogResourceService.list(ResourceSlug.Users, {
        include: authorIds.join(),
        withoutPagination: true
      }) as Promise<WPUser[]>
    ).then((wpUserRes: WPUser[]) =>
      wpUserRes.map((wpU) => this.formatResourceService.formatAuthor(wpU))
    )
  }

  // The way to get author by slug instead of Id (for SEO purposes) is to filter list by slug.
  show(slug: string): Promise<Author> {
    return (this.list({ slug }) as Promise<Paginator<Author>>).then(
      (wpAuthorRes: Paginator<Author>) => {
        if (!wpAuthorRes?.data?.length) {
          return Promise.reject('resource not found')
        }
        return wpAuthorRes.data[0]
      }
    )
    // TODO: catch
  }
}


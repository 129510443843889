import { Component, OnInit } from '@angular/core'

import { StripHtmlPipe } from 'src/app/shared/pipes/strip-html.pipe'
import { BreadcrumbLink } from '../../shared/interfaces/breadcrumb-link.interface'
import { Page } from '../../shared/interfaces/page.interface'
import { BreadcrumbService } from '../../shared/services/breadcrumb.service'
import { MetaService } from '../../shared/services/meta.service'
import { PageService } from '../../shared/services/page-service/page.service'

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  breadcrumbLinks: BreadcrumbLink[]
  partnersContent: Page
  selectedNavItem = 'partners'
  constructor(
    breadcrumbService: BreadcrumbService,
    private metaService: MetaService,
    private stripHtmlPipe: StripHtmlPipe,
    private pageService: PageService
  ) {
    this.breadcrumbLinks = breadcrumbService.getBreadCrumbLinks('partners')
  }

  async ngOnInit() {
    this.partnersContent = await this.pageService.show('partners')

    this.metaService.setTags({
      title: this.partnersContent.metaTitle || this.partnersContent.title,
      description: this.stripHtmlPipe.transform(
        this.partnersContent.metaDescription || this.partnersContent.excerpt
      ),
      path: 'partners',
      absoluteOgImage: this.partnersContent.image
        ? this.partnersContent.image.socialCard ||
          this.partnersContent.image.landscape ||
          this.partnersContent.image.videoCard
        : this.metaService.defaultMeta.absoluteOgImage
    })
  }
}

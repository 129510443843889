import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { BreadcrumbsComponent } from '../../../partials/breadcrumbs/breadcrumbs.component'
import { BreadcrumbLink } from '../../../interfaces/breadcrumb-link.interface'
import { AboutPageState } from '../../../interfaces/about-page.model'
import { NgIf, NgStyle } from '@angular/common'

@Component({
  selector: 'app-about-section-head',
  templateUrl: './about-section-head.component.html',
  styles: [`
    .fl span {
      display: inline;
    }

    .fl a {
      display: inline;
      margin-left: 2px;
    }`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BreadcrumbsComponent,
    NgIf,
    NgStyle
  ],
  standalone: true
})

export class AboutSectionHeadComponent {
  @Input({ required: true }) breadcrumbLinks: BreadcrumbLink[]
  @Input({ required: true }) page: AboutPageState
  @Input() dynamicDescription ?: string | null
}

import { Component, Input } from '@angular/core'

import { Paginator } from '../../interfaces/paginator.interface'
import { NgIf, NgPlural, NgPluralCase, NgTemplateOutlet } from '@angular/common'

@Component({
  selector: 'app-list-meta',
  templateUrl: './list-meta.component.html',
  styleUrls: ['./list-meta.component.scss'],
  standalone: true,
  imports: [NgPlural, NgPluralCase, NgTemplateOutlet, NgIf]
})
export class ListMetaComponent {
  @Input() paginator: Paginator<any>
}

import { Injectable } from '@angular/core'
import { ResourceSlug } from '../enums/wordpress/wp-resource-slug.enum'

import { TaxonomyTerm } from '../interfaces/taxonomy-term.interface'
import { BlogResourceService } from './blog-resource.service'

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  constructor(private blogResourceService: BlogResourceService) {}

  async showBySlug(slug: string): Promise<any> {
    const countries: TaxonomyTerm[] = (await this.blogResourceService.list(
      ResourceSlug.Countries,
      { slug, withoutPagination: true }
    )) as TaxonomyTerm[]

    if (!countries || !countries.length) {
      // TODO: Navigate to 404 page.
    }
    return countries[0]
  }

  show(id: string | number): Promise<TaxonomyTerm> {
    return this.blogResourceService.show(ResourceSlug.Countries, id)
  }

  listByIds(countryIds: string[] | number[]): Promise<TaxonomyTerm[]> {
    return this.blogResourceService.list(ResourceSlug.Countries, {
      include: countryIds.join(),
      withoutPagination: true
    }) as Promise<TaxonomyTerm[]>
  }
}

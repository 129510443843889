<form [formGroup]="searchForm">
  <div class="field">
    <div class="control">
      <p class="control has-icons-left has-icons-right my-0">
        <input
          (keyup)="onSearchInputKeyup($event)"
          (focus)="showList = true"
          type="search"
          [placeholder]="placeholder"
          formControlName="terms"
          id="dashboard-search"
          autocomplete="off"
          class="input"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-search has-text-grey-dark"></i>
        </span>
      </p>

      <nav
        class="panel is-absolute is-fullwidth has-background-light no-radius content is-small"
        *ngIf="searchResults && showList"
      >
        <a
          class="panel-block ghost"
          [ngClass]="{ 'is-active': focusedValueIndex === i }"
          *ngFor="let searchResult of searchResults; let i = index"
          [appSmartLink]="searchResult.link"
        >
          <span class="panel-icon">
            <i
              class="has-text-dark fas"
              aria-hidden="true"
              [ngClass]="{
                'fa-globe': searchResult.type === DashboardType.Countries,
                'fa-wrench':
                  searchResult.type === DashboardType.PolicyInstruments,
                'fa-bullseye': searchResult.type === DashboardType.TargetGroups
              }"
            ></i>
          </span>
          <span
            innerHTML="{{
              searchResult.label | highlight : searchForm.value.terms
            }}"
          >
          </span>
          <span class="pl-1 is-italic has-text-dark"
            >({{ searchResult.initiativeCount }} policies)</span
          >
        </a>
        <div class="panel-block" *ngIf="!searchResults.length">
          <span class="panel-icon">
            <i class="has-text-dark fas fa-frown-open" aria-hidden="true"></i>
          </span>
          <span> No results... </span>
        </div>
      </nav>
    </div>
  </div>
</form>

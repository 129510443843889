export const environment = {
  production: true,
  noIndex: true,
  baseUrl: 'https://pp.oecd.ai/en/',
  apiDashboardUrl: 'https://api-pp.oecd.ai/ws/AIPO/API/',
  apiCatalogBaseUrl: 'https://pp.oecd-ai.case-api.buddyweb.fr/',
  apiCatalogStoragePath: 'https://pp.oecd-ai.case-api.buddyweb.fr/storage/',
  apiAiSearchBaseUrl: 'https://air.oecdai.org/api/v1',
  apiBlogBaseUrl: 'https://pp.wp.oecd.ai/wp-json/wp/v2/',
  queryBuilderBaseUrl: 'https://api-pp.oecd.ai/ws/AIPO/API/',
  apiIncidentsMonitorBaseUrl: 'https://incidents-server.oecdai.org/api/v1',
  contactFormApiUrl: 'email.xqy',
  enableBugsnag: true,
  envName: 'staging',

  disqusId: 'ai-futures-pp',

  // Working groups slugs.
  futuresWorkingGroupSlug: 'expert-group-on-ai-foresight',
  computeClimateWorkingGroupSlug: 'expert-group-on-compute-climate',
  riskAccountabilityWorkingGroupSlug: 'expert-group-on-ai-risk-accountability',
  incidentsWorkingGroupSlug: 'expert-group-on-ai-incidents',
  dataPrivacyWorkingGroupSlug: 'expert-group-on-ai-data-privacy',
  healthWorkingGroupSlug: 'expert-group-on-health',

  allPoliciesMediaId: 3431, // Used in MainDashboardComponent to have a dynamic file to download.
  purposeId: 8,
  externalLinkUrls: {
    policyExplorer:
      'https://stip.oecd.org/policyexplorer/?mode=defined&searchProfileId=innovation&uri='
  },
  lang: 'en',
  availableLanguages: [
    {
      code: 'en',
      name: 'English',
      url: 'https://pp.oecd.ai/en'
    },
    {
      code: 'fr',
      name: 'French',
      url: 'https://pp.oecd.ai/fr'
    }
  ]
}

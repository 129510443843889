export type AboutPageState = 'the-context' | 'what-we-do' | 'about-' | 'one-ai-members' | 'partners' | 'background'

export function slugMapToLabel(val: AboutPageState): string {
  switch (val) {
    case 'the-context':
      return 'The context'
    case 'what-we-do':
      return 'GPAI'
    case 'about-':
      return 'About OECD.AI'
    case 'partners':
      return 'Partners'
    case 'background':
      return 'Background'
    case 'one-ai-members':
      return 'About AIGO'
    default:
      return ''
  }
}

export function createAboutPath(val: AboutPageState): string {
  const pagePref = 'about/'
  switch (val) {
    case 'what-we-do':
      return `${pagePref}about-gpai`
    case 'about-':
      return `about-oecd-ai`
    default:
      return `${pagePref}${val}`
  }
}

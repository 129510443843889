import { Injectable } from '@angular/core'
import { Document } from '../interfaces/resources/document.interface'

import { BlogResourceService } from './blog-resource.service'

import { ResourceSlug } from '../enums/wordpress/wp-resource-slug.enum'
import { FormatResourceService } from './format-resource.service'
import { WPDocument } from '../interfaces/wordpress/wp-document.interface'

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  constructor(
    private blogResourceService: BlogResourceService,
    private formatResourceService: FormatResourceService
  ) {}

  list(listParams: {
    authorId?: number
    coAuthorId?: number
    featured?: boolean
    limitTo?: number
    AIPrincipleId?: string
    policyAreaId?: string
    stakeholderTypeId?: string
    slug?: string
    workingGroupIds?: number
    countryId?: string | number
  }): Promise<Document[]> {
    const params: { [key: string]: any } = {
      withoutPagination: true
    }
    if (listParams.authorId) {
      params.author = listParams.authorId.toString()
    }
    if (listParams.coAuthorId) {
      params.coAuthor = listParams.coAuthorId.toString()
    }
    if (listParams.featured) {
      // Get posts with field featured true ( WP Ugly Way)
      params['filter[meta_key]'] = 'featured'
      params['filter[meta_value]'] = '1'
    }
    if (listParams.limitTo) {
      params.per_page = listParams.limitTo.toString()
    }
    if (listParams.AIPrincipleId) {
      // Get posts with ai-principles featured true ( WP Ugly Way)
      params[ResourceSlug.Principles] = listParams.AIPrincipleId
    }
    if (listParams.workingGroupIds) {
      params.workingGroup = listParams.workingGroupIds
    }
    if (listParams.policyAreaId) {
      params[ResourceSlug.PolicyAreas] = listParams.policyAreaId
    }
    if (listParams.stakeholderTypeId) {
      params[ResourceSlug.StakeholderTypes] = listParams.stakeholderTypeId
    }
    if (listParams.countryId) {
      params[ResourceSlug.Countries] = listParams.countryId
    }

    if (listParams.slug) {
      params.slug = listParams.slug
    }

    return (
      this.blogResourceService.list(ResourceSlug.Documents, params) as Promise<
        WPDocument[]
      >
    ).then((wpDocumentRes: WPDocument[]) => {
      return wpDocumentRes.map((d) =>
        this.formatResourceService.formatDocument(d)
      )
    })
  }

  listByIds(documentIds: number[]): Promise<Document[]> {
    return (
      this.blogResourceService.list(ResourceSlug.Documents, {
        include: documentIds.join(),
        withoutPagination: true
      }) as Promise<WPDocument[]>
    ).then((wpDocumentRes: WPDocument[]) =>
      wpDocumentRes.map((d) => this.formatResourceService.formatDocument(d))
    )
  }

  // The way to get author by slug instead of Id (for SEO purposes) is to filter list by slug.
  show(slug: string): Promise<Document> {
    return this.list({ slug }).then((wpDocumentRes: Document[]) => {
      if (!wpDocumentRes || !wpDocumentRes.length) {
        return Promise.reject('resource not found')
      }
      return wpDocumentRes[0]
    })
  }
}

import { isPlatformBrowser } from '@angular/common'
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { environment } from '../../../environments/environment'
import { flashMessages } from '../../../locale/flash-messages/flash-messages.en'
import { BreadcrumbLink } from '../../shared/interfaces/breadcrumb-link.interface'
import { BreadcrumbService } from '../../shared/services/breadcrumb.service'
import { DashboardService } from '../../shared/services/dashboard.service'
import { FlashMessageService } from '../../shared/services/flash-message.service'
import { MetaService } from '../../shared/services/meta.service'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactFormApiUrl: string = environment.contactFormApiUrl

  form: FormGroup

  loading = false
  breadcrumbLinks: BreadcrumbLink[]

  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private flashMessageService: FlashMessageService,
    metaService: MetaService,
    breadcrumbService: BreadcrumbService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.breadcrumbLinks = breadcrumbService.getBreadCrumbLinks('contact')
    metaService.setTags({
      title: 'Contact',
      description:
        'We would be happy to hear from you. Please send us comments, suggestions or queries about OECD.AI by using this form or by sending us an email at ai@oecd.org.',
      path: 'contact'
    })
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      company: '',
      query: ['', Validators.required],
      message: ['', Validators.required]
    })
  }

  submit() {
    this.loading = true

    const formData = new FormData()
    formData.append('name', this.form.value.name)
    formData.append('email', this.form.value.email)
    formData.append('query', this.form.value.query)
    formData.append('message', this.form.value.message)

    if (this.form.value.company) {
      formData.append('company', this.form.value.company)
    }

    this.dashboardService.store(this.contactFormApiUrl, formData).then(
      () => {
        this.loading = false
        this.flashMessageService.success(
          flashMessages.contact.success.successSend
        )
        this.form.reset()

        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0)
        }
      },
      () => {
        this.loading = false
        this.flashMessageService.error(flashMessages.contact.error.cannotSend)
      }
    )
  }
}

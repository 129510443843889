<div
  class="video-card"
  (click)="isActive = true"
  (keydown)="(true)"
  tabindex="0"
>
  <figure *ngIf="video.image">
    <img [src]="video.image.videoCard" [alt]="video.name" />
    <div class="img-fg">
      <i class="icon icon-Video has-text-white is-size-1"></i>
    </div>
  </figure>
  <h2 class="title is-4 m-b-0 m-t-md">{{ video.name }}</h2>
  <span class="date small-meta">{{
    video.publicationDate | date : 'LLLL d, yyyy'
  }}</span>
  <span *ngIf="video.duration && video.duration.length">
    <span class="m-x-sm">–</span>
    <img
      src="./assets/images/clock-small.svg"
      style="position: relative; top: 3px"
      width="16"
      class="m-x-xs"
      alt="clock"
    />
    <span class="small-meta">{{ video.duration }}</span>
  </span>

  <div
    class="excerpt-wrapper"
    [innerHTML]="video.excerpt"
    *ngIf="showExcerpt"
  ></div>
</div>

<!-- Video modal -->
<div class="modal modal-large is-active" *ngIf="isActive">
  <div class="modal-background"></div>
  <div class="modal-content">
    <iframe
      id="youtube-player"
      width="100%"
      height="500"
      style="height: 500px"
      [src]="video.youtubeLink"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</div>

<section>
  <div class="container-full bg-theme">
    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <app-breadcrumbs
            [darkTheme]="true"
            [breadcrumbLinks]="breadcrumbLinks"
          ></app-breadcrumbs>
          <h1 i18n="@@searchTitle" class="title is-1 has-text-light">Search</h1>
          <p i18n="@@searchBaseline" class="has-text-light m-y-gutter">
            Search the entire OECD.AI website.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <div class="google-media-search" [innerHTML]="searchHTML"></div>
      </div>
    </div>
  </div>
</section>

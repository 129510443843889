import { Component, ElementRef, HostListener, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms'
import * as fastLevenshtein from 'fast-levenshtein'

import { CommonModule } from '@angular/common'
import { SmartLinkDirective } from 'src/app/shared/directives/smart-link.directive'
import { DataItem } from '../../../../shared/interfaces/data-item.interface'
import { HTMLInputEvent } from '../../../../shared/interfaces/html-input-event.interface'
import { SearchResult } from '../../../../shared/interfaces/search-result.interface'
import { DashboardService } from '../../../../shared/services/dashboard.service'
import { SmartLinkService } from '../../../../shared/services/smart-link.service'
import { ChartType } from '../../enums/chart-type.enum'
import { DashboardType } from '../../enums/dashboard-type.enum'
import { Aggregation } from '../../interfaces/aggregation.interface'
import { Dashboard } from '../../interfaces/dashboard.interface'
import { HighlightPipe } from 'src/app/shared/pipes/highlight.pipe'

@Component({
  selector: 'app-dashboard-search',
  templateUrl: './dashboard-search.component.html',
  standalone: true,
  styleUrls: ['./dashboard-search.component.scss'],
  imports: [ReactiveFormsModule, CommonModule, SmartLinkDirective, HighlightPipe]
})
export class DashboardSearchComponent implements OnInit {
  searchForm: FormGroup
  allDashboards: SearchResult[]
  searchResults: SearchResult[]

  placeholder: string
  showList: boolean
  focusedValueIndex: number

  DashboardType = DashboardType

  constructor(
    private formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private dashboardService: DashboardService,
    private smartLinkService: SmartLinkService
  ) {}

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      terms: ''
    })

    this.placeholder = 'Search for a specific dashboard ...'

    this.dashboardService
      .getMainDashboard()
      .then((mainDashboardRes: Dashboard) => {
        this.allDashboards = this.getAllDashboards(
          mainDashboardRes.aggregations
        )

        this.searchForm.valueChanges.subscribe(
          (newValue: { terms: string }) => {
            this.searchResults = this.searchDashboards(newValue.terms)
          }
        )
      })
  }

  onSearchInputKeyup(event: HTMLInputEvent) {
    if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
      this.navigateSearchResults(event.key)
    } else {
      delete this.focusedValueIndex
    }
  }

  // Return only searchResults that match to the terms
  searchDashboards(terms: string): SearchResult[] {
    return this.allDashboards
      .filter(
        (dashboard: SearchResult) =>
          terms &&
          dashboard.label
            .toLocaleLowerCase()
            .includes(terms.toLocaleLowerCase())
      )
      .sort(
        (a: SearchResult, b: SearchResult) =>
          fastLevenshtein.get(terms, a.label) -
          fastLevenshtein.get(terms, b.label)
      )
      .slice(0, 10)
  }

  clearForm(): void {
    this.searchForm.reset()
    delete this.searchResults
  }

  // Extract all links to dashboard from different aggregations.
  getAllDashboards(aggregations: Aggregation[]): SearchResult[] {
    const dashboards: SearchResult[] = []

    aggregations.forEach((aggregation: Aggregation) => {
      if (
        aggregation.chartTypes.includes(ChartType.WorldMap) ||
        aggregation.chartTypes.includes(ChartType.FlagList)
      ) {
        aggregation.datasets[0].data.forEach((country: DataItem) => {
          dashboards.push({
            label: country.label,
            initiativeCount: country.initiativeCount,
            link: country.link,
            type: aggregation.id
          })
        })
      }

      if (
        aggregation.chartTypes.includes(ChartType.BubbleChart) ||
        aggregation.chartTypes.includes(ChartType.Sunburst) ||
        aggregation.chartTypes.includes(ChartType.TreeMap)
      ) {
        aggregation.datasets[0].data.forEach((mainItem: DataItem) => {
          if (mainItem.subItems) {
            mainItem.subItems.forEach((subItem: DataItem) => {
              dashboards.push({
                label: subItem.label,
                initiativeCount: subItem.initiativeCount,
                link: subItem.link,
                type: aggregation.id
              })
            })
          } else {
            dashboards.push({
              label: mainItem.label,
              initiativeCount: mainItem.initiativeCount,
              link: mainItem.link,
              type: aggregation.label
            })
          }
        })
      }
    })
    return dashboards.filter((d) => d.initiativeCount > 0)
  }

  // Use arrowKeys and enter to select suggested themes with keyboard
  navigateSearchResults(key: string): void {
    if (key === 'ArrowDown') {
      if (typeof this.focusedValueIndex === 'undefined') {
        this.showList = true
        this.focusedValueIndex = 0
      } else if (this.focusedValueIndex < this.searchResults.length) {
        this.focusedValueIndex++
      }
    } else if (key === 'ArrowUp') {
      if (!this.focusedValueIndex) {
        this.showList = false
        delete this.focusedValueIndex
      } else {
        this.focusedValueIndex--
      }
    } else if (
      key === 'Enter' &&
      typeof this.focusedValueIndex !== 'undefined' &&
      this.searchResults[this.focusedValueIndex]
    ) {
      this.smartLinkService.goToLink(
        this.searchResults[this.focusedValueIndex].link
      )
    }
  }

  // Click outside closes list
  @HostListener('document:click', ['$event.target'])
  clickOut(eventTarget) {
    if (!this.elementRef.nativeElement.contains(eventTarget)) {
      this.showList = false
    }
  }
}

import { NgClass, NgFor, NgIf } from '@angular/common'
import { Component, Input } from '@angular/core'
import { RouterLink } from '@angular/router'

import { environment } from '../../../../environments/environment'
import { BreadcrumbLink } from '../../interfaces/breadcrumb-link.interface'

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  standalone: true,
  imports: [NgClass, NgFor, NgIf, RouterLink]
})
export class BreadcrumbsComponent {
  @Input() breadcrumbLinks: BreadcrumbLink[]
  @Input() darkTheme = false
  baseUrl: string = environment.baseUrl
}

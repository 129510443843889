import { Component, OnInit } from '@angular/core'
import { priorityIssues } from 'src/assets/content/priority-issue/priority-issue.content'
import { PriorityIssue } from '../../interfaces/priority-issue.interface'
import { ViewportService } from '../../services/viewport.service'

@Component({
  selector: 'app-priority-issue-carousel',
  templateUrl: './priority-issue-carousel.component.html',
  styleUrls: ['./priority-issue-carousel.component.scss']
})
export class PriorityIssueCarrouselComponent implements OnInit {
  priorityIssues: PriorityIssue[] = priorityIssues

  selectedItemIndex = 0
  carouselItemsPerLine: number

  isMobile: boolean

  constructor(private viewportService: ViewportService) {}

  ngOnInit(): void {
    this.viewportService.isMobileResolution.subscribe((isMobile: boolean) => {
      this.isMobile = isMobile
      this.carouselItemsPerLine = this.isMobile ? 1 : 4
    })
  }
}

import { Component, Input, OnInit } from '@angular/core'

import { QuickLink } from '../../interfaces/quick-link'
import { QuickLinkService } from '../../services/quick-link.service'
import { NgFor, NgIf } from '@angular/common'

@Component({
  selector: 'app-quick-link-list',
  templateUrl: './quick-link-list.component.html',
  styleUrls: ['./quick-link-list.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor]
})
export class QuickLinkListComponent implements OnInit {
  @Input() parentGroupSlug?: string

  quickLinks: QuickLink[]
  groups: { id: number; name: string; quickLinks: QuickLink[] }[]
  isLoading = true

  constructor(private quickLinkService: QuickLinkService) {}

  async ngOnInit() {
    this.quickLinkService.list().then((quickLinkRes: QuickLink[]) => {
      this.quickLinks = quickLinkRes

      this.groups = this.quickLinks.reduce((acc, quickLink) => {
        const group = acc.find((g) => g.id === quickLink.group?.id)
        if (group) {
          group.quickLinks.push(quickLink)
        } else if (quickLink.group?.id) {
          acc.push({
            id: quickLink.group.id,
            name: quickLink.group.name,
            quickLinks: [quickLink]
          })
        }
        return acc
      }, [])

      if (this.parentGroupSlug) {
        this.quickLinkService.listGroups().then((groupRes) => {
          const parentGroup = groupRes.find(
            (group) => group.slug === this.parentGroupSlug
          )

          if (parentGroup) {
            const childrenGroupIds = groupRes
              .filter((group) => group.parent === parentGroup.id)
              .map((group) => group.id)

            this.groups = this.groups.filter((group) =>
              childrenGroupIds.includes(group.id)
            )
          }
        })
      }

      this.isLoading = false
    })
  }
}

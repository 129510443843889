import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AboutComponent } from './about.component'
import { PartnersComponent } from '../partners/partners.component'
import { AboutRoutingModule } from './about-routing.module'
import {
  AboutSectionHeadComponent
} from '../../shared/components/about-section/about-section-head/about-section-head.component'
import {
  AboutNavigationBarComponent
} from '../../shared/components/about-section/about-navigation-bar/about-navigation-bar.component'
import { QuickLinkListComponent } from '../../shared/partials/quick-link-list/quick-link-list.component'
import { BreadcrumbsComponent } from '../../shared/partials/breadcrumbs/breadcrumbs.component'

@NgModule({
  declarations: [AboutComponent, PartnersComponent],
  imports: [
    CommonModule,
    AboutRoutingModule,
    AboutSectionHeadComponent,
    AboutNavigationBarComponent,
    QuickLinkListComponent,
    BreadcrumbsComponent
  ]
})
export class AboutModule {
}

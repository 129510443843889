import { Component, OnInit } from '@angular/core'
import { metaDataContent } from '../../../locale/meta-data/meta-data.content.en'
import { BreadcrumbLink } from '../../shared/interfaces/breadcrumb-link.interface'
import { AIResource } from '../../shared/interfaces/resources/ai-resource.interface'
import { AiResourceService } from '../../shared/services/ai-resource.service'
import { BreadcrumbService } from '../../shared/services/breadcrumb.service'
import { MetaService } from '../../shared/services/meta.service'

@Component({
  selector: 'app-resources-on-ai',
  templateUrl: './resources-on-ai.component.html',
  styleUrls: ['./resources-on-ai.component.scss']
})
export class ResourcesOnAiComponent implements OnInit {
  breadcrumbLinks: BreadcrumbLink[]
  OECDResources: AIResource[]
  nonOECDResources: AIResource[]
  otherAIResources: AIResource[]

  constructor(
    private AIResourceService: AiResourceService,
    breadcrumbService: BreadcrumbService,
    metaService: MetaService
  ) {
    metaService.setTags(metaDataContent.resources)
    this.breadcrumbLinks = breadcrumbService.getBreadCrumbLinks('resources')
  }

  async ngOnInit(): Promise<void> {
    const AIResources = await this.AIResourceService.list()

    this.OECDResources = AIResources.filter(
      (element) => element.isOECDResource === 'Yes'
    )

    this.nonOECDResources = AIResources.filter(
      (element) => element.isOECDResource === 'No'
    )

    this.otherAIResources = AIResources.filter(
      (element) => element.isOECDResource === 'Other'
    )
  }
}

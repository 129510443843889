import { CommonModule, NgIf } from '@angular/common'
import { Component, Input } from '@angular/core'
import { FeaturedCardLayout } from '../../enums/featured-card-layout.enum'
import { FeaturedCard } from '../../interfaces/resources/featured-card.interface'
import { FeaturedCardComponent } from '../featured-card/featured-card.component'

@Component({
  selector: 'app-featured-card-list',
  templateUrl: './featured-card-list.component.html',
  standalone: true,
  styleUrls: ['./featured-card-list.component.scss'],
  imports: [FeaturedCardComponent, NgIf, CommonModule]
})
export class FeaturedCardListComponent {
  FeaturedCardLayout = FeaturedCardLayout
  @Input({ required: true }) loading: boolean
  @Input({ required: true }) featuredCards: FeaturedCard[]
}

import { Component, Input, OnInit } from '@angular/core'

import { Post } from '../../../interfaces/resources/post.interface'
import { StripHtmlPipe } from '../../../pipes/strip-html.pipe'
import { PostLayout } from '../../../enums/post-layout.enum'
import { TruncatePipe } from '../../../pipes/truncate.pipe'
import { CommonModule, DatePipe } from '@angular/common'
import { RouterModule } from '@angular/router'

@Component({
  selector: 'app-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss'],
  standalone: true,
  imports: [StripHtmlPipe, TruncatePipe, DatePipe, RouterModule, CommonModule]
})
export class PostCardComponent implements OnInit {
  @Input() post: Post
  @Input() layout: PostLayout = PostLayout.Regular
  @Input() isMinimal?: boolean = false

  minRead: number
  excerpt: string

  private wordsPerMinute = 250

  PostLayout = PostLayout

  constructor(
    private stripHtmlPipe: StripHtmlPipe,
    private truncatePipe: TruncatePipe
  ) {}

  ngOnInit() {
    this.minRead = this.post.content
      ? Math.ceil(
          this.stripHtmlPipe.transform(this.post.content).split(' ').length /
            this.wordsPerMinute
        )
      : 0

    this.excerpt = this.truncatePipe.transform(
      this.stripHtmlPipe.transform(this.post.excerpt || this.post.content),
      [200]
    )
  }
}

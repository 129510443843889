<div
  class="is-il"
  [ngClass]="{
    'container-full bg-primary p-t-xxl has-text-light': isDark
  }"
>
  <div class="container">
    <div class="columns">
      <ng-container>
        <div class="column is-12">
          <div class="flex align-center justify-center is-flex-direction-column-mobile align-start-mobile flex--newsletter">
            <div class="flex align-center justify-start mb-4-mobile">
              <img
                *ngIf="withAvatar"
                src="./assets/images/ai-wonk-dog.png"
                alt="AI Wonk Dog"
                class="avatar m-r-gutter"
              />

              <div
                i18n="@@newsletterFormCta"
                [ngClass]="{ 'title is-3 has-text-light': isDark, 'mr-4': !isDark}"
              >
                Receive the OECD's artificial intelligence newsletter!
              </div>
            </div>
            <div>
              <a
                [href]="newsletterLink"
                target="_blank"
                class="button is-link has-text-weight-bold has-text-white"
                >Sign up with Linkedin</a
              >
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

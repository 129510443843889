import { Injectable } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'

import { environment } from '../../../environments/environment'
import { metaDataContent } from '../../../locale/meta-data/meta-data.content.en'
import { MetaObject } from '../interfaces/meta-object.interface'

@Injectable({
  providedIn: 'root'
})

// This service sets META properties on pages.
export class MetaService {
  metaTitleSuffix = ' - OECD.AI'
  defaultMeta: MetaObject = metaDataContent.defaultMeta

  constructor(private title: Title, private meta: Meta) {
    this.defaultMeta.absoluteOgImage =
      environment.baseUrl + 'assets/images/default-og-ai.png'
  }

  setTags(meta: MetaObject): void {
    this.title.setTitle(
      meta.title + this.metaTitleSuffix || (this.defaultMeta.title as string)
    )
    this.meta.updateTag({
      name: 'description',
      content: meta.description || (this.defaultMeta.description as string)
    })
    this.meta.updateTag({
      name: 'og:description',
      content: meta.description || (this.defaultMeta.description as string)
    })
    this.meta.updateTag({
      name: 'og:title',
      content: meta.title || (this.defaultMeta.title as string)
    })
    this.meta.updateTag({
      name: 'og:url',
      content: environment.baseUrl + meta.path
    })
    this.meta.updateTag({
      name: 'og:image',
      content:
        meta.absoluteOgImage || (this.defaultMeta.absoluteOgImage as string)
    })
  }
}

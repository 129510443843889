import { NgClass, NgIf } from '@angular/common'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-newsletter-form',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf]
})
export class NewsletterFormComponent {
  @Input() withAvatar = false
  @Input() isDark: boolean

  newsletterLink =
    'https://www.linkedin.com/newsletters/oecd-ai-newsletter-7133112175430111232/'
}

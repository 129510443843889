import { Injectable } from '@angular/core'

import { Params } from '@angular/router'
import { ResourceSlug } from '../enums/wordpress/wp-resource-slug.enum'
import { WorkingGroup } from '../interfaces/resources/working-group.interface'
import { WPPost } from '../interfaces/wordpress/wp-post.interface'
import { BlogResourceService } from './blog-resource.service'
import { FormatResourceService } from './format-resource.service'

@Injectable({
  providedIn: 'root'
})
export class WorkingGroupService {
  constructor(
    private blogResourceService: BlogResourceService,
    private formatResourceService: FormatResourceService
  ) {}

  list(): Promise<WorkingGroup[]> {
    const params: Params = {
      withoutPagination: true
    }

    return (
      this.blogResourceService.list(
        ResourceSlug.WorkingGroups,
        params
      ) as Promise<WPPost[]>
    ).then((wpWorkingGroupRes: WPPost[]) =>
      wpWorkingGroupRes.map((p) =>
        this.formatResourceService.formatWorkingGroup(p)
      )
    )
  }

  show(id: number): Promise<WorkingGroup> {
    return this.blogResourceService
      .show(ResourceSlug.WorkingGroups, id)
      .then((wpWorkingGroupRes: WPPost) =>
        this.formatResourceService.formatWorkingGroup(wpWorkingGroupRes)
      )
  }

  showBySlug(slug: string): Promise<WorkingGroup> {
    return this.blogResourceService
      .showBySlug(ResourceSlug.WorkingGroups, slug)
      .then((wpWorkingGroupRes: WPPost) =>
        this.formatResourceService.formatWorkingGroup(wpWorkingGroupRes)
      )
  }
}

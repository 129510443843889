<app-newsletter-form *ngIf="!isHome" [isDark]="true"></app-newsletter-form>

<footer class="footer">
  <div class="container">
    <div class="columns">
      <div class="column content footer__content">
        <img
          src="./assets/images/logo-oecd-light.svg"
          alt="OECD Logo"
          width="224"
        />

        <ul class="xsmall-meta footer__links">
          <li>
            <span i18n="@@footerCopyright"
              >© {{ currentYear }} OECD. All rights reserved</span
            >
          </li>
          <li>
            <a i18n="@@footerHome" class="has-text-light no-deco" routerLink="/"
              >Home</a
            >
          </li>
          <li>
            <a
              class="has-text-light no-deco"
              href="https://www.oecd.org/termsandconditions/"
              target="_blank"
              i18n="@@footerTermsAndConditions"
            >
              Terms & conditions
            </a>
          </li>
          <li>
            <a
              class="has-text-light no-deco"
              href="https://www.oecd.org/privacy/"
              target="_blank"
              i18n="@@footerPrivacyPolicy"
            >
              Privacy policy
            </a>
          </li>
        </ul>
        <!-- Social networks -->
        <ul class="xsmall-meta m-b-gutter flex align-center social-links">
          <li class="m-a-0">
            <span i18n="@@footerSocialMediaBacklinks">Follow us on:</span>
          </li>

          <li class="m-a-0">
            <a
              class="has-text-light no-deco"
              href="https://twitter.com/OECDinnovation"
              target="_blank"
            >
              <img src="./assets/images/twitter.svg" alt="Twitter logo" />
            </a>
          </li>
          <li class="m-a-0">
            <a
              class="has-text-light no-deco"
              href="https://www.facebook.com/theOECD/"
              target="_blank"
            >
              <img src="./assets/images/facebook.svg" alt="Facebook logo" />
            </a>
          </li>
          <li class="m-a-0">
            <a
              class="has-text-light no-deco"
              href="https://www.linkedin.com/company/oecd-ai"
              target="_blank"
            >
              <img src="./assets/images/linkedin.svg" alt="LinkedIn logo" />
            </a>
          </li>
          <li class="m-a-0 pr-0">
            <a
              class="has-text-light no-deco"
              href="https://wp.oecd.ai/feed/"
              target="_blank"
            >
              <img src="./assets/images/rss.svg" alt="RSS logo" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>

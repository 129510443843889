<div class="container-full bg-theme">
  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <div
          class="temporary-item temporary-breadcrumbs"
          *ngIf="!breadcrumbLinks"
        ></div>
        <app-breadcrumbs
          [darkTheme]="true"
          [breadcrumbLinks]="breadcrumbLinks"
          *ngIf="breadcrumbLinks"
        ></app-breadcrumbs>
        <ng-container *ngIf="page">
          <h1 class="title is-1 has-text-light">{{ page.title }}</h1>
          <div
            class="has-text-light m-y-gutter"
            [innerHTML]="page.excerpt"
          ></div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="container m-t-alt">
  <div class="columns">
    <div class="column">
      <!-- Content page start -->
      <div class="article-wrapper">
        <div
          class="flex flex-column align-start justify-start is-full-height article"
        >
          <div
            class="card has-shadow is-size-6"
            id="custom-tooltip"
            style="display: none"
          >
            <div id="custom-tooltip-html-wrapper"></div>
            <a class="tooltip__close-btn" id="close-button">
              <i class="icon icon-Closelarge"></i>
            </a>
          </div>
          <div class="full-width" [innerHTML]="pageContent"></div>
        </div>

        <ng-container
          *ngIf="
            page && page.relatedPosts && page.relatedPosts.length && !loading
          "
        >
          <div class="container-full bg-white-bis m-b-xl mt-4">
            <div class="container">
              <div class="columns p-t-md">
                <div class="column is-12">
                  <div class="flex flex-wrap align-center space-between">
                    <h2 i18n="page-detail-posts" class="m-b-0">
                      From the AI Wonk
                    </h2>
                  </div>
                </div>
              </div>
              <div class="columns flex flex-wrap p-b-md">
                <div
                  class="column is-12-mobile is-6-tablet is-4-desktop"
                  *ngFor="let post of page.relatedPosts"
                >
                  <app-post-card [post]="post"></app-post-card>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!page && loading">
          <div class="column is-12-mobile is-6-tablet is-4-desktop">
            <div class="lc lc--regular">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="column is-12-mobile is-6-tablet is-4-desktop">
            <div class="lc lc--regular">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div
            class="column is-12-mobile is-6-tablet is-4-desktop"
            *ngIf="loading"
          >
            <div class="lc lc--regular">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

import { Participant } from 'src/app/shared/interfaces/participant.interface'

export const additionalExperts: Participant[] = [
  {
    name: 'Mr. James Hodson',
    title: 'Member of the Board of Directors and CEO',
    organization: 'AI for Good foundation '
  },
  {
    name: 'Mr. Michael Witbrock ',
    title: 'Head, AI Foundations Lab - Reasoning ',
    organization: 'IBM Research AI'
  },
  {
    name: 'Mr. John Shawe Taylor ',
    title: 'Head of Computer Science department at UCL and UNESCO AI Chair',
    organization: 'UCL (University College London)'
  },
  {
    name: 'Mr. Abe Hsuan ',
    title: 'IT & IP lawyer',
    organization: ''
  },
  {
    name: 'Mr. Ali G Hessami ',
    title: 'Chair and Tech Editor, IEEE P7000 Tech-Ethics Standard',
    organization: 'IEEE'
  },
  {
    name: 'Mr. Wael Diab ',
    title: 'Chair SC 42 (Artificial Intelligence) ',
    organization: 'ISO'
  },
  {
    name: 'Mr. Grigory Marshalko ',
    title:
      'Expert of the Technical committee for standardization "Cryptography and security mechanisms", "IT security techniques", and "AI"',
    organization: 'ISO'
  },
  {
    name: 'Ms. Ingrid Volkmer',
    title: 'Professor and Head, Media and Communications Program',
    organization: 'University of Melbourne'
  }
]

import { Injectable } from '@angular/core'
import { ResourceSlug } from '../enums/wordpress/wp-resource-slug.enum'

import { AIResource } from '../interfaces/resources/ai-resource.interface'
import { WPAIResource } from '../interfaces/wordpress/wp-ai-resource.interface'
import { BlogResourceService } from './blog-resource.service'
import { FormatResourceService } from './format-resource.service'

@Injectable({
  providedIn: 'root'
})
export class AiResourceService {
  constructor(
    private blogResourceService: BlogResourceService,
    private formatResourceService: FormatResourceService
  ) {}

  list(): Promise<AIResource[]> {
    return (
      this.blogResourceService.list(ResourceSlug.Resources, {
        withoutPagination: true,
        per_page: 100
      }) as Promise<WPAIResource[]>
    ).then((wpAiResourceRes: WPAIResource[]) =>
      wpAiResourceRes.map((wpAiResource) =>
        this.formatResourceService.formatAIResource(wpAiResource)
      )
    )
  }
}

<div class="columns" *ngIf="paginator.lastPage > 1">
  <div class="column">
    <hr />
    <nav
      class="pagination is-centered is-rounded"
      role="navigation"
      aria-label="pagination"
    >
      <ul class="pagination-list">
        <li>
          <a
            class="pagination-link"
            (click)="goToPage(pageArray[0])"
            (keydown)="goToPage(pageArray[0])"
            tabindex="0"
            *ngIf="pageArray[0] > 1"
            >1</a
          >
        </li>
        <li>
          <span class="pagination-ellipsis" *ngIf="pageArray[0] > 1"
            >&hellip;</span
          >
        </li>
        <li>
          <a
            class="pagination-link"
            (click)="goToPage(pageNumber)"
            (keydown)="goToPage(pageNumber)"
            tabindex="0"
            *ngFor="let pageNumber of pageArray"
            [ngClass]="{
              'is-current has-text-light': paginator.currentPage === pageNumber
            }"
            >{{ pageNumber }}</a
          >
        </li>
        <li>
          <span
            class="pagination-ellipsis"
            *ngIf="pageArray[pageArray.length - 1] < paginator.lastPage"
            >&hellip;</span
          >
        </li>
        <li>
          <a
            class="pagination-link"
            (click)="goToPage(paginator.lastPage)"
            (keydown)="goToPage(paginator.lastPage)"
            tabindex="0"
            *ngIf="pageArray[pageArray.length - 1] < paginator.lastPage"
            >{{ paginator.lastPage }}</a
          >
        </li>
      </ul>
    </nav>
  </div>
</div>

import { Injectable } from '@angular/core'

import { Faq } from '../interfaces/faq.interface'
import { WPCategory } from '../interfaces/wordpress/wp-category.interface'
import { WPPage } from '../interfaces/wordpress/wp-page.interface'
import { StripHtmlPipe } from '../pipes/strip-html.pipe'
import { BlogResourceService } from './blog-resource.service'

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  constructor(
    private blogResourceService: BlogResourceService,
    private stripHtmlPipe: StripHtmlPipe
  ) {}

  async list(params: {
    faqCategorySlug?: string
    faqCategorySlugNotIn?: string
  }): Promise<Faq[]> {
    const requestParams = {
      withoutPagination: true
    }

    if (params.faqCategorySlug) {
      const faqCategory: WPCategory = await this.blogResourceService
        .showBySlug('faq-categories', params.faqCategorySlug)
        .then((faqCategoryRes: WPCategory) => faqCategoryRes)

      requestParams['faq-categories'] = faqCategory.id
    }

    if (params.faqCategorySlugNotIn) {
      const faqCategory: WPCategory = await this.blogResourceService
        .showBySlug('faq-categories', params.faqCategorySlugNotIn)
        .then((faqCategoryRes: WPCategory) => faqCategoryRes)
      requestParams['faq-categories_exclude'] = faqCategory.id
    }

    return (
      this.blogResourceService.list('faqs', requestParams) as Promise<WPPage[]>
    ).then((wpFAQRes: WPPage[]) =>
      wpFAQRes.map((wpFaq) => this.formatFAQ(wpFaq))
    )
  }

  formatFAQ(wpFaq: WPPage): Faq {
    return {
      label: this.stripHtmlPipe.transform(wpFaq.title.rendered),
      content: this.stripHtmlPipe.transform(wpFaq.content.rendered)
    }
  }
}

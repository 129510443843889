import { BreadcrumbLink } from '../../app/shared/interfaces/breadcrumb-link.interface'

export const appBreadcrumbLinks: { [key: string]: BreadcrumbLink[] } = {
  // About dropdown.
  about: [{ label: 'About' }],

  networkOfExperts: [
    { label: 'OECD AI expert network', path: 'network-of-experts' }
  ],
  partners: [{ label: 'Partners', path: 'partners' }],
  resources: [{ label: 'Resources', path: 'resources' }],
  FAQ: [{ label: 'FAQ', path: 'faq' }],
  videos: [{ label: 'Videos', path: 'videos' }],
  survey: [{ label: 'Survey', path: 'survey' }],
  classificationSurvey: [
    {
      label: 'Survey for the classification of AI Systems',
      path: 'classificationsurvey'
    }
  ],

  // AI Principles dropdown.
  principleList: [
    { label: 'OECD AI Principles overview', path: 'ai-principles' }
  ],
  principleDetail: [{ label: 'OECD AI Principles', path: 'ai-principles' }],

  // AI Tools Catalogue dropdown.
  toolCatalog: [{ label: 'Tools & metrics', path: '/catalogue/overview' }],

  // Policy areas dropdown.
  policyAreaList: [{ label: 'Policy areas overview', path: 'policy-areas' }],
  policyAreaDetail: [{ label: 'Policy areas', path: 'policy-areas' }],

  // Trends and data dropdown.
  trendsAndData: [{ label: 'Trends & data overview', path: 'trends-and-data' }],
  OECDMetricsAndMethods: [
    { label: 'OECD metrics & methods', path: 'oecd-metrics-and-methods' }
  ],
  liveDataFromPartners: [{ label: 'Live data', path: 'data' }],

  // Countries and initiatives dropdown.

  mainDashboard: [
    {
      label: 'National strategies & policies',
      path: 'dashboards/overview'
    }
  ],
  search: [
    {
      label: 'Search',
      path: 'search'
    }
  ],

  // Blog.
  blogIndex: [{ label: 'The AI Wonk', path: 'wonk' }],
  blogAuthors: [{ label: 'AI community', path: 'community' }],

  // Futures.
  futures: [
    {
      label: 'Priority issues'
    },
    {
      label: 'AI Futures',
      path: 'site/ai-futures'
    }
  ],
  // AI Index.
  aiIndex: [
    {
      label: 'Priority issues'
    },
    {
      label: 'AI Index',
      path: 'site/ai-index'
    }
  ],

  // Compute and climate.
  computeClimate: [
    {
      label: 'Priority issues'
    },
    {
      label: 'Compute & Climate Change',
      path: 'site/compute-climate'
    }
  ],

  // Risk and accountability.

  riskAccountability: [
    {
      label: 'Priority issues'
    },
    {
      label: 'Risk & Accountability',
      path: 'site/risk-accountability'
    }
  ],

  // Incidents.
  incidents: [
    {
      label: 'Priority issues'
    },
    {
      label: 'Incidents',
      path: 'site/incidents'
    }
  ],

  // Data & Privacy.
  dataPrivacy: [
    {
      label: 'Priority issues'
    },
    {
      label: 'Data & Privacy',
      path: 'site/data-privacy'
    }
  ],
  // Health.
  health: [
    {
      label: 'Priority issues'
    },
    {
      label: 'Health',
      path: 'site/health'
    }
  ],

  // Generative AI.
  generativeAI: [
    {
      label: 'Generative AI',
      path: 'generative-ai'
    }
  ],

  // Policy initiatives.
  policyInitiatives: [
    {
      label: 'Policy initiatives',
      path: 'policy-initiatives'
    }
  ],

  // AI Publications.
  aiPublications: [{ label: 'Publications', path: 'ai-publications' }],

  // Pages
  pages: [{ label: 'Pages', path: '' }],
  wips: [
    {
      label: 'Work, Innovation, Productivity and Skills programme',
      path: '/work-innovation-productivity-skills/'
    }
  ],

  // Incidents monitor
  incidentsMonitor: [{ label: 'AI Incidents Monitor', path: '/incidents' }],

  // AI Recap
  aiRecap: [
    {
      label: 'AI Recap',
      path: 'air'
    }
  ],

  // Other
  events: [{ label: 'Events', path: 'events' }],
  contact: [{ label: 'Contact', path: 'contact' }],
  error404: [{ label: 'Error 404 : Page not found', path: '404' }]
}

// Home breadcrumb link is the root of all others.
export const homeBreadcrumbLink: BreadcrumbLink = {
  label: 'Home',
  path: '/'
}

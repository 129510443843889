<!-- Header -->
<section class="hero is-default has-background-primary">
  <svg
    width="671"
    height="460"
    viewBox="0 0 671 460"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6" filter="url(#filter0_f_904_1408)">
      <ellipse
        cx="43.9024"
        cy="554.483"
        rx="229.532"
        ry="173.159"
        transform="rotate(15 43.9024 554.483)"
        fill="#75E2C9"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_904_1408"
        x="-965.554"
        y="-53.1094"
        width="1635.7"
        height="1423.49"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="200"
          result="effect1_foregroundBlur_904_1408"
        />
      </filter>
    </defs>
  </svg>

  <div class="hero-body p-y-gutter">
    <div class="container-full">
      <div class="container">
        <div class="columns">
          <div class="column is-12 has-text-light">
            <h1 i18n="@@homeTitle" class="title is-1 m-y-gutter has-text-light">
              Policies, data and analysis for trustworthy artificial
              intelligence
            </h1>
            <p class="has-text-light m-y-gutter">
              In July 2024, the GPAI initiative and OECD member countries’ work on AI joined forces under the GPAI brand
              to create an integrated partnership.
              <a
                class="has-text-white"
                href="https://oecd.ai/en/about/what-we-do"
                target="_blank"
              >Find out more</a
              >.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Main content -->
<section>
  <div class="container m-t-alt">
    <!-- FeaturedCards -->
    <div class="columns">
      <div class="column is-12">
        <h2 class="title is-3 mb-0">Featured</h2>
      </div>
    </div>

    <app-featured-card-list
      [loading]="loadingFeaturedCards"
      [featuredCards]="featuredCards"
    ></app-featured-card-list>
  </div>
</section>
<!-- Post list-->
<div class="container">
  <!-- Post list-->
  <div class="columns">
    <div class="column is-12">
      <app-post-carousel [posts]="posts"></app-post-carousel>
    </div>
  </div>
  <!-- Priority issues -->
  <app-priority-issue-carousel></app-priority-issue-carousel>
  <!--  Other links section -->
  <div class="columns mt-5 mb-6">
    <div class="column is-6">
      <div class="card is-borderless card--aim p-a-xxl is-full-height">
        <div
          class="is-flex is-flex-direction-column is-align-items-flex-start is-justify-content-space-between is-full-height"
        >
          <div class="m-b-giant p-b-giant">
            <div
              class="is-flex is-align-items-flex-start is-justify-content-flex-start mt-0 m-b-colgap"
            >
              <figure class="logo-aim mr-4">
                <img
                  src="./assets/images/aim-black.svg"
                  alt="aim-logo"
                  title="aim"
                  width="24"
                />
              </figure>
              <h3 class="title is-4 my-0">OECD AI Incidents Monitor (AIM)</h3>
            </div>
            <p>
              AIM tracks AI incidents in the global press for valuable insights
              into existing AI risks.
            </p>
          </div>
          <a routerLink="/incidents" class="button is-link m-t-giant">
            <span>Explore AIM</span>
            <i class="fas fa-arrow-right ml-2"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="column is-6">
      <div class="card is-borderless card--catalog p-a-xxl is-full-height">
        <div
          class="is-flex is-flex-direction-column is-align-items-flex-start is-justify-content-space-between is-full-height"
        >
          <div class="m-b-giant p-b-giant">
            <h3 class="title is-4">
              OECD Catalogue of Tools & Metrics for Trustworthy AI
            </h3>
            <p>
              To help AI actors develop and use trustworthy AI systems and
              applications that respect human rights and are fair, transparent,
              explainable, robust, secure and safe.
            </p>
          </div>
          <a routerLink="/catalogue/overview" class="button is-link m-t-giant">
            <span>Visit the Catalogue</span>
            <i class="fas fa-arrow-right ml-2"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Video modal -->
<div class="modal modal-large is-active" *ngIf="showVideoModal">
  <div class="modal-background"></div>
  <div class="modal-content">
    <video
      id="video-player"
      width="100%"
      height="500"
      style="height: 500px"
      controls
      autoplay
    >
      <source [src]="videoSourceMP4" type="video/mp4" />

      <source [src]="videoSourceWEBM" type="video/webm" />
      Your browser does not support the video tag.
    </video>
  </div>
</div>

import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation
} from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'

import { environment } from '../environments/environment'
import { appConstants } from './app.constants'
import { EventService } from './shared/services/event.service'
import { ViewportService } from './shared/services/viewport.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['styles/main.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  @ViewChild('unlockInput', { static: false }) private unlockInput: ElementRef
  isTouchResolution = false
  showBrowserRequestMessage: boolean
  noIndex = environment.noIndex

  // On noIndex versions, we ask for a password to unlock the page.
  locked = environment.noIndex
  impossibleToCrackPassword = 'aiTeam100%'

  untranslatedSections = ['catalogue', 'community', 'wonk', 'dashboards']

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private readonly document: Document,
    private router: Router,
    private meta: Meta,
    private eventService: EventService,
    private viewportService: ViewportService,
    private activatedRoute: ActivatedRoute
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.isTouchResolution = window.innerWidth < appConstants.TOUCH_BREAKPOINT
      this.viewportService.isTouchResolution.next(this.isTouchResolution)
      this.viewportService.isMobileResolution.next(
        window.innerWidth < appConstants.MOBILE_BREAKPOINT
      )
    }
  }

  ngOnInit() {
    if (this.noIndex) {
      this.meta.addTag({ name: 'robots', content: 'NOINDEX, NOFOLLOW' })
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.eventService.routeChanged.next({ url: event.url })

        const urlWithoutQuery: string = event.url.split('?')[0]
        const isScrollEvent: boolean = urlWithoutQuery === '/wonk' || urlWithoutQuery === '/data'

        if (isPlatformBrowser(this.platformId) && !isScrollEvent) {
          if (window.scrollY > 0) {
            setTimeout(() => window.scrollTo(0, 0), 200)
          }
        }

        if (
          environment.lang !== 'en' &&
          this.untranslatedSections.includes(event.url.split('/')[1])
        ) {
          // Some sections are not translated in French, so we redirect to the English version.
          this.setCanonicalUrl(
            environment.baseUrl.replace('/fr/', '/en') + event.url
          )
        } else {
          this.setCanonicalUrl(
            environment.baseUrl +
            this.removeQueryParams(event.url.replace(/^\//, '')) // Remove first slash.
          )
        }
      }
    })

    this.viewportService.isTouchResolution.subscribe((newValue) => {
      this.isTouchResolution = newValue
    })

    // Show browser request message for 5 seconds after loading.
    if (isPlatformBrowser(this.platformId)) {
      this.showBrowserRequestMessage = true
      setTimeout(() => (this.showBrowserRequestMessage = false), 5000)
    }
  }

  setCanonicalUrl(url: string): void {
    const head = this.document.getElementsByTagName('head')[0]
    let element: HTMLLinkElement =
      this.document.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element = this.document.createElement('link') as HTMLLinkElement
      head.appendChild(element)
    }
    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', url)
  }

  clearCanonicalUrl(): void {
    const element: HTMLLinkElement =
      this.document.querySelector(`link[rel='canonical']`) || null
    if (element) {
      element.remove()
    }
  }

  unlock() {
    if (
      this.unlockInput.nativeElement.value === this.impossibleToCrackPassword
    ) {
      this.locked = false
    } else {
      alert('Wrong password!')
    }
  }

  /**
   * Remove query params from url string.
   *
   * @param urlString
   * @returns urlString without query params.
   */
  private removeQueryParams(urlString: string): string {
    const questionMarkIndex = urlString.indexOf('?')
    if (questionMarkIndex !== -1) {
      return urlString.slice(0, questionMarkIndex)
    }
    return urlString
  }

  @HostListener('window:resize')
  onResize() {
    if (isPlatformBrowser(this.platformId)) {
      this.viewportService.isTouchResolution.next(
        window.innerWidth < appConstants.TOUCH_BREAKPOINT
      )
      this.viewportService.isMobileResolution.next(
        window.innerWidth < appConstants.MOBILE_BREAKPOINT
      )
    }
  }
}

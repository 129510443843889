import { isPlatformBrowser } from '@angular/common'
import { Inject, Pipe, PipeTransform, PLATFORM_ID } from '@angular/core'

@Pipe({
  name: 'stripHtml',
  standalone: true
})
export class StripHtmlPipe implements PipeTransform {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  transform(value: string): string {
    if (!value || !value.length) {
      return ''
    }

    let output: string

    // Parse HTML Special chars (clientApp only as it uses "document")
    if (isPlatformBrowser(this.platformId)) {
      const txt = document.createElement('textarea')
      txt.innerHTML = value
      output = txt.value
    } else {
      output = value
    }

    // Strip tags.
    return output.replace(/<.*?>/g, '').replace(/&/g, '&').replace(/#038;/g, '')
  }
}

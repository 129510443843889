import { Injectable } from '@angular/core'

import { ResourceSlug } from '../enums/wordpress/wp-resource-slug.enum'
import { QuickLink } from '../interfaces/quick-link'
import { WPQuickLink } from '../interfaces/wordpress/wp-quick-link'
import { BlogResourceService } from './blog-resource.service'
import { FormatResourceService } from './format-resource.service'

@Injectable({
  providedIn: 'root'
})
export class QuickLinkService {
  constructor(
    private formatResourceService: FormatResourceService,
    private blogResourceService: BlogResourceService
  ) {}

  list() {
    return (
      this.blogResourceService.list(ResourceSlug.QuickLinks, {
        withoutPagination: true
      }) as Promise<any[]>
    ).then((wpQuickLinkRes: WPQuickLink[]) =>
      wpQuickLinkRes.map((WPQuickLink) =>
        this.formatResourceService.formatQuickLink(WPQuickLink)
      )
    )
  }

  listGroups() {
    return this.blogResourceService.list(ResourceSlug.QuickLinkGroups, {
      withoutPagination: true
    }) as Promise<any[]>
  }

  async getQuickLinksGroupsByParentGroupSlug(
    parentGroupSlug: string
  ): Promise<any[]> {
    const quickLinks: QuickLink[] = await this.list()

    let groups = quickLinks.reduce((acc, quickLink) => {
      const group = acc.find((g) => g.id === quickLink.group?.id)
      if (group) {
        group.quickLinks.push(quickLink)
      } else if (quickLink.group?.id) {
        acc.push({
          id: quickLink.group.id,
          name: quickLink.group.name,
          quickLinks: [quickLink]
        })
      }
      return acc
    }, [])

    const groupRes = await this.listGroups()
    const parentGroup = groupRes.find((group) => group.slug == parentGroupSlug)

    if (parentGroup) {
      const childrenGroupIds = groupRes
        .filter((group) => group.parent === parentGroup.id)
        .map((group) => group.id)

      groups = groups.filter((group) => childrenGroupIds.includes(group.id))
    } else {
      groups = []
    }
    return groups
  }
}

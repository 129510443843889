import { Component, OnInit } from '@angular/core'
import { StripHtmlPipe } from 'src/app/shared/pipes/strip-html.pipe'
import { BreadcrumbLink } from '../../shared/interfaces/breadcrumb-link.interface'
import { Page } from '../../shared/interfaces/page.interface'
import { Area } from '../../shared/interfaces/resources/area.interface'
import { AreaService } from '../../shared/services/area.service'
import { BreadcrumbService } from '../../shared/services/breadcrumb.service'
import { MetaService } from '../../shared/services/meta.service'
import { PageService } from '../../shared/services/page-service/page.service'

@Component({
  selector: 'app-trends-and-data',
  templateUrl: './trends-and-data.component.html',
  styleUrls: ['./trends-and-data.component.scss']
})
export class TrendsAndDataComponent implements OnInit {
  breadcrumbLinks: BreadcrumbLink[]
  trendsAndDataContent: Page
  areas: Area[]

  constructor(
    breadcrumbService: BreadcrumbService,
    private metaService: MetaService,
    private stripHtmlPipe: StripHtmlPipe,
    private areaService: AreaService,
    private pageService: PageService
  ) {
    this.breadcrumbLinks = breadcrumbService.getBreadCrumbLinks('trendsAndData')
  }

  async ngOnInit() {
    this.trendsAndDataContent = await this.pageService.show(
      'trends-data-overview'
    )
    this.areaService.list().then((areas: Area[]) => {
      const trendsAndDataArea: Area = areas.find(
        (a) => a.slug === 'trends-data'
      )

      if (trendsAndDataArea) {
        this.areas = areas
          .filter((a) => a.parent === trendsAndDataArea.id)
          .sort((a, b) => {
            return b.sortOrder - a.sortOrder
          })
      }
    })

    this.metaService.setTags({
      title:
        this.trendsAndDataContent.metaTitle || this.trendsAndDataContent.title,
      description: this.stripHtmlPipe.transform(
        this.trendsAndDataContent.metaDescription ||
          this.trendsAndDataContent.excerpt
      ),
      path: 'trends-and-data',
      absoluteOgImage: this.trendsAndDataContent.image
        ? this.trendsAndDataContent.image.socialCard ||
          this.trendsAndDataContent.image.landscape ||
          this.trendsAndDataContent.image.videoCard
        : this.metaService.defaultMeta.absoluteOgImage
    })
  }
}

import { isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { Subject } from 'rxjs'

import { appConstants } from '../../app.constants'

@Injectable({
  providedIn: 'root'
})
export class FlashMessageService {
  public flashMessage: Subject<{
    message: string
    className: string
  }> = new Subject()

  timeout: number
  defaultTimeout: 6000

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    this.timeout = appConstants.FLASH_MESSAGE_TIMEOUT
  }

  success(message: string, sticky = false) {
    if (isPlatformBrowser(this.platformId)) {
      this.flashMessage.next({ message, className: 'notification is-success' })
      setTimeout(
        () => {
          this.flashMessage.next(null)
        },
        sticky ? 9999999 : this.timeout
      )
    }
  }

  error(message: string, sticky = false) {
    if (isPlatformBrowser(this.platformId)) {
      this.flashMessage.next({ message, className: 'notification is-danger' })
      setTimeout(
        () => {
          this.flashMessage.next(null)
        },
        sticky ? 9999999 : this.timeout
      )
    }
  }

  info(message: string, sticky = false) {
    if (isPlatformBrowser(this.platformId)) {
      this.flashMessage.next({ message, className: 'notification is-info' })
      setTimeout(
        () => {
          this.flashMessage.next(null)
        },
        sticky ? 9999999 : this.timeout
      )
    }
  }
}

<!-- Header -->
<div class="container-full head">
  <div class="container">
    <div class="columns">
      <div class="column is-12 is-12-mobile">
        <div
          class="temporary-item temporary-breadcrumbs"
          *ngIf="!breadcrumbLinks"
        ></div>
        <app-breadcrumbs
          [darkTheme]="true"
          [breadcrumbLinks]="breadcrumbLinks"
          *ngIf="breadcrumbLinks"
        ></app-breadcrumbs>
      </div>
    </div>

    <div class="columns">
      <div class="column is-12 is-12-mobile">
        <div class="card card--author bg-transparent">
          <div class="temporary-item temporary-avatar" *ngIf="loading"></div>
          <div class="flex align-center justify-start">
            <figure class="avatar is-large m-r-xl m-y-md" *ngIf="author">
              <img
                [src]="author.image.squareLarge"
                [alt]="'photo of ' + author.name"
                *ngIf="author.image"
              />

              <img
                src="/assets/images/default-author.svg"
                [alt]="'photo of ' + author.name"
                *ngIf="!author.image"
              />
            </figure>
            <div>
              <div
                class="temporary-item temporary-author"
                *ngIf="loading"
              ></div>
              <h1 class="title is-1 has-text-light" *ngIf="author">
                {{ author.name }}
              </h1>
              <div class="temporary-item temporary-desc" *ngIf="loading"></div>
              <h2
                class="title is-2 has-text-light m-b-md p-b-xs"
                *ngIf="author"
              >
                {{ author.position }}
                <span *ngIf="!loading"> - </span>
                {{ author.institution }}
              </h2>

              <div class="temporary-item temporary-desc" *ngIf="loading"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="columns">
    <div class="column p-b-0 col-author">
      <div class="field is-grouped is-grouped-multiline">
        <ng-container *ngIf="author?.workingGroups.length">
          <div
            class="control mt-6"
            *ngFor="let workingGroup of author.workingGroups"
          >
            <div class="tags has-addons">
              <span class="tag is-info">Working group</span>
              <span class="tag is-white-bis">{{ workingGroup.name }}</span>
            </div>
          </div>
        </ng-container>

        <div class="control mt-6" *ngIf="author?.stakeholderType">
          <div class="tags has-addons">
            <span class="tag is-info">Stakeholder Type</span>
            <span class="tag is-white-bis">{{
              author.stakeholderType.name
            }}</span>
          </div>
        </div>
        <ng-container *ngIf="author?.countries">
          <div class="control mt-6" *ngFor="let country of author.countries">
            <div class="tags has-addons">
              <span class="tag is-info">Country</span>
              <span class="tag is-white-bis">{{ country.name }}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="author?.formerMember">
          <div class="control mt-6">
            <div class="tags has-addons">
              <span class="tag is-info">Former ONE AI participant </span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="author?.gPAI">
          <div class="control mt-6">
            <div class="tags has-addons">
              <span class="tag is-info">GPAI</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="author?.secretariat">
          <div class="control mt-6">
            <div class="tags has-addons">
              <span class="tag is-info">OECD Secretariat </span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="author?.observer">
          <div class="control mt-6">
            <div class="tags has-addons">
              <span class="tag is-info">ONE AI Observer </span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="author?.member">
          <div class="control mt-6">
            <div class="tags has-addons">
              <span class="tag is-info">ONE AI Member </span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="author?.parliamentaryGroup">
          <div class="control mt-6">
            <div class="tags has-addons">
              <span class="tag is-info">OECD Parliamentary Group on AI </span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="posts?.length">
          <div class="control mt-6">
            <div class="tags has-addons">
              <span class="tag is-info">AI Wonk contributor </span>
            </div>
          </div>
        </ng-container>
      </div>

      <div *ngIf="author" [innerHTML]="author.bio"></div>
    </div>
  </div>
</div>

<!-- Post list -->
<div class="container m-t-alt" *ngIf="posts && posts.length">
  <div class="columns m-t-gutter">
    <div class="column is-12 is-12-mobile">
      <h3
        i18n="@@author_detailPstList"
        class="title is-3 m-b-md"
        *ngIf="author"
      >
        {{ author.name }}'s publications
      </h3>
    </div>
  </div>
  <div class="columns flex flex-wrap">
    <div
      class="column is-12 is-12-mobile is-6-tablet is-4-desktop"
      *ngFor="let post of posts"
    >
      <app-post-card [post]="post"></app-post-card>
    </div>
  </div>

  <div
    class="column is-12-mobile is-6-tablet is-4-desktop"
    *ngIf="loadingPosts"
  >
    <div class="lc lc--regular">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div
    class="column is-12-mobile is-6-tablet is-4-desktop"
    *ngIf="loadingPosts"
  >
    <div class="lc lc--regular">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div
    class="column is-12-mobile is-6-tablet is-4-desktop"
    *ngIf="loadingPosts"
  >
    <div class="lc lc--regular">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>

<!-- Vidéos list -->
<div class="container m-t-alt" *ngIf="videos && videos.length">
  <div class="columns m-t-gutter">
    <div class="column is-12 is-12-mobile">
      <h3 class="title is-3 m-b-md" *ngIf="author">
        {{ author.name }}'s videos
      </h3>
    </div>
  </div>
  <div class="columns flex flex-wrap">
    <div
      class="column is-12 is-12-mobile is-6-tablet is-4-desktop"
      *ngFor="let video of videos"
    >
      <app-video-card [video]="video" [showExcerpt]="true"></app-video-card>
    </div>
  </div>

  <div
    class="column is-12-mobile is-6-tablet is-4-desktop"
    *ngIf="loadingPosts"
  >
    <div class="lc lc--regular">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div
    class="column is-12-mobile is-6-tablet is-4-desktop"
    *ngIf="loadingPosts"
  >
    <div class="lc lc--regular">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div
    class="column is-12-mobile is-6-tablet is-4-desktop"
    *ngIf="loadingPosts"
  >
    <div class="lc lc--regular">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>

<!-- Documents list -->
<div class="container m-t-alt" *ngIf="documents && documents.length">
  <div class="columns m-t-gutter">
    <div class="column is-12 is-12-mobile">
      <h3 class="title is-3 m-b-md" *ngIf="author">
        {{ author.name }}'s documents
      </h3>
    </div>
  </div>
  <div class="columns flex flex-wrap">
    <div
      class="column is-12 is-12-mobile is-6-tablet is-4-desktop"
      *ngFor="let document of documents"
    >
      <app-document-card [document]="document"></app-document-card>
    </div>
  </div>

  <div
    class="column is-12-mobile is-6-tablet is-4-desktop"
    *ngIf="loadingPosts"
  >
    <div class="lc lc--regular">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div
    class="column is-12-mobile is-6-tablet is-4-desktop"
    *ngIf="loadingPosts"
  >
    <div class="lc lc--regular">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div
    class="column is-12-mobile is-6-tablet is-4-desktop"
    *ngIf="loadingPosts"
  >
    <div class="lc lc--regular">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
<div class="container m-t-gutter">
  <div class="columns">
    <div class="column is-12">
      <app-author-disclaimer></app-author-disclaimer>
    </div>
  </div>
</div>

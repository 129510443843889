import { NgClass, NgIf } from '@angular/common'
import { Component, HostListener, OnInit } from '@angular/core'

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf]
})
export class TermsComponent implements OnInit {
  selectedTab = 'AISystem'
  showExpandedWorldMapImageModal = false
  constructor() {}

  ngOnInit() {}
  @HostListener('document:click', ['$event.target'])
  onClick(target: any) {
    // We track clicks to close model if open and click outside

    if (
      (this.showExpandedWorldMapImageModal) &&
      target.className === 'modal-background'
    ) {
      this.showExpandedWorldMapImageModal = false
    }
  }
}

import { NgFor, NgIf } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { forkJoin } from 'rxjs'

import { ResourceSlug } from '../../../enums/wordpress/wp-resource-slug.enum'
import { Author } from '../../../interfaces/author.interface'
import { BreadcrumbLink } from '../../../interfaces/breadcrumb-link.interface'
import { Document } from '../../../interfaces/resources/document.interface'
import { Post } from '../../../interfaces/resources/post.interface'
import { TaxonomyTerm } from '../../../interfaces/taxonomy-term.interface'
import { Video } from '../../../interfaces/video.interface'
import { BreadcrumbsComponent } from '../../../partials/breadcrumbs/breadcrumbs.component'
import { StripHtmlPipe } from '../../../pipes/strip-html.pipe'
import { AuthorService } from '../../../services/author.service'
import { BlogResourceService } from '../../../services/blog-resource.service'
import { BreadcrumbService } from '../../../services/breadcrumb.service'
import { CountryService } from '../../../services/country.service'
import { DocumentService } from '../../../services/document.service'
import { MetaService } from '../../../services/meta.service'
import { PostService } from '../../../services/post.service'
import { VideoService } from '../../../services/video.service'
import { DocumentCardComponent } from '../../document/document-card/document-card.component'
import { PostCardComponent } from '../../post/post-card/post-card.component'
import { VideoCardComponent } from '../../video/video-card/video-card.component'
import { AuthorDisclaimerComponent } from '../author-disclaimer/author-disclaimer.component'

@Component({
  selector: 'app-author-detail',
  templateUrl: './author-detail.component.html',
  styleUrls: ['./author-detail.component.scss'],
  standalone: true,
  imports: [
    PostCardComponent,
    VideoCardComponent,
    BreadcrumbsComponent,
    DocumentCardComponent,
    AuthorDisclaimerComponent,
    NgFor,
    NgIf
  ]
})
export class AuthorDetailComponent implements OnInit {
  author: Author
  posts: Post[]
  videos: Video[]
  documents: Document[]

  breadcrumbLinks: BreadcrumbLink[]

  loading: boolean
  loadingPosts: boolean

  constructor(
    private authorService: AuthorService,
    private postService: PostService,
    private videoService: VideoService,
    private documentService: DocumentService,
    private countryService: CountryService,
    private blogResourceService: BlogResourceService,
    private stripHtmlPipe: StripHtmlPipe,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaService,
    private breadcrumbService: BreadcrumbService,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.loading = true
      this.authorService.show(params.authorSlug).then(
        (authorRes: Author) => {
          this.loading = false
          this.author = authorRes

          if (this.author.countryIds && this.author.countryIds.length) {
            this.countryService
              .listByIds(this.author.countryIds)
              .then((countriesRes: TaxonomyTerm[]) => {
                this.author.countries = countriesRes
              })
          }

          if (this.author.stakeholderTypeId) {
            this.blogResourceService
              .show(
                ResourceSlug.StakeholderTypes,
                this.author.stakeholderTypeId
              )
              .then((stakeholderTypeRes: TaxonomyTerm) => {
                this.author.stakeholderType = stakeholderTypeRes
              })
          }

          // Get posts where Author is either main author of the post or co-author.
          forkJoin([
            this.postService.listUnpaginated({ authorId: this.author.id }),
            this.postService.listUnpaginated({ coAuthorId: this.author.id })
          ]).subscribe(([postRes, coAuthorPostRes]: [Post[], Post[]]) => {
            this.posts = postRes.concat(coAuthorPostRes)
          })

          // Get videos where Author is either main author of the post or co-author.

          forkJoin([
            this.videoService.list({
              authorId: this.author.id,
              withoutPagination: true
            }) as Promise<Video[]>,
            this.videoService.list({
              coAuthorId: this.author.id,
              withoutPagination: true
            }) as Promise<Video[]>
          ]).subscribe(([videoRes, coAuthorVideoRes]: [Video[], Video[]]) => {
            this.videos = videoRes.concat(coAuthorVideoRes)
          })

          // Get documents where Author is either main author of the post or co-author.
          forkJoin([
            this.documentService.list({
              authorId: this.author.id
            }),
            this.documentService.list({
              coAuthorId: this.author.id
            })
          ]).subscribe(([docRes, coAuthorDocRes]: [Document[], Document[]]) => {
            this.documents = docRes.concat(coAuthorDocRes)
          })

          this.breadcrumbLinks = this.breadcrumbService.getBreadCrumbLinks(
            'blogAuthors',
            [
              {
                label: this.author.name,
                path: `community/${this.author.slug}`
              }
            ]
          )

          this.metaService.setTags({
            title: this.author.name + ' : AI Expert',
            description: this.stripHtmlPipe.transform(this.author.bio),
            path: `community/${this.author.slug}`,
            absoluteOgImage: this.author.image
              ? (this.author.image['social-card'] as string)
              : null
          })
        },
        () => {
          this.router.navigate(['/404'])
        }
      )
    })
  }
}

import { Component } from '@angular/core'
import { metaDataContent } from '../../../locale/meta-data/meta-data.content.en'
import { BreadcrumbLink } from '../../shared/interfaces/breadcrumb-link.interface'
import { BreadcrumbService } from '../../shared/services/breadcrumb.service'
import { MetaService } from '../../shared/services/meta.service'

@Component({
  selector: 'app-classification-survey',
  templateUrl: './classification-survey.component.html',
  styleUrls: ['./classification-survey.component.scss']
})
export class ClassificationSurveyComponent {
  breadcrumbLinks: BreadcrumbLink[]
  constructor(breadcrumbService: BreadcrumbService, metaService: MetaService) {
    this.breadcrumbLinks = breadcrumbService.getBreadCrumbLinks(
      'classificationSurvey'
    )
    metaService.setTags(metaDataContent.classificationSurvey)
  }
}

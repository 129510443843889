import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'highlight',
  standalone: true
})
export class HighlightPipe implements PipeTransform {
  transform(value: any, args: any): any {
    if (!args) {
      return value
    }

    const newValue = value.replace(
      new RegExp(args, 'gi'),
      (match) =>
        '<span class="has-text-link has-text-weight-bold">' + match + '</span>'
    )
    return newValue
  }
}

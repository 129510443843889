import { isPlatformBrowser } from '@angular/common'
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2
} from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { ActivatedRoute, Params, Router } from '@angular/router'

import { BreadcrumbLink } from '../../../shared/interfaces/breadcrumb-link.interface'
import { Page } from '../../../shared/interfaces/page.interface'
import { Post } from '../../../shared/interfaces/resources/post.interface'
import { StripHtmlPipe } from '../../../shared/pipes/strip-html.pipe'
import { BreadcrumbService } from '../../../shared/services/breadcrumb.service'
import { DomContentService } from '../../../shared/services/dom-content.service'
import { MetaService } from '../../../shared/services/meta.service'
import { PageService } from '../../../shared/services/page-service/page.service'
import { PostService } from '../../../shared/services/post.service'

@Component({
  selector: 'app-page-detail',
  templateUrl: './page-detail.component.html',
  styleUrls: ['./page-detail.component.scss']
})
export class PageDetailComponent implements OnInit {
  page: Page
  pageContent: SafeHtml
  breadcrumbLinks: BreadcrumbLink[]

  showCustomTooltip: boolean
  loading: boolean

  constructor(
    private pageService: PageService,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaService,
    private stripHtmlPipe: StripHtmlPipe,
    private breadcrumbService: BreadcrumbService,
    private postService: PostService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private renderer: Renderer2,
    private domContentService: DomContentService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.loading = true
      this.pageService.show(params.slug).then(
        (res: Page) => {
          this.loading = false
          this.page = res

          if (this.page.relatedPostTagIds) {
            this.postService
              .listUnpaginated({
                tagId: this.page.relatedPostTagIds[0].toString()
              })
              .then((postRes: Post[]) => {
                this.page.relatedPosts = postRes
              })
          }

          this.pageContent = this.sanitizer.bypassSecurityTrustHtml(
            this.page.content
          )

          // Meta and breadcrumbs.
          this.metaService.setTags({
            title: this.page.metaTitle || this.page.title,
            description: this.stripHtmlPipe.transform(
              this.page.metaDescription || this.page.excerpt
            ),
            path: this.page.slug,
            absoluteOgImage: this.page.image
              ? this.page.image.socialCard
              : this.metaService.defaultMeta.absoluteOgImage
          })

          this.breadcrumbLinks = this.breadcrumbService.getBreadCrumbLinks(
            'pages',
            [
              {
                label: this.page.title
              }
            ]
          )

          // Custom tooltips.
          if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
              this.domContentService.enableTooltips(this.renderer)
              this.domContentService.enableAccordions()
            }, 0)
          }
        },
        () => {
          this.router.navigate(['/404'])
        }
      )
    })
  }
}

export enum ChartType {
  AsterPlot = 'asterPlot',
  BubbleChart = 'bubbleChart',
  BubbleMatrix = 'bubbleMatrix',
  BulletChart = 'bulletChart',
  BarChart = 'barChart',
  Doughnut = 'doughnut',
  FlagList = 'flagList',
  HeatMap = 'heatMap',
  Iframe = 'iframe',
  InitiativeList = 'initiativeList',
  LineChart = 'lineChart',
  List = 'list',
  Network = 'network',
  PrintTable = 'printTable',
  RSSFeed = 'rssList',
  Sunburst = 'sunburst',
  Table = 'table',
  TimelineChart = 'timelineChart',
  TwitterFeed = 'tweet',
  TreeMap = 'treeMap',
  WorldMap = 'worldMap'
}

import { Injectable } from '@angular/core'
import { BreadcrumbLink } from '../interfaces/breadcrumb-link.interface'
import { appBreadcrumbLinks } from '../../../locale/breadcrumb/breadcrumb.content.en'
import { homeBreadcrumbLink } from '../../../locale/breadcrumb/breadcrumb.content.en'

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  appBreadcrumbLinks = appBreadcrumbLinks

  // Home breadcrumb link is the root of all others.
  homeBreadcrumbLink = homeBreadcrumbLink

  getBreadCrumbLinks(
    pageName: string,
    dynamicLinks?: BreadcrumbLink[]
  ): BreadcrumbLink[] {
    if (!this.appBreadcrumbLinks[pageName]) {
      return []
    }

    return dynamicLinks
      ? [
          ...[this.homeBreadcrumbLink],
          ...this.appBreadcrumbLinks[pageName],
          ...dynamicLinks
        ]
      : [...[this.homeBreadcrumbLink], ...this.appBreadcrumbLinks[pageName]]
  }
}

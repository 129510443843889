import { Component } from '@angular/core'

@Component({
  selector: 'app-author-disclaimer',
  template: `
    <div class="columns">
      <div class="column is-12">
        <div class="disclaimer m-b-xl">
          <p class="small-meta">
            <strong i18n="@@blogDisclaimerIntro">Disclaimer: </strong>
            <span i18n="@@blogDisclaimerParagraph"
              >The opinions expressed and arguments employed herein are solely
              those of the authors and do not necessarily reflect the official
              views of the OECD or its member countries. The Organisation cannot
              be held responsible for possible violations of copyright resulting
              from the posting of any written material on this
              website/blog.</span
            >
          </p>
        </div>
      </div>
    </div>
  `,
  standalone: true
})
export class AuthorDisclaimerComponent {}

<section>
  <div class="container-full bg-theme">
    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <app-breadcrumbs [darkTheme]="true" [breadcrumbLinks]="breadcrumbLinks"></app-breadcrumbs>
          <h1 i18n="@@aboutTitle" class="title is-1 has-text-light">
            About OECD.AI
          </h1>
          <p i18n="@@aboutSubtitle" *ngIf="selectedNavItem === 'what-we-do'" class="has-text-light m-y-gutter">
            This website is currently being updated further to the announcement of an integrated partnership for the
            Global Partnership on AI (GPAI) with the OECD. Stay tuned for further updates! <a
              href="https://www.oecd.org/en/about/programmes/global-partnership-on-artificial-intelligence.html"
              target="_blank" class="has-text-white">See here for more
              information</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-full">
    <div class="subnav bg-swamp">
      <div class="container">
        <div class="columns">
          <div class="column p-y-0">
            <div class="full-width subnav--list">
              <a class="subnav--item is-about" routerLink="/about/the-context"
                [ngClass]="{ 'is-active': selectedNavItem === 'the-context' }">The Context</a>
              <a class="subnav--item is-about" routerLink="/about/what-we-do"
                [ngClass]="{ 'is-active': selectedNavItem === 'what-we-do' }">What we do</a>
              <a class="subnav--item is-about" routerLink="/about/background"
                [ngClass]="{ 'is-active': selectedNavItem === 'background' }">Background</a>

              <a class="subnav--item is-about" routerLink="/about/partners"
                [ngClass]="{ 'is-active': selectedNavItem === 'partners' }">Partners</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="aboutPage" class="container m-t-alt m-b-xxl">
    <div class="columns is-multiline">
      <div
        class="column is-12-mobile is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop is-offset-3-widescreen is-6-widescreen">
        <h1 class="is-2 title">{{ aboutPage.title }}</h1>

        <div class="content" [innerHTML]="pageContent"></div>
      </div>

      <div class="column is-12-mobile is-12-tablet is-3-desktop is-3-widescreen" *ngIf="hasQuickLinks">
        <app-quick-link-list [parentGroupSlug]="'about-' + selectedNavItem" class="link-list"></app-quick-link-list>
      </div>
    </div>
  </div>

  <!-- // TODO: Add loader component -->
  <div *ngIf="isLoading" class="container m-t-alt m-b-xxl">
    <div class="columns">
      <div
        class="column is-12-mobile is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop is-offset-3-widescreen is-6-widescreen">
        <div class="temporary-item temporary-item--dark temporary-desc"></div>
        <div class="temporary-item temporary-item--dark temporary-desc"></div>
        <div class="temporary-item temporary-item--dark temporary-desc"></div>
        <div class="temporary-item temporary-item--dark temporary-desc"></div>
        <div class="temporary-item temporary-item--dark temporary-desc"></div>
        <div class="temporary-item temporary-item--dark temporary-desc"></div>
        <div class="temporary-item temporary-item--dark temporary-desc"></div>
        <div class="temporary-item temporary-item--dark temporary-desc"></div>
      </div>
    </div>
  </div>
</section>

import { Injectable } from '@angular/core'

import { ResourceSlug } from '../enums/wordpress/wp-resource-slug.enum'
import { Paginator } from '../interfaces/paginator.interface'
import { KeyTheme } from '../interfaces/resources/key-theme.interface'
import { Post } from '../interfaces/resources/post.interface'
import { WPPost } from '../interfaces/wordpress/wp-post.interface'
import { BlogResourceService } from './blog-resource.service'
import { FormatResourceService } from './format-resource.service'

@Injectable({
  providedIn: 'root'
})
export class PostService {
  private postsPerPage = 12

  constructor(
    private blogResourceService: BlogResourceService,
    private formatResourceService: FormatResourceService
  ) {}

  list(listParams: {
    page?: number
    featured?: boolean
    featuredPortal?: boolean
    limitTo?: number
    countryId?: string | number
    AIPrincipleId?: string
    policyAreaId?: string
    stakeholderTypeId?: string
    authorId?: number
    coAuthorId?: number
    slug?: string
    tagId?: string
    tagIds?: string[]
    postType?: string
    workingGroupId?: number
    withoutPagination?: boolean
    lang?: string
    terms?: string
  }): Promise<Post[] | KeyTheme[] | Paginator<Post> | Paginator<KeyTheme>> {
    const params: { [key: string]: any } = {}

    if (listParams.withoutPagination) {
      params.withoutPagination = true
      params.per_page = 100
    } else {
      params.per_page = this.postsPerPage
      params.page = listParams.page ? listParams.page.toString() : '1'
    }

    if (listParams.featured) {
      params.featuredOnly = '1'
    }
    if (listParams.featuredPortal) {
      params.featuredPortalOnly = '1'
    }
    if (listParams.limitTo) {
      params.per_page = listParams.limitTo.toString()
    }
    if (listParams.authorId) {
      params.author = listParams.authorId.toString()
    }
    if (listParams.coAuthorId) {
      params.coAuthor = listParams.coAuthorId.toString()
    }
    if (listParams.AIPrincipleId) {
      params[ResourceSlug.Principles] = listParams.AIPrincipleId
    }
    if (listParams.policyAreaId) {
      params[ResourceSlug.PolicyAreas] = listParams.policyAreaId
    }
    if (listParams.countryId) {
      params[ResourceSlug.Countries] = listParams.countryId
    }
    if (listParams.stakeholderTypeId) {
      params[ResourceSlug.StakeholderTypes] = listParams.stakeholderTypeId
    }
    if (listParams.workingGroupId) {
      params.workingGroup = listParams.workingGroupId
    }
    if (listParams.slug) {
      params.slug = listParams.slug
    }
    if (listParams.tagId) {
      params.tags = listParams.tagId
    }
    if (listParams.tagIds) {
      params.tags = listParams.tagIds.join(',')
    }
    if (listParams.lang) {
      params.lang = listParams.lang
    }
    if (listParams.terms) {
      params.search = listParams.terms
    }

    if (listParams.withoutPagination) {
      return (
        this.blogResourceService.list(
          listParams.postType || ResourceSlug.Posts,
          params
        ) as Promise<WPPost[]>
      ).then((wpPostRes: WPPost[]) =>
        wpPostRes.map((p) =>
          listParams.postType === ResourceSlug.KeyThemes
            ? this.formatResourceService.formatWipsKeyTheme(p)
            : this.formatResourceService.formatPost(p)
        )
      )
    } else {
      return (
        this.blogResourceService.list(
          listParams.postType || ResourceSlug.Posts,
          params
        ) as Promise<Paginator<WPPost>>
      ).then((wpPostRes: Paginator<any>) => {
        wpPostRes.data = wpPostRes.data.map((p) =>
          listParams.postType === ResourceSlug.KeyThemes
            ? this.formatResourceService.formatWipsKeyTheme(p)
            : this.formatResourceService.formatPost(p)
        )

        return wpPostRes
      })
    }

    // TODO: Catch
  }

  listPaginated(listParams: any): Promise<Paginator<Post>> {
    listParams.withoutPagination = false
    return this.list(listParams) as Promise<Paginator<Post>>
  }

  listUnpaginated(listParams: any): Promise<Post[]> {
    listParams.withoutPagination = true
    return this.list(listParams) as Promise<Post[]>
  }

  // The way to get post by slug instead of Id (for SEO purposes) is to filter list by slug.
  show({
    slug,
    postType,
    lang
  }: {
    slug: string
    postType?: string
    lang?: string
  }): Promise<Post> {
    return (
      this.list({ slug, postType, withoutPagination: true, lang }) as Promise<
        Post[]
      >
    ).then((wpPostRes: Post[]) => {
      if (!wpPostRes || !wpPostRes.length) {
        return Promise.reject('resource not found')
      }
      return wpPostRes[0]
    })
    // TODO: catch
  }

  showById(id: number | string): Promise<Post> {
    return this.blogResourceService
      .show(ResourceSlug.Posts, id)
      .then((wpPost: WPPost) => {
        return this.formatResourceService.formatPost(wpPost)
      })
  }
}
